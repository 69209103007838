import { DefaulContext, DefaulValueType } from "./Types";
import { createContext, useEffect, FC, useState } from "react";

const DefaultValue: DefaulValueType = {
  token: "",
};

const defaulContext: DefaulContext = {
  user: {
    token: "",
  },
  setUser: () => {},
};

export const userContext = createContext<DefaulContext>(defaulContext);
const UserProvider: FC = ({ children }) => {
  const [user, setUser] = useState(() => {
    const localData = localStorage.getItem("intellifund.Auth");
    return localData ? JSON.parse(localData) : DefaultValue;
  });

  useEffect(() => {
    localStorage.setItem("intellifund.Auth", JSON.stringify(user));
  }, [user]);

  return (
    <userContext.Provider value={{ user, setUser }}>
      {children}
    </userContext.Provider>
  );
};

export default UserProvider;
