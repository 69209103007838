import {
  Box, Button,
  FormControl, FormHelperText, Typography
} from "@material-ui/core";
import FormGroup from "@material-ui/core/FormGroup";
import LoadingButton from "@mui/lab/LoadingButton";
import { BackendIndustryType, IndustryType } from "Common/Types/InvestmentType";
import useStyles from "Components/FundingRequest/styles";
import { DataListProps } from "Components/Header/PropsType";
import { Field, Form, Formik } from "formik";
import { CheckboxWithLabel, TextField } from "formik-material-ui";
import i18n from "i18next";
import React, { Children, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import ReactLoading from "react-loading";
import { useMutation, useQuery } from "react-query";
import api from "Utils/AxiosConfig";
import { serializeCategoryToContext } from "Utils/Serializers/serializeCategory";
import { serializeIndustries } from "Utils/Serializers/serializeInvestment";



interface IndustriesProps {
  changeStep: (value: number) => void;
  userID: number;
  industriesInfo: IndustryType;
}

interface Body {
  industries: string[];
  otherIndustries: string;
}

const INITIAL_VALUES = {
  industries: [],
  otherIndustries: "",
};

export default function Industries({
  changeStep,
  userID,
  industriesInfo,
}: IndustriesProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [categoriesData, setCategoriesData] = useState<DataListProps[]>([]);
  const [loading, setLoading] = useState(false);
  const [notCategories, setNotCategories] = useState(false);

  const { isLoading } = useQuery(
    "categoriesFetch",
    async () => api.get("api/investments/categories"),
    {
      onSuccess: (res) => {
        const data = res.data;
        setCategoriesData(serializeCategoryToContext(data));
      },
      onError: () => {
        setCategoriesData([]);
      },
    }
  );

  const mutation = useMutation(
    (data: BackendIndustryType) =>
      api.put(
        `/api/investments/investments/create/${userID}/industries/`,
        data
      ),
    {
      onSuccess: () => {
        changeStep(3);
      },
      onError: () => {
        setLoading(false);
      },
    }
  );

  const allCategories = categoriesData.slice(0, 16);

  function Submit(industries: string[], otherIndustries: string) {
    const body: Body = {
      industries,
      otherIndustries,
    };
    setLoading(true);
    if (
      body.industries.length > 0 ||
      body.otherIndustries !== ""
    ) {
      setNotCategories(false);
      mutation.mutate(serializeIndustries(body));
    } else {
      setNotCategories(true);
      setLoading(false);
    }
  }

  return (
    <>
      <Typography className={classes.title}>
        <Trans i18nKey="Industries / Categories">
          <strong>Industries / Categories</strong>
        </Trans>
      </Typography>

      <Formik
        initialValues={
          {
            industries: industriesInfo.industries,
            otherIndustries: industriesInfo.otherIndustries,
          } || INITIAL_VALUES
        }
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(false);
          resetForm({ values: INITIAL_VALUES });
          Submit(values.industries.map(String), values.otherIndustries);
        }}
      >
        {({ submitForm, setFieldValue, isSubmitting, values }) => (
          <Form className={classes.mainForm} autoComplete="off">
            {!isLoading ? (
              <FormControl fullWidth>
                <FormGroup>
                  <Box className={classes.checkGroup}>
                    {allCategories &&
                      Children.toArray(
                        allCategories.map((category, item) => (
                          <Field
                            component={CheckboxWithLabel}
                            type="checkbox"
                            name="industries"
                            color="primary"
                            value={category.id.toString()}
                            Label={{
                              label: `${
                                i18n.language === "EN"
                                  ? category.nameEn
                                  : category.nameEs
                              }`,
                            }}
                          />
                        ))
                      )}
                  </Box>
                </FormGroup>
                {notCategories && (
                  <FormHelperText className={classes.error}>
                    <strong>
                      {t("You must choose at least one category")}
                    </strong>
                  </FormHelperText>
                )}
              </FormControl>
            ) : (
              <ReactLoading
                type="spinningBubbles"
                color="#19283F"
                height={"8%"}
                width={"8%"}
              />
            )}
            <FormControl fullWidth className={classes.formControl}>
              <Box className={classes.boxInput}>
                <Field
                  className={classes.input}
                  label={t("Others Industries")}
                  name="otherIndustries"
                  component={TextField}
                  InputProps={{ disableUnderline: true }}
                />
              </Box>
            </FormControl>
            <Box>
              <Button className={classes.button} onClick={() => changeStep(1)}>
                {t("BACK")}
              </Button>
              <LoadingButton
                className={!loading ? classes.button : classes.none}
                onClick={submitForm}
                loading={loading}
                variant="outlined"
              >
                {t("NEXT")}
              </LoadingButton>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
}
