import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { useTranslation, Trans } from "react-i18next";
import { useMutation } from "react-query";

import {
  Typography,
  Button,
  FormControl,
  Box,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import { Visibility, VisibilityOff } from "@material-ui/icons";

import {
  PasswordResetType,
  NewPasswordType,
} from "Common/Types/PasswordRecoveryType";
import ResetSuccess from "Components/PasswordReset/ResetSuccess";
import api from "Utils/AxiosConfig";
import useStyles from "./styles";

const INITIAL_VALUES = {
  password: "",
};

export default function NewPasswordForm({
  token,
  language,
}: PasswordResetType) {
  const classes = useStyles();
  const { i18n, t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(false);

  const mutation = useMutation(
    (data: NewPasswordType) =>
      api.post("api/users/password-reset/confirm/", data),
    {
      onError: (error) => {
        console.error("Error: ", error);
        setResetSuccess(false);
      },
      onSuccess: () => {
        setResetSuccess(true);
      },
    }
  );

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const validationSchema = () =>
    Yup.object({
      password: Yup.string()
        .required(t("New password required"))
        .min(8, t("The password must have a minimum of 8 characters")),
    });

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [i18n, language]);

  const handleSendButton = (password: string) => {
    const body: NewPasswordType = {
      token,
      password: password,
      language: language,
    };
    mutation.mutate(body);
  };

  return (
    <>
      {!resetSuccess ? (
        <>
          <Typography className={classes.title}>
            <Trans i18nKey="Reset your password">
              Reset your <b>password</b>
            </Trans>
          </Typography>
          <Formik
            initialValues={INITIAL_VALUES}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              handleSendButton(values.password);
              resetForm({ values: INITIAL_VALUES });
              setSubmitting(false);
            }}
          >
            {({ submitForm, isSubmitting, touched, errors }) => (
              <Form className={classes.mainForm}>
                <FormControl fullWidth className={classes.formControl}>
                  <Box className={classes.passwordBox}>
                    <Field
                      component={TextField}
                      className={classes.input}
                      placeholder={t("New Password")}
                      type={showPassword ? "text" : "password"}
                      name="password"
                      InputProps={{ disableUnderline: true }}
                    />
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  </Box>
                </FormControl>
                <Button
                  className={classes.button}
                  type="submit"
                  disabled={isSubmitting}
                >
                  {t("SET NEW PASSWORD")}
                </Button>
              </Form>
            )}
          </Formik>
        </>
      ) : (
        <ResetSuccess />
      )}
    </>
  );
}
