import { useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useParams } from "react-router-dom";

import { Typography, Button } from "@material-ui/core";

import useStyles from "./styles";

interface Language {
  language: string;
}

export default function ActivationSuccess() {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const { language } = useParams<Language>();

  useEffect(() => {
    if (language === "en-us" || language === "en") {
      i18n.changeLanguage("EN");
    } else {
      i18n.changeLanguage("ES");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Typography className={classes.title}>
        <Trans i18nKey="CONGRATULATIONS! your account has been activated.">
          CONGRATULATIONS! <b>your account has been activated.</b>
        </Trans>
      </Typography>
      <Typography className={classes.description}>
        {t(
          "Please click LOGIN and enter your credentials to continue with your registration."
        )}
      </Typography>
      <Button className={classes.button} href="/?login=true">
        {t("LOG IN")}
      </Button>
    </>
  );
}
