import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    mainCtn: {
      height: "200px",
      backgroundColor: theme.palette.info.main,
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        height: "100%",
        paddingTop: "10%",
        paddingBottom: "10%",
      },
    },
    gridCtn: {
      color: "#19283F",
      "& h2": {
        fontFamily: "Arial",
        fontWeight: "bold",
        fontSize: "75px",
        [theme.breakpoints.down("sm")]: {
          fontSize: "55px",
        },
      },
      "& h4": {
        fontWeight: "bold",
        fontSize: "26px",
        [theme.breakpoints.down("sm")]: {
          fontSize: "20px",
        },
      },
      "& h5": {
        fontWeight: "bold",
        fontSize: "26px",
        marginLeft: "10%",
        [theme.breakpoints.down("sm")]: {
          marginLeft: "5%",
          fontSize: "20px",
        },
      },
    },
    loading: {
      display: "flex",
      justifyContent: "center",
    },
  })
);
