import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: 0,
    },
    nsContainer: {
      marginTop: "40px",
      width: "100%",
      marginLeft: 0,
      height: "140px",
      [theme.breakpoints.down("sm")]: {
        marginBottom: "10%",
      },
      [theme.breakpoints.between("sm", "md")]: {
        marginBottom: "2%",
        display: "flex",
        flexDirection: "column",
      },
    },
    mainText: {
      textAlign: "left",
      color: "#19283F",
      "& p": {
        fontSize: "30px",
        lineHeight: "1",
      },
      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
        "& p": {
          fontSize: "25px",
          lineHeight: "1",
        },
      },
    },
    mainInput: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      justifyContent: "flex-start",
      [theme.breakpoints.between("sm", "md")]: {
        height: "70px",
      },
    },
    form: {
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        width: "110%",
        "&>.MuiFormControl-root": {
          width: "110%",
        },
      },
      [theme.breakpoints.between("sm", "md")]: {
        width: "100%",
        "&>.MuiFormControl-root": {
          width: "50%",
        },
      },
    },
    emailInput: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      border: `solid 2px ${theme.palette.info.contrastText}`,
      borderRadius: "15px",
      height: "40px",
      "&>.MuiFormControl-root": {
        "&>.MuiFormHelperText-root": {
          display: "none",
        },
      },
      [theme.breakpoints.down("sm")]: {
        height: "38px",
      },
    },
    input: {
      marginLeft: theme.spacing(2),
      flex: 1,
      fontStyle: "italic",
      border: "none",
      "&:focus": {
        outline: "none",
      },
    },
    iconButton: {
      background: theme.palette.info.contrastText,
      borderRadius: "0px 12px 12px 0px",
      color: "white",
      fontSize: "15px",
      fontWeight: "bold",
      height: "40px",
      right: "59%",
      paddingLeft: "80%",
      paddingRight: "80%",
      "&:hover": {
        background: "#3f83a6",
      },
      [theme.breakpoints.down("sm")]: {
        height: "38px",
        paddingLeft: "65%",
        paddingRight: "65%",
        right: "29%",
      },
      [theme.breakpoints.between("sm", "md")]: {
        right: "59%",
        paddingLeft: "80%",
        paddingRight: "80%",
      },
    },
    checkIcon: {
      color: theme.palette.info.contrastText,
    },
    suscribeMsg: {
      fontSize: "14px",
      fontStyle: "italic",
      marginRight: "5px",
    },
    suscribeErrMsg: {
      fontSize: "14px",
      fontStyle: "italic",
      marginRight: "5px",
      color: "red",
    },
    suscribeCtn: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      justifyContent: "center",
    },
  })
);
