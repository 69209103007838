import { BackendUserReview, UserReviewType } from "Common/Types/UserReviewType";

export const serializeUserReviewToContext = (
  review: BackendUserReview[]
): UserReviewType[] => {
  const serializeReviewMap = review && review.map ? review.map((item) => {
    const serializedReview: UserReviewType = {
      text: item.text,
      textEs: item.text_es,
      user: item.user,
      avatarPictureThumbnail: item.avatar_picture_thumbnail,
    };
    return serializedReview;
  }) : [];
  return serializeReviewMap;
};
