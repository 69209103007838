import {
  BackendBussinessPlanType, BackendFinancialInformationType, BackendIndustryType, BackendInvestment, BackendInvestmentDetailType, BackendMarketOpportunity, BackendProjectInformationType, BackendStrategyImplementation, BackendStrategyInformationType, BussinessPlanType, FinancialInformationType, IndustryType, InvestmentDetailType, InvestmentType, MarketOpportunity, ProjectInformationType, StrategyImplementationType, StrategyInformationType
} from "Common/Types/InvestmentType";

export const serializeInvestmentToContext = (
  investment: BackendInvestment[]
): InvestmentType[] => {
  const serializeInvestmentMap = investment && investment.map ? investment.map((item) => {
    const serializedInvestment: InvestmentType = {
      id: item.id,
      name: item.name,
      shortDescription: item.short_description,
      amount: item.amount,
      sharesPercentage: item.shares_percentage,
      marketValue: item.market_value,
      potentialValue: item.potential_value,
      estimatedReturnMonths: item.estimated_return_months,
      mainPictureThumbnail: item.main_picture_thumbnail,
    };
    return serializedInvestment;
  }) : [];
  return serializeInvestmentMap;
};

export const serializeInvestmentDetailToContext = (
  investmentDetail: BackendInvestmentDetailType
): InvestmentDetailType => {
  const serializedInvestmentDetail: InvestmentDetailType = {
    amount: investmentDetail.amount,
    sharesPercentage: investmentDetail.shares_percentage,
    marketValue: investmentDetail.market_value,
    potentialValue: investmentDetail.potential_value,
    yearlyRevenue: investmentDetail.yearly_revenue,
    yearlyRevenueFifth: investmentDetail.yearly_revenue_fifth,
    shortDescription: investmentDetail.short_description,
    internalAppraisal: investmentDetail.internal_appraisal,
    externalAppraisal: investmentDetail.external_appraisal,
    strengths: investmentDetail.strengths,
    weakness: investmentDetail.weakness,
    opportunities: investmentDetail.opportunities,
    threats: investmentDetail.threats,
    marketNeeds: investmentDetail.market_needs,
    name: investmentDetail.name,
    mainPicture: investmentDetail.main_picture,
    competitiveAdvantages: investmentDetail.competitive_advantages,
    videoUrl: investmentDetail.video_url,
    description: investmentDetail.description,
    videoType: investmentDetail.video_type,
  };
  return serializedInvestmentDetail;
};

export const serializeProjectInformation = (
  projectInformation: ProjectInformationType
): BackendProjectInformationType => {
  return {
    name: projectInformation.name,
    company_name: projectInformation.companyName,
    company_website: projectInformation.companyWebsite,
    video_url: projectInformation.videoUrl,
    strategic_plan: projectInformation.strategicPlan,
    main_picture: projectInformation.mainPicture,
    video_type: projectInformation.videoType,
    video_file: projectInformation.videoFile,
    pitch: projectInformation.pitch,
    financial_forecast: projectInformation.financialForecast,
  };
};

export const serializeProjectInformationFrontend = (
  projectInformation: BackendProjectInformationType
): ProjectInformationType => {
  return {
    name: projectInformation.name,
    companyName: projectInformation.company_name,
    companyWebsite: projectInformation.company_website,
    videoUrl: projectInformation.video_url,
    strategicPlan: "",
    mainPicture: "",
    videoType: projectInformation.video_type,
    videoFile: "",
    strategicPlanUrl: projectInformation.strategic_plan,
    mainPictureUrl: projectInformation.main_picture,
    videoFileUrl: projectInformation.video_file,
    pitch: "",
    pitchUrl: projectInformation.pitch,
    financialForecast: "",
    financialForecastUrl: projectInformation.financial_forecast,
  };
};

export const serializeFinancialtInformation = (
  financialInformation: FinancialInformationType
): BackendFinancialInformationType => {
  return {
    amount: parseFloat(financialInformation.amount),
    shares_percentage: parseFloat(financialInformation.sharesPercentage),
    market_value: parseFloat(financialInformation.marketValue),
    potential_value: parseFloat(financialInformation.potentialValue),
    yearly_revenue: parseFloat(financialInformation.yearlyRevenue),
    yearly_revenue_fifth: parseFloat(financialInformation.yearlyRevenueFifth),
  };
};

export const serializeFinancialtInformationFrontend = (
  financialInformation: BackendFinancialInformationType
): FinancialInformationType => {
  return {
    amount:
      financialInformation.amount !== null
        ? financialInformation.amount.toString()
        : "",
    sharesPercentage:
      financialInformation.shares_percentage !== null
        ? financialInformation.shares_percentage.toString()
        : "",
    marketValue:
      financialInformation.market_value !== null
        ? financialInformation.market_value.toString()
        : "",
    potentialValue:
      financialInformation.potential_value !== null
        ? financialInformation.potential_value.toString()
        : "",
    yearlyRevenue:
      financialInformation.yearly_revenue !== null
        ? financialInformation.yearly_revenue.toString()
        : "",
    yearlyRevenueFifth:
      financialInformation.yearly_revenue_fifth !== null
        ? financialInformation.yearly_revenue_fifth.toString()
        : "",
  };
};

export const serializeIndustries = (
  industry: IndustryType
): BackendIndustryType => {
  return {
    industries: industry.industries.map(Number),
    other_industries: industry.otherIndustries,
  };
};

export const serializeIndustriesFrontend = (
  industry: BackendIndustryType
): IndustryType => {
  return {
    industries: industry.industries.map(String),
    otherIndustries: industry.other_industries,
  };
};

export const serializeBussinessPlan = (
  bussiness: BussinessPlanType
): BackendBussinessPlanType => {
  return {
    short_description: bussiness.shortDescription,
    description: bussiness.description,
  };
};

export const serializeBussinessPlanFrontend = (
  bussiness: BackendBussinessPlanType
): BussinessPlanType => {
  return {
    shortDescription: bussiness.short_description,
    description: bussiness.description,
  };
};

export const serializeMarketOpportunity = (
  opportunity: MarketOpportunity
): BackendMarketOpportunity => {
  return {
    internal_appraisal: opportunity.internalAppraisal,
    external_appraisal: opportunity.externalAppraisal,
    strengths: opportunity.strengths,
    weakness: opportunity.weakness,
    opportunities: opportunity.opportunities,
    threats: opportunity.threats,
    market_needs: opportunity.marketNeeds,
    competitive_advantages: opportunity.competitiveAdvantages,
  };
};

export const serializeMarketOpportunityFrontend = (
  opportunity: BackendMarketOpportunity
): MarketOpportunity => {
  return {
    internalAppraisal: opportunity.internal_appraisal,
    externalAppraisal: opportunity.external_appraisal,
    strengths: opportunity.strengths,
    weakness: opportunity.weakness,
    opportunities: opportunity.opportunities,
    threats: opportunity.threats,
    marketNeeds: opportunity.market_needs,
    competitiveAdvantages: opportunity.competitive_advantages,
  };
};

export const serializeStrategyInformation = (
  strategy: StrategyInformationType
): BackendStrategyInformationType => {
  return {
    vision: strategy.vision,
    objectives: strategy.objectives,
    portfolio: strategy.portfolio,
    segmentation_strategy: strategy.segmentationStrategy,
    market_price: strategy.marketPrice,
    market_product: strategy.marketProduct,
    market_place: strategy.marketPlace,
    market_promotion: strategy.marketPromotion,
    investment: strategy.investment,
  };
};

export const serializeStrategyInformationFrontend = (
  strategy: BackendStrategyInformationType
): StrategyInformationType => {
  return {
    vision: strategy.vision,
    objectives: strategy.objectives,
    portfolio: strategy.portfolio,
    segmentationStrategy: strategy.segmentation_strategy,
    marketPrice: strategy.market_price,
    marketProduct: strategy.market_product,
    marketPlace: strategy.market_place,
    marketPromotion: strategy.market_promotion,
    investment: strategy.investment,
  };
};

export const serializeStrategyImplementation = (
  implementation: StrategyImplementationType
): BackendStrategyImplementation => {
  return {
    communicate_vision: implementation.communicateVision,
    development_skills: implementation.developmentSkills,
    incentives: implementation.incentives,
    resources: implementation.resources,
    execution_plan: implementation.executionPlan,
  };
};

export const serializeStrategyImplementationFrontend = (
  implementation: BackendStrategyImplementation
): StrategyImplementationType => {
  return {
    communicateVision: implementation.communicate_vision,
    developmentSkills: implementation.development_skills,
    incentives: implementation.incentives,
    resources: implementation.resources,
    executionPlan: implementation.execution_plan,
  };
};
