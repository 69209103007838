import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    buttonGoogle: {
      textTransform: "none",
      background: "#E7E6E6",
      fontSize: "14px",
      height: "34px",
      color: "#6E6E6E",
      fontWeight: 500,
      borderRadius: "12px",
    },
    buttonGoogleLogin: {
      color: "#19283F",
      height: "37px",
      fontWeight: 500,
      borderRadius: "0px",
      width: "100%",
      marginTop: "10px",
      border: "1px solid",
    },
  })
);
