import React from "react";
import { useTranslation } from "react-i18next";
// Import Material Ui / Formik Related
import { Grid } from "@material-ui/core";
// Import locals
import { NavListPropsMobile } from "Components/Header/PropsType";
import ItemMenuMobile from "Components/Header/Mobile/ItemMenuMobile";
import useStyles from "./styles";

export default function NavListMobile({
  arrayOfInvesment,
  arrayOfEntrepreneurs,
  arrayOfInvestors,
}: NavListPropsMobile) {
  const classes = useStyles();
  const { t } = useTranslation();
  const arrayOfList = [
    t("Investment Opportunities"),
    t("Entrepreneurs"),
    t("Investors"),
  ];
  const newArray = [arrayOfInvesment, arrayOfEntrepreneurs, arrayOfInvestors];

  return (
    <Grid className={classes.container}>
      {React.Children.toArray(
        arrayOfList.map((item, index) => (
          <ItemMenuMobile
            name={item}
            handleOpenMenu={() => {}}
            header={true}
            data={newArray[index]}
          />
        ))
      )}
    </Grid>
  );
}
