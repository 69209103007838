import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: "24px",
      marginBottom: "3%",
      marginTop: "2%",
      [theme.breakpoints.down("sm")]: {
        marginBottom: "7%",
        marginTop: "6%",
        fontSize: "20px",
      },
      [theme.breakpoints.between("sm", "md")]: {
        marginBottom: "4%",
        marginTop: "3%",
      },
    },
    formControl: {
      display: "flex",
      alignItems: "center",
      paddingTop: "10px",
      paddingBottom: "10px",
    },
    input: {
      fontSize: "17px",
      fontStyle: "italic",
      fontFamily: "Aero Matics Regular",
      "& ::-ms-reveal": {
        display: "none",
      },
      "& input": {
        fontSize: "17px",
        color: theme.palette.secondary.main,
        padding: "7px 20px",
        [theme.breakpoints.down("sm")]: {
          fontSize: "15px",
        },
        [theme.breakpoints.between("sm", "md")]: {
          fontSize: "16px",
        },
        "&::placeholder": {
          opacity: "0.7",
        },
      },
      "&::placeholder": {
        color: theme.palette.secondary.main,
      },
      "&:focus": {
        outline: "none",
      },
    },
    mainForm: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    error: {
      marginTop: "3px",
      color: "#f44336",
      fontSize: "14px",
      fontStyle: "italic",
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.between("sm", "md")]: {
        fontSize: "13px",
      },
    },
    boxInput: {
      display: "flex",
      flexDirection: "column",
      width: "80%",
      maxWidth: "300px",
      paddingBottom: "10px",
      "&>.MuiFormControl-root": {
        "&>.MuiInputBase-root": {
          borderRadius: "12px",
          border: `2px solid ${theme.palette.secondary.main}`,
        },
      },
    },
    button: {
      background: "#19283F",
      borderRadius: "12px",
      border: "2px solid",
      color: "#FFFFFF",
      fontSize: "17px",
      letterSpacing: "1.5px",
      fontWeight: 500,
      paddingLeft: "15px",
      paddingRight: "15px",
      "&:hover": {
        background: "transparent",
        color: "#19283F",
        borderColor: "#19283F",
      },
    },
    checkGroup: {
      display: "flex",
      flexWrap: "wrap",
      width: "100%",
      paddingBottom: "10px",
      [theme.breakpoints.up("sm")]: {
        width: "70%",
        margin: "auto",
        justifyContent: "flex-end",
      },
      "&>.MuiFormControl-root": {
        "&>.MuiInputBase-root": {
          borderRadius: "12px",
          border: `2px solid ${theme.palette.secondary.main}`,
        },
        "&>.MuiFormHelperText-root": {
          textAlign: "center",
          fontSize: "14px",
          fontWeight: "bold",
          color: "red",
          [theme.breakpoints.down("sm")]: {
            fontSize: "12px",
          },
          [theme.breakpoints.between("sm", "md")]: {
            fontSize: "13px",
          },
        },
      },
      [theme.breakpoints.between("sm", "md")]: {
        paddingBottom: "2%",
      },
      "& svg": {
        color: theme.palette.secondary.main,
      },
      "& span": {
        fontSize: "16px",
      },
      "& label": {
        flexBasis: "100%",
        textAlign: "left",
        [theme.breakpoints.up("sm")]: {
          flexBasis: "40%",
        },
        [theme.breakpoints.down("sm")]: {
          marginLeft: "15%",
        },
        [theme.breakpoints.between("sm", "md")]: {
          marginLeft: 0,
        },
      },
    },
    boxContainer: {
      "& span": {
        fontSize: "16px",
        textAlign: "left",
        [theme.breakpoints.down("sm")]: {
          fontSize: "15px",
          textAlign: "left",
        },
      },
      "& svg": {
        color: theme.palette.primary.main,
      },
      [theme.breakpoints.between("sm", "md")]: {
        marginLeft: "10%",
      },
    },
  })
);
