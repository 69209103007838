import * as Yup from "yup";
import i18n from "i18next";
export default function setYupLocale() {

  Yup.setLocale({
    mixed: {
      required: i18n.t("This field is required"),
      notType: i18n.t("Incorrect value"),
    },
    string: {
      email: i18n.t("Enter a valid email"),
    },
  });
}
