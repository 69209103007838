import * as React from "react";
import { Route, Switch } from "react-router-dom";

import HomePage from "Views/Home";
import PasswordResetPage from "Views/PasswordReset";
import ActivationAccountPage from "Views/ActivationAccount";
import ResendAccountPage from "Views/ActivationAccount/ResendActivation";
import RegisterActivation from "Views/ActivationAccount/RegisterActivation";
import NotFoundPage from "Views/NotFound";
import TermsConditionsPage from "Views/TermsConditions";
import IntellectualPropertyPage from "Views/IntellectualProperty";
import SignUp from "Views/SignUp";
import FundingRequestPage from "Views/FundingRequest";
import SearchPage from "Views/Search";
import InvestmentDetailPage from "Views/InvestmentDetail";
import ResourcesPage from "Views/Resources";

export const baseUrl = "/";
export const resetPasswordUrl = `${baseUrl}resetPassword/`;
export const resetPasswordTokenUrl = `${baseUrl}resetNewPassword/:token/:language`;
export const activationAccount = `${baseUrl}activationAccount/:uuid/:token/:language`;
export const resendActivationAccount = `${baseUrl}resendActivationAccount/`;
export const registerActivationAccount = `${baseUrl}registerActivation`;
export const signUpPath = `${baseUrl}signUp/:type`;
export const termsConditions = `${baseUrl}termsConditions`;
export const intellectualProperty = `${baseUrl}intellectualProperty`;
export const fundingRequest = `${baseUrl}fundingRequest`;
export const searchPath = `${baseUrl}search/:categoryID?`;
export const investmentDetailPath = `${baseUrl}investment/:id`;
export const resourcesPath = `${baseUrl}resources/:type`;

export const Routes: React.FC = () => (
  <Switch>
    <Route exact path={baseUrl} component={HomePage} />
    <Route path={resetPasswordUrl} component={PasswordResetPage} />
    <Route path={resetPasswordTokenUrl} children={<PasswordResetPage />} />
    <Route path={activationAccount} component={ActivationAccountPage} />
    <Route path={resendActivationAccount} component={ResendAccountPage} />
    <Route path={registerActivationAccount} component={RegisterActivation} />
    <Route path={signUpPath} component={SignUp} />
    <Route path={termsConditions} component={TermsConditionsPage} />
    <Route path={intellectualProperty} component={IntellectualPropertyPage} />
    <Route path={fundingRequest} component={FundingRequestPage} />
    <Route path={searchPath} component={SearchPage} />
    <Route path={investmentDetailPath} component={InvestmentDetailPage} />
    <Route path={resourcesPath} component={ResourcesPage} />
    <Route component={NotFoundPage} />
  </Switch>
);

export default Routes;
