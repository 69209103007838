import React, { useEffect, useRef, useContext } from "react";
import { useTranslation } from "react-i18next";
// Import Material Ui / Formik Related
import { Typography, Link } from "@material-ui/core";
import { Grid } from "@material-ui/core";
// Import locals
import { ListOptionsMenuProps } from "Components/Header/PropsType";
import { userContext } from "Context/UserSesion/UserContext";
import useStyles from "./styles";

export default function ListOptionsMenu({
  setIsOpenMenu,
  data,
  title,
  listenScrollEvent,
}: ListOptionsMenuProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const lng = localStorage.getItem("intellifund-language");
  const dropDownMenu = useRef<any>(null);
  const { user } = useContext(userContext);

  const handleClickOtside = (event: { target: any }) => {
    if (dropDownMenu.current && !dropDownMenu.current.contains(event.target)) {
      setIsOpenMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOtside, true);
    return () => {
      document.removeEventListener("click", handleClickOtside, true);
    };
  });

  const getClassByType = () => {
    switch (title) {
      case "CATEGORIES":
        return classes.containerCategories;
      case "Entrepreneurs":
        return classes.containerEntrepreneurs;
      case "Investors":
        return classes.containerInvestors;
    }
  };
  return (
    <Grid
      ref={dropDownMenu}
      onMouseLeave={() => {
        setIsOpenMenu(false);
        listenScrollEvent();
      }}
      className={getClassByType()}
    >
      <Typography align="left" className={classes.titleSubMenu}>
        {title === "CATEGORIES" ? t("CATEGORIES") : ""}
      </Typography>
      <Grid className={classes.gridElements}>
        {data.length < 1 ? (
          <Typography align="left" variant="h6" className={classes.noData}>
            {t("There is no data to display at this time")}
          </Typography>
        ) : (
          <>
            {React.Children.toArray(
              data.map((item) => (
                <Link
                  variant="h6"
                  align="left"
                  underline="none"
                  className={classes.categories}
                  style={{
                    pointerEvents:
                      user.token !== ""
                        ? item.link.startsWith("signUp")
                          ? "none"
                          : "auto"
                        : "auto",
                    opacity:
                      user.token !== ""
                        ? item.link.startsWith("signUp")
                          ? 0.6
                          : 1
                        : 1,
                  }}
                  href={
                    item.link.startsWith("categories")
                      ? `/search/${item.id}`
                      : `/${item.link}`
                  }
                >
                  {lng === "EN" ? item.nameEn : item.nameEs}
                </Link>
              ))
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
}
