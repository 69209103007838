import React, { useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import * as yup from "yup";
import { useMutation } from "react-query";

import {
  Typography,
  Button,
  FormControl,
  FormHelperText,
  Box,
  Divider,
  Tooltip,
} from "@material-ui/core";
import HelpIcon from "@mui/icons-material/Help";
import LoadingButton from "@mui/lab/LoadingButton";
import { TextField } from "formik-material-ui";
import { Field, Formik } from "formik";
import { Editor } from "@tinymce/tinymce-react";

import {
  BackendBussinessPlanType,
  BussinessPlanType,
} from "Common/Types/InvestmentType";
import { serializeBussinessPlan } from "Utils/Serializers/serializeInvestment";
import api from "Utils/AxiosConfig";
import useStyles from "Components/FundingRequest/styles";

interface BusinessPlanProps {
  changeStep: (value: number) => void;
  userID: number;
  businessInfo: BussinessPlanType;
}

interface Body {
  shortDescription: string;
  description: string;
}

const INITIAL_VALUES = {
  shortDescription: "",
  description: "",
};

export default function BusinessPlan({
  changeStep,
  userID,
  businessInfo,
}: BusinessPlanProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [summary, setSummary] = useState(businessInfo.description);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = yup.object().shape({
    shortDescription: yup.string().required(t("Short description is required")),
  });

  const handleEditorChange = (content: any, editor: any) => {
    setSummary(content);
  };

  const mutation = useMutation(
    (data: BackendBussinessPlanType) =>
      api.put(
        `/api/investments/investments/create/${userID}/executive_summary/`,
        data
      ),
    {
      onSuccess: () => {
        changeStep(4);
      },
      onError: () => {
        setIsLoading(false);
      },
    }
  );

  function Submit(shortDescription: string) {
    if (summary === "") {
      setShowErrorMessage(true);
    } else {
      setShowErrorMessage(false);
    }
    if (shortDescription !== "" && summary !== "") {
      const body: Body = {
        shortDescription,
        description: summary,
      };
      setIsLoading(true);
      mutation.mutate(serializeBussinessPlan(body));
    }
  }

  return (
    <>
      <Box className={classes.titleBox}>
        <Typography className={classes.title}>
          <Trans i18nKey="Project Short Description and Executive Summary">
            Project <strong>Short Description and Executive Summary</strong>
          </Trans>
        </Typography>
        <Tooltip
          title={
            <span className={classes.tooltip}>
              {t(
                "A strategic business plan is a written document that pairs the objectives of a company with the needs of the market place."
              )}
            </span>
          }
          placement="right"
        >
          <HelpIcon className={classes.helpIcon} />
        </Tooltip>
      </Box>
      <Formik
        initialValues={businessInfo ? businessInfo : INITIAL_VALUES}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(false);
          Submit(values.shortDescription);
        }}
      >
        {({
          submitForm,
          isSubmitting,
          errors,
          touched,
          values,
          setFieldValue,
        }) => (
          <form className={classes.mainForm} autoComplete="off">
            <Box className={classes.subtitleDescCtn}>
              <Typography className={classes.subtitle}>
                <Trans i18nKey="Project Short Description">
                  <strong>Project Short Description</strong>
                </Trans>
              </Typography>
            </Box>
            <Divider className={classes.dividerDesc} />
            <FormControl fullWidth className={classes.formControlDescription}>
              <Box className={classes.boxInput}>
                <Field
                  className={classes.inputTextBox}
                  placeholder={t("Limit to 150 characters")}
                  name="shortDescription"
                  component={TextField}
                  InputProps={{ disableUnderline: true }}
                  multiline={true}
                  rows="5"
                  inputProps={{ maxLength: 150 }}
                />
              </Box>
            </FormControl>
            <Box className={classes.subtitleDescCtn}>
              <Typography className={classes.subtitle}>
                <Trans i18nKey="Executive Summary">
                  Executive <strong>Summary</strong>
                </Trans>
              </Typography>
            </Box>
            <Divider className={classes.dividerDesc} />
            <FormControl fullWidth className={classes.formControlEditor}>
              <Editor
                apiKey="mhzaswjb1r2ha8s0o764ekf0per8vstsxu4oednu69p0a4sr"
                init={{
                  skin: "snow",
                  icons: "thin",
                  placeholder: t(
                    "Type something here... (Limit to 3500 characters)"
                  ),

                  height: "100%",
                  menubar: true,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen textcolor ",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  textcolor_rows: "4",

                  toolbar:
                    "undo redo | styleselect | fontsizeselect| code | bold italic | alignleft aligncenter alignright alignjustify | outdent indent ",
                }}
                onEditorChange={handleEditorChange}
                outputFormat="html"
                initialValue={businessInfo.description}
              />
            </FormControl>
            {showErrorMessage && (
              <FormHelperText className={classes.error}>
                <strong>{t("Company executive summary is required")}</strong>
              </FormHelperText>
            )}
            <Box>
              <Button onClick={() => changeStep(2)} className={classes.button}>
                {t("BACK")}
              </Button>
              <LoadingButton
                className={!isLoading ? classes.button : classes.none}
                onClick={submitForm}
                loading={isLoading}
                variant="outlined"
              >
                {t("NEXT")}
              </LoadingButton>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
}
