// Import Material Ui / Formik Related
import { Typography } from "@material-ui/core";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
// Import locals
import { ItemMenuProps } from "Components/Header/PropsType";
import { useState } from "react";
import useStyles from "./styles";

export default function ItemMenu({
  name,
  handleOpenMenu,
  header,
}: ItemMenuProps) {
  const [openCategory, setOpenCategory] = useState(false);

  const props = {
    transform: openCategory ? "rotate(180deg)" : "rotate(90deg)",
  };
  const classes = useStyles(props);

  return (
    <div
      className={classes.textContainer}
      onMouseEnter={() => {
        setOpenCategory(!openCategory);
        handleOpenMenu(name);
      }}
      onMouseLeave={() => setOpenCategory(!openCategory)}
    >
      <Typography variant="h4" className={classes.textWhite} data-att="menu" data-menu={name}>
        {name}
      </Typography>
      <PlayArrowIcon color="primary" className={classes.arroIcon} />
    </div>
  );
}
