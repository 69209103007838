import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import notFound from "Assets/images/404.png";

export default makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      marginTop: "16%",
      marginBottom: "12%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        paddingTop: "30%",
        paddingBottom: "20%",
      },
      [theme.breakpoints.between("sm", "md")]: {
        paddingTop: "18%",
        paddingBottom: "18%",
      },
    },
    title: {
      fontSize: "24px",
      marginBottom: "3%",
      [theme.breakpoints.down("md")]: {
        fontSize: "19px",
      },
    },
    description: {
      fontSize: "20px",
      opacity: "0.9",
      marginBottom: "3%",
      width: "65%",
      [theme.breakpoints.down("md")]: {
        fontSize: "15px",
      },
    },
    button: {
      marginTop: "2%",
      background: "#19283F",
      borderRadius: "12px",
      border: "2px solid",
      color: "#FFFFFF",
      fontSize: "17px",
      letterSpacing: "1.5px",
      fontWeight: 500,
      paddingLeft: "15px",
      paddingRight: "15px",
      "&:hover": {
        background: "transparent",
        color: "#19283F",
        borderColor: "#19283F",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "12px",
        marginTop: 0,
      },
    },
    image: {
      backgroundImage: `url(${notFound})`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundAttachment: "fixed",
      backgroundSize: "contain",
      [theme.breakpoints.down("sm")]: {
        backgroundImage: `url(${notFound})`,
        backgroundAttachment: "inherit",
        backgroundSize: "cover",
      },
    },
  })
);
