import { BackendCategory, CategoryType } from "Common/Types/CategoryType";

export const serializeCategoryToContext = (
  category: BackendCategory[]
): CategoryType[] => {
  const serializeCategoryMap = category && category.map
    ? category.map((item) => {
        const serializedCategory: CategoryType = {
          id: item.id,
          nameEs: item.name_es,
          nameEn: item.name_en,
          link: `search/${item.id}`,
        };
        return serializedCategory;
      })
    : [];
  return serializeCategoryMap;
};
