// Import core modules
import { Grid } from "@material-ui/core";

import Banner from "Components/Banner";
import Newsletter from "Components/Newsletter";
import UsersReviews from "Components/UsersReviews";
import Slogans from "Components/Slogans";
import InvestmentOpportunity from "Components/InvestmentOpportunity";
import Summary from "Components/Summary";

export default function HomePage() {
  return (
    <Grid>
      <Banner />
      <InvestmentOpportunity />
      <Newsletter />
      <UsersReviews />
      <Slogans />
      <Summary />
    </Grid>
  );
}
