import React from "react";
import { useTranslation, Trans } from "react-i18next";

import { Typography } from "@material-ui/core";

import useStyles from "./styles";

export default function EmailSuccess() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Typography className={classes.title}>
        <Trans i18nKey="THANK YOU, we received your request to reset your password">
          THANK YOU! <b>we received your request to reset your password</b>
        </Trans>
      </Typography>
      <Typography className={classes.description}>
        {t(
          "Please, check your email and follow the steps indicated to reset your password"
        )}
      </Typography>
    </>
  );
}
