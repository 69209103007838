import axios from "axios";
import { useContext } from "react";
import FacebookLogin from "react-facebook-login";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { userContext } from "../../Context/UserSesion/UserContext";
import api from "../../Utils/AxiosConfig";
import useStyles from "./styles";

interface FacebookAuthProps {
  isSignUp: boolean;
  isInvestor: boolean;
  callback?: any;
}

interface TokenResponse {
  token: string;
  result: string;
}

export default function FacebookAuth(props: FacebookAuthProps): JSX.Element {

  const { setUser } = useContext(userContext);
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();
  const facebookAppId: string = process.env.REACT_APP_FACEBOOK_CLIENTID as string;

  
  const validateFacebookAuth = (data: object, token: string) => {

    const headers = {
      Authorization: token,
      'Content-Type': 'application/json'
    };
    const apiBase: string = process.env.REACT_APP_API_LINK as string;

    axios.post<TokenResponse>(apiBase + 'facebook-login/', data, { headers })
      .then((response) => {
        const result = response.data.result;
        if (result === 'no-account') {
          alert(t("Looks like you have not created an account with us yet, please first signup"));
        }
        else {
          const user = { token: response.data.token };
          localStorage.setItem("intellifund.Auth", JSON.stringify(user));
          setUser({
            token: response.data.token,
          });

          api.get("/api/users/profile/").then(response => {
            if (response.data.user.status === 0) {
              history.push("/", response.data);
            }
            else {
              if (props.isInvestor) {
                history.push("/signUp/Investor", response.data);
              }
              else {
                history.push("/signUp/Entrepreneur", response.data);
              }
            }
            if (props.callback) {
              props.callback();
            }
          });
        }

      }, (error) => {

      });
  }
  const responseFacebook = (response: any) => {
    
    const idToken = response.tokenId;
    const data = {
      email: response.email,
      name: response.name,
      is_investor: props.isInvestor,
      is_sign_up: props.isSignUp,
    };
    return validateFacebookAuth(data, idToken);
  }


  return (
    <FacebookLogin
      appId={facebookAppId}
      autoLoad={false}
      fields="name,email"
      textButton={ props.isSignUp ? t("Sign Up with Facebook") : t("Login with Facebook")}
      cssClass={classes.buttonFacebook}
      callback={responseFacebook}
      
    />
  )
}