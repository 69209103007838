import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";

import { Box, Button, Grid } from "@material-ui/core";

import api from "Utils/AxiosConfig";
import useStyles from "./styles";


export default function MainResources(props: { blogSlug: string }) {
  const classes = useStyles();
  const { blogSlug } = props;
  const { i18n, t } = useTranslation();
  const [contentTitle, setContentTitle] = useState("");
  const [contentTitleEs, setContentTitleEs] = useState("");
  const [content, setContent] = useState("");
  const [contentEs, setContentEs] = useState("");
  const [useEnglishContent, setUseEnglishContent] = useState(true);
  const type = (blogSlug === "entrepreneurs" ? "entrepreneur" : "investor");

  useEffect(() => {
    if (i18n.language === "ES") {
      setUseEnglishContent(false);
    } else {
      setUseEnglishContent(true);
    }
  }, [i18n.language]);

  useQuery(
    "getEntrepreneurBlogs",
    () => api.get("api/blogs/blogs/" + blogSlug + "/"),
    {
      onSuccess: (res) => {
        setContentTitle(res.data["title"]);
        setContentTitleEs(res.data["title_es"]);
        setContent(res.data["content"]);
        setContentEs(res.data["content_es"]);
      },
    }
  );

  return (
    <>
      <Button className={classes.butonBlog}>
        <b>{useEnglishContent ? contentTitle : contentTitleEs}</b>
      </Button>
      <Box px={2} py={3} className="temp">
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="center"
          className="temp"
        >
          <div
            dangerouslySetInnerHTML={{
            __html: useEnglishContent ? content : contentEs,
            }}
          />
          
        </Grid>
      </Box>
    </>
  );
}
