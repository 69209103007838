import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: "24px",
      marginBottom: "3%",
      [theme.breakpoints.down("md")]: {
        fontSize: "19px",
      },
    },
    description: {
      fontSize: "20px",
      opacity: "0.6",
      marginBottom: "3%",
      [theme.breakpoints.down("md")]: {
        fontSize: "15px",
      },
    },
    button: {
      marginTop: "2%",
      background: "#19283F",
      borderRadius: "12px",
      border: "2px solid",
      color: "#FFFFFF",
      fontSize: "17px",
      letterSpacing: "1.5px",
      fontWeight: 500,
      paddingLeft: "15px",
      paddingRight: "15px",
      "&:hover": {
        background: "transparent",
        color: "#19283F",
        borderColor: "#19283F",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "12px",
        marginTop: 0,
      },
    },
  })
);
