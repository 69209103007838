import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import { ArroIconProps } from "Components/Header/PropsType";

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      width: "500px",
      justifyContent: "space-between",
      alignItems: "center",
    },
    textWhite: {
      color: "#FFFFFF",
      fontFamily: "Helvetica",
      fontSize: "17px",
      padding: "0",
      margin: "0",
    },
    textContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
    },
    arroIcon: {
      fontSize: 15,
      transition: "transform .3s ease-in-out",
      transform: (props: ArroIconProps) => props.transform,
      padding: "0",
      margin: "0",
      height: "100%",
    },
  })
);
