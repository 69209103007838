import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    footer: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      height: "400px",
      background: "#19283F",
      [theme.breakpoints.down("md")]: {
        height: "auto",
        padding: "20px",
      },
    },
    containerFooter: {
      width: "100%",
      height: "100%",
      display: "grid",
      gridTemplateColumns: "1fr 1fr 1fr 1fr",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      },
    },
    intellifund: {
      height: "80%",
      [theme.breakpoints.down("md")]: {
        marginTop: "30px",
      },
    },
    title: {
      color: "#FFFFFF",
      opacity: 1,
      marginBottom: "14px",
    },
    text: {
      color: "#FFFFFF",
      opacity: 0.6,
      marginTop: "30px",
    },
    box: {
      width: "26px",
      height: "26px",
      background: "#47A6BE",
    },
    titleRow: {
      display: "grid",
      gridTemplateColumns: "40px 1fr",
    },
    link: {
      gridColumnStart: 2,
      textAlign: "left",
      outline: "none",
      textDecoration: "none",
      color: "#FFFFFF",
      cursor: "pointer",
    },
    button: {
      gridColumnStart: 2,
      marginTop: "10px",
      textAlign: "left",
      outline: "none",
      textDecoration: "none",
      marginLeft: "0px",
      color: "#FFFFFF",
      textTransform: "capitalize",
    },
    Terms: {
      textAlign: "center",
      color: "#FFFFFF",
      gridColumnStart: 1,
      gridColumnEnd: 4,
      [theme.breakpoints.down("md")]: {
        width: "100%",
        marginTop: "30px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      },
    },
    icon: {
      height: "22px",
    },
    social: {
      marginTop: "30px",
      width: "140px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    spanColor: {
      color: "#47A6BE",
    },
    buttonTerms: {
      padding: 0,
      textTransform: "none",
      fontFamily: "'Aero Matics Regular'",
      fontSize: "0.85rem",
      color: "#47A6BE",
      paddingBottom: "2px",
    },
  })
);
