import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: "24px",
      marginBottom: "3%",
      marginTop: "2%",
      [theme.breakpoints.down("sm")]: {
        marginBottom: "7%",
        marginTop: "6%",
        fontSize: "20px",
      },
      [theme.breakpoints.between("sm", "md")]: {
        marginBottom: "4%",
        marginTop: "3%",
      },
    },
    subtitle: {
      fontSize: "20px",
    },
    subtitleCtn: {
      display: "flex",
      textAlign: "left",
      width: "50%",
      alignItems: "center",
    },
    secondSubtitle: {
      display: "flex",
      width: "50%",
      alignItems: "flex-end",
      "& h5": {
        fontSize: "17px",
        textAlign: "left",
        fontWeight: 'bold',
      },
      "& h6": {
        fontSize: "15px",
        textAlign: "left",
      },
    },
    secondSubtitleFull: {
      display: "flex",
      alignItems: "flex-end",
      "& h5": {
        fontSize: "17px",
        textAlign: "left",
        fontWeight: 'bold',
      },
      "& h6": {
        fontSize: "15px",
        textAlign: "left",
      },
    },
    "link": {
      fontSize: "15px",
      textAlign: "left",
      color: theme.palette.secondary.main,
    },
    divider: {
      border: "1px solid gray",
      width: "50%",
      marginBottom: "10px",
    },
    formControl: {
      width: "55%",
      padding: "4px 20px 4px 20px",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        height: "60px",
      },
      [theme.breakpoints.between("sm", "md")]: {
        height: "65px",
      },
    },
    formControlTextBox: {
      width: "55%",
      padding: "4px 20px 4px 20px",
      height: "140px",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        height: "160px",
      },
      [theme.breakpoints.between("sm", "md")]: {
        height: "165px",
      },
    },
    formControlEditor: {
      width: "70%",
      height: "400px",
      border: `2px solid ${theme.palette.secondary.main}`,
      borderRadius: "12px",
      marginBottom: "12px",
    },
    input: {
      fontSize: "17px",
      fontStyle: "italic",
      fontFamily: "Aero Matics Regular",
      "& input": {
        fontSize: "17px",
        color: theme.palette.secondary.main,
        padding: "7px 20px",
        [theme.breakpoints.down("sm")]: {
          fontSize: "15px",
        },
        [theme.breakpoints.between("sm", "md")]: {
          fontSize: "16px",
        },
        "&[type=number]": {
          "-moz-appearance": "textfield",
        },
        "&::-webkit-outer-spin-button": {
          "-webkit-appearance": "none",
          margin: 0,
        },
        "&::-webkit-inner-spin-button": {
          "-webkit-appearance": "none",
          margin: 0,
        },
        "&::placeholder": {
          opacity: "0.7",
        },
      },
      "&::placeholder": {
        color: theme.palette.secondary.main,
      },
      "&:focus": {
        outline: "none",
      },
    },
    mainForm: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    boxInput: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      "&>.MuiFormControl-root": {
        "&>.MuiInputBase-root": {
          borderRadius: "12px",
          border: `2px solid ${theme.palette.secondary.main}`,
        },
        "&>.MuiFormHelperText-root": {
          textAlign: "center",
          fontSize: "14px",
          fontWeight: "bold",
          [theme.breakpoints.down("sm")]: {
            fontSize: "12px",
          },
          [theme.breakpoints.between("sm", "md")]: {
            fontSize: "13px",
          },
        },
      },
      [theme.breakpoints.between("sm", "md")]: {
        paddingBottom: "2%",
      },
    },
    button: {
      marginTop: "2% !important",
      marginLeft: "15px !important",
      background: "#19283F !important",
      borderRadius: "12px !important",
      border: "2px solid !important",
      color: "#FFFFFF !important",
      fontSize: "17px !important",
      letterSpacing: "1.5px !important",
      fontWeight: 500,
      paddingLeft: "10px !important",
      paddingRight: "10px !important",
      fontFamily: "Aero Matics Regular !important",
      "&:hover": {
        background: "transparent !important",
        color: "#19283F !important",
        borderColor: "#19283F !important",
      },
    },
    none: {
      marginTop: "2% !important",
      marginLeft: "15px !important",
      color: "#19283F",
      borderRadius: "12px !important",
      border: " #19283F 2px solid !important",
    },
    error: {
      marginTop: "3px",
      color: "#f44336",
      fontSize: "14px",
      fontStyle: "italic",
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.between("sm", "md")]: {
        fontSize: "13px",
      },
    },
    fileInput: {
      border: `2px solid ${theme.palette.secondary.main}`,
      borderRadius: "12px",
      width: "100%",
      fontStyle: "italic",
      "& input": {
        fontSize: "17px",
        color: theme.palette.secondary.main,
        padding: "7px 20px",
        [theme.breakpoints.down("sm")]: {
          fontSize: "15px",
        },
        [theme.breakpoints.between("sm", "md")]: {
          fontSize: "16px",
        },
      },
      "&>.MuiFormHelperText-root.Mui-error": {
        display: "none",
      },
    },
    formOption: {
      width: "100%",
      height: "42px",
      paddingLeft: "20px",
      paddingRight: "20px",
      fontStyle: "italic",
      "& input": {
        fontSize: "30px",
        color: theme.palette.secondary.main,
        padding: "7px 20px",
        [theme.breakpoints.down("sm")]: {
          fontSize: "15px",
        },
        [theme.breakpoints.between("sm", "md")]: {
          fontSize: "16px",
        },
      },
      "&>.MuiFormHelperText-root.Mui-error": {
        display: "none",
      },
    },
    fileCtn: {
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      height: "42px",
    },
    file: {
      display: "none",
    },
    fileInputCtn: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "42px",
    },
    fileButton: {
      background: "#19283F",
      borderRadius: "20px",
      border: "2px solid",
      color: "#FFFFFF",
      fontSize: "14px",
      letterSpacing: "1.5px",
      fontWeight: 500,
      paddingLeft: "25px",
      paddingRight: "25px",
      textTransform: "capitalize",
      fontStyle: "italic",
      marginLeft: "5px",
      boxShadow: "none",
      "&:hover": {
        background: "transparent",
        color: "#19283F",
      },
      "& .MuiButton-contained": {
        color: "transparent",
        backgroundColor: "transparent",
      },
    },
    titleBox: {
      display: "flex",
      alignItems: "center",
      marginTop: "2%",
      marginBottom: "2%",
    },
    helpIcon: {
      width: "20px !important",
      color: "#19283F",
      paddingLeft: "2px",
    },
    tooltip: {
      fontSize: "14px",
      fontWeight: 0,
    },
    radioGroup: {
      display: "flex",
      flexWrap: "wrap",
      width: "100%",
      paddingBottom: "10px",
      [theme.breakpoints.up("sm")]: {
        
        margin: "auto",
        justifyContent: "flex-start",
      },
      "& label": {
        flexBasis: "100%",
        textAlign: "left",
        fontSize: "16px",
        [theme.breakpoints.up("sm")]: {
          flexBasis: "50%",
        },
        [theme.breakpoints.down("sm")]: {
          marginLeft: "15%",
        },
        [theme.breakpoints.between("sm", "md")]: {
          marginLeft: 0,
        },
      },
    },
    checkGroup: {
      display: "flex",
      flexWrap: "wrap",
      width: "100%",
      paddingBottom: "10px",
      [theme.breakpoints.up("sm")]: {
        width: "70%",
        margin: "auto",
        justifyContent: "flex-end",
      },
      "&>.MuiFormControl-root": {
        "&>.MuiInputBase-root": {
          borderRadius: "12px",
          border: `2px solid ${theme.palette.secondary.main}`,
        },
        "&>.MuiFormHelperText-root": {
          textAlign: "center",
          fontSize: "14px",
          fontWeight: "bold",
          color: "red",
          [theme.breakpoints.down("sm")]: {
            fontSize: "12px",
          },
          [theme.breakpoints.between("sm", "md")]: {
            fontSize: "13px",
          },
        },
      },
      [theme.breakpoints.between("sm", "md")]: {
        paddingBottom: "2%",
      },
      "& svg": {
        color: theme.palette.secondary.main,
      },
      "& span": {
        fontSize: "16px",
      },
      "& label": {
        flexBasis: "100%",
        textAlign: "left",
        [theme.breakpoints.up("sm")]: {
          flexBasis: "40%",
        },
        [theme.breakpoints.down("sm")]: {
          marginLeft: "15%",
        },
        [theme.breakpoints.between("sm", "md")]: {
          marginLeft: 0,
        },
      },
    },
    inputTextBox: {
      fontSize: "16px",
      fontStyle: "italic",
      fontFamily: "Aero Matics Regular",
      overflow: "auto",
      "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
        backgroundColor: "#dedede",
        width: "4px",
      },
      "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
        backgroundColor: "#1f2f46",
      },
      "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
        backgroundColor: "#192434",
      },
      "& textarea": {
        fontSize: "16px",
        color: theme.palette.secondary.main,
        padding: "7px 20px",
        height: "80px !important",

        [theme.breakpoints.down("sm")]: {
          fontSize: "15px",
          height: "100px !important",
        },
        [theme.breakpoints.between("sm", "md")]: {
          fontSize: "16px",
          height: "120px !important",
        },
        "&::placeholder": {
          opacity: "0.7",
        },
      },
      "&::placeholder": {
        color: theme.palette.secondary.main,
      },
      "&:focus": {
        outline: "none",
      },
    },
    formControlDescription: {
      width: "74%",
      padding: "4px 20px 4px 20px",
      height: "140px",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        height: "160px",
      },
      [theme.breakpoints.between("sm", "md")]: {
        height: "165px",
      },
    },
    subtitleDescCtn: {
      display: "flex",
      textAlign: "left",
      width: "70%",
      alignItems: "center",
    },
    dividerDesc: {
      border: "1px solid gray",
      width: "70%",
      marginBottom: "10px",
    },
    errorEditor: {
      marginTop: "56%",
      color: "#f44336",
      fontSize: "14px",
      fontStyle: "italic",
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.between("sm", "md")]: {
        fontSize: "13px",
      },
    },
    tableInputs: {
      display: "flex",
    },
    inputTable: {
      border: "1px solid",
      height: "35px",
      width: "85%",
      marginTop: "17px",
      marginBottom: "20px",
      display: "flex",
      justifyContent: "center",
      textAlign: "center",
      "& input": {
        textAlign: "center",
      },
    },
    tableHead: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      background: "#e1e9f5",
      padding: "15px 30px 15px 10px",
      "& h2": {
        width: "23%",
        fontSize: "17px",
        fontWeight: "bold",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontStyle: "italic",
        color: theme.palette.secondary.main,
      },
      "& h5": {
        fontSize: "16px",
        fontStyle: "italic",
        color: theme.palette.secondary.main,
      },
    },
    tableItems: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flexDirection: "column",
      height: "35px",
    },
    tableBody: {
      marginTop: "2%",
      "& h5": {
        fontSize: "16px",
        fontStyle: "italic",
        marginTop: "18px",
        marginBottom: "18px",
        color: theme.palette.secondary.main,
      },
    },
    tableCtn: {
      border: `2px solid ${theme.palette.secondary.main}`,
      padding: "10px",
      borderRadius: "12px",
    },
  })
);
