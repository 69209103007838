import Button, {
  ButtonProps as MuiButtonProps,
} from "@material-ui/core/Button";
import { Omit } from "@material-ui/types";

import { useStyles } from "./styles";

interface Props {
  header: boolean;
  label: string;
  outlined: boolean;
  mobileMode: boolean;
  handleOpenModal: () => void;
}

function CustomButton(props: Props & Omit<MuiButtonProps, keyof Props>) {
  const { label, outlined, handleOpenModal, mobileMode } = props;
  const classes = useStyles(props);
  return (
    <Button
      onClick={handleOpenModal}
      className={
        outlined
          ? mobileMode
            ? classes.outlinedMobile
            : classes.outlined
          : mobileMode
          ? classes.rootMobile
          : classes.root
      }
    >
      {label}
    </Button>
  );
}
export default CustomButton;
