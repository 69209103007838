import { useState } from "react";
import { useTranslation } from "react-i18next";
import ReactLoading from "react-loading";
import { useQuery } from "react-query";

import { Container, Grid, Typography } from "@material-ui/core";

import { SummaryType } from "Common/Types/SummaryType";
import api from "Utils/AxiosConfig";
import { numberToString } from "Utils/Functions";
import { serializeSummaryToContext } from "Utils/Serializers/serializeSummary";
import useStyles from "./styles";

export default function Summary() {
  const classes = useStyles();
  const [summaryData, setSummaryData] = useState<SummaryType>();

  const { t } = useTranslation();

  const { isLoading } = useQuery(
    "summaryFetch",
    async () => api.get("api/investments/investments/search/summary"),
    {
      onSuccess: (res) => {
        const data = res.data;
        setSummaryData(serializeSummaryToContext(data));
      },
    }
  );

  return (
    <Grid className={classes.mainCtn}>
      <Container maxWidth="md">
        <Grid container className={classes.gridCtn} spacing={3}>
          {!isLoading && summaryData ? (
            <>
              <Grid item xs={12} md={4}>
                <Typography variant="h2">
                  {numberToString(summaryData.numInvestments)}
                </Typography>
                <Typography variant="h4">{t("Investors")}</Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="h2">
                  {numberToString(summaryData.amountRaised)}
                </Typography>
                <Typography variant="h4">{t("USD Requested")}</Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="h2">
                  +{numberToString(summaryData.totalProjects)}
                </Typography>
                <Typography variant="h5">{t("Projects")}</Typography>
              </Grid>
            </>
          ) : (
            <Grid item xs={12} md={12} className={classes.loading}>
              <ReactLoading
                type="spinningBubbles"
                color="#19283F"
                height={"8%"}
                width={"8%"}
              />
            </Grid>
          )}
        </Grid>
      </Container>
    </Grid>
  );
}
