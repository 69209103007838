import { Container, Grid } from "@material-ui/core";
import useStyles from "Components/IntellectualProperty/styles";
import i18n from "i18next";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import api from "Utils/AxiosConfig";




export default function IntellectualInvestor() {
  const classes = useStyles();
  const { t } = useTranslation();

  const [contentEs, setContentEs] = useState("");
  const [contentEn, setContentEn] = useState("");

  useQuery(
    "getEntrepreneurBlogs",
    () => api.get("api/blogs/blogs/intellectual-property-investors/"),
    {
      onSuccess: (res) => {
        setContentEn(res.data["content"]);
        setContentEs(res.data["content_es"]);
      },
    }
  );
  return (
    <Container className={classes.container}>
      <Grid container className={classes.subContainer}>
      <div
        dangerouslySetInnerHTML={{
          __html: i18n.language === "ES" ? contentEs : contentEn,
        }}
      />
      </Grid>
    </Container>
  );
}
