import React from "react";
import { Img } from "react-image";

import { Container, Hidden } from "@material-ui/core";

import banner from "Assets/images/bannerPasswordReset.png";
import ActivationResend from "Components/ActivationAccount/ActivationResend";
import useStyles from "./styles";

export default function ResendAccountPage(props: {
  location: { state: { email: string } };
}) {
  const classes = useStyles();
  const { location } = props;
  const email = location.state.email;

  return (
    <>
      <Hidden only={["sm", "xs"]}>
        <Img src={banner} className={classes.banner} />
      </Hidden>
      <Container maxWidth="md" className={classes.mainContainer}>
        <ActivationResend email={email} />
      </Container>
    </>
  );
}
