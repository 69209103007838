import React from "react";
import { useTranslation, Trans } from "react-i18next";

import { Typography } from "@material-ui/core";

import useStyles from "./styles";

export default function ActivationFailed() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Typography className={classes.title}>
        <Trans i18nKey="SORRY, the token provided is invalid.">
          SORRY, <b>the token provided is invalid.</b>
        </Trans>
      </Typography>
      <Typography className={classes.description}>
        {t("Please verify that the URL provided by the email has not been modified.")}
      </Typography>
    </>
  );
}
