import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      marginTop: "5%",
      marginBottom: "5%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        paddingTop: "36%",
        paddingBottom: "26%",
      },
      background: "#f1f1f1",
    },
    banner: {
      marginTop: "80px",
      width: "100%",
      height: "580px",
      objectFit: "cover",
      objectPosition: "center",
    },
    filtersCtn: {
      marginTop: "5%",
      paddingRight: "3%",
    },
    resultCtn: {
      background: "#f1f1f1",
      marginTop: "3%",
      display: "flex",
      alignItems: "center",
    },
    resultInput: {
      fontSize: "18px",
      paddingLeft: "10px",
    },
    orderList: {
      textAlign: "right",
      fontSize: "18px",
      "& svg": {
        color: theme.palette.secondary.main,
      },
    },
    mainSearch: {
      maxWidth: "100%",
      justifyContent: "space-between",
    },
  })
);
