import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    cardCtn: {
      maxWidth: "100%",
      borderRadius: "15px",
      boxShadow:
        "0px 0px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
      backgroundColor: theme.palette.info.main,
    },
    title: {
      height: "100px",
      paddingTop: "7%",
      paddingBottom: "0 !important",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        height: "70px",
      },
    },
    description: {
      height: "100px",
      paddingTop: "2%",
      paddingBottom: "8px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        height: "70px",
      },
    },
    investment: {
      paddingTop: "8px",
      paddingBottom: "8px",
    },
    contentCtn: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    titleCard: {
      fontSize: "22px",
      fontWeight: "bold",
      marginBottom: "2%",
      color: "#19283F",
      lineHeight: "1.2",
      [theme.breakpoints.down("sm")]: {
        textAlign: "left",
        fontSize: "15px",
      },
    },
    descriptionCard: {
      fontSize: "19px",
      opacity: 0.6,
      color: "#000000",
      lineHeight: "1.2",
      [theme.breakpoints.down("sm")]: {
        textAlign: "left",
        fontSize: "13px",
      },
      overflow: "hidden",
      textOverflow: "ellipsis",
      display:"-webkit-box",
      WebkitBoxOrient:"vertical",
      WebkitLineClamp: 4,
    },
    investCard: {
      backgroundColor: theme.palette.info.dark,
      padding: "8px !important",
      "& p": {
        fontSize: "18px",
        fontWeight: "bold",
        color: "#19283F",
      },
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "16px !important",
        "& p": {
          fontSize: "12px",
          textAlign: "left",
        },
      },
    },
    numCtn: {
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      boxSizing: "border-box",
      justifyContent: "space-around",
      "& div": {
        display: "flex",
        alignItems: "center",
      },
    },
    amount: {
      lineHeight: "18px",
      "& b": {
        fontWeight: "bold",
        fontSize: "22px",
      },
      [theme.breakpoints.down("sm")]: {
        lineHeight: "15px",
        "& b": {
          fontWeight: "bold",
          fontSize: "14px",
        },
      },
    },
    marketCard: {
      backgroundColor: theme.palette.info.dark,
      padding: "8px !important",
      "& p": {
        fontSize: "17px",
        color: "#19283F",
      },
      [theme.breakpoints.down("sm")]: {
        "& p": {
          fontSize: "11px",
          color: "#19283F",
        },
      },
    },
    divider: {
      border: "solid 1px",
      height: "40px",
      marginTop: "3%",
    },
    potencialCard: {
      padding: "8px !important",
      "& p": {
        fontSize: "17px",
        color: "#19283F",
        fontStyle: "italic",
      },
      marginBottom: "10%",
      [theme.breakpoints.down("sm")]: {
        marginBottom: "5%",
        "& p": {
          fontSize: "11px",
          color: "#19283F",
        },
      },
    },
    image: {
      height: "396px",
      [theme.breakpoints.down("sm")]: {
        borderRadius: "7%",
        width: "90%",
        height: "85%",
      },
    },
  })
);
