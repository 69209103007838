import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      width: "700px",
      padding: "20px",
      [theme.breakpoints.down("sm")]: {
        width: "310px",
      },
      [theme.breakpoints.between("sm", "md")]: {
        width: "550px",
      },
    },
    subContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      "& h5": {
        fontSize: "24px",
        [theme.breakpoints.down("md")]: {
          fontSize: "20px",
        },
      },
      "& label, p": {
        fontSize: "17px",
        [theme.breakpoints.down("md")]: {
          fontSize: "15px",
        },
      },
      "& p": {
        fontSize: "14px",
        [theme.breakpoints.down("md")]: {
          fontSize: "12px",
        },
      },
      "& span": {
        fontSize: "15px",
        [theme.breakpoints.down("md")]: {
          fontSize: "13px",
        },
      },
    },
    exitContainer: {
      display: "flex",
      justifyContent: "flex-end",
    },
    ico: {
      width: "10px",
      height: "10px",
      cursor: "pointer",
    },
    line: {
      marginTop: "20px",
      height: "4px",
      width: "100%",
      backgroundColor: "#000",
    },
    form: {
      width: "100%",
      display: "grid",
      marginTop: "30px",
      gridTemplateColumns: "1fr 1fr",
      gridGap: "10px",
      [theme.breakpoints.down("sm")]: {
        gridTemplateColumns: "1fr",
      },
    },
    formControl: {
      width: "100%",
    },
    input: {
      marginTop: "10px",
      [`& fieldset`]: {
        borderRadius: 0,
      },
      [`& internal-autofill-selected`]: {
        backgroundColor: "#FFF !important",
      },
    },
    butonContainer: {
      marginTop: "20px",
      width: "100%",
    },
    buttonLogin: {
      color: "#FFFFFF",
      backgroundColor: "#19283F",
      height: "37px",
      padding: "10px",
      fontWeight: 500,
      borderRadius: "0px",
      width: "100%",
      border: "2px solid",
      "&:hover": {
        background: "transparent",
        color: "#19283F",
        borderColor: "#19283F",
        fontWeight: "bold",
      },
    },
    buttonFacebook: {
      color: "#FFFFFF",
      height: "37px",
      fontFamily: ["Aero Matics Regular"].join(","),
      backgroundColor: "#3b5998",
      cursor: "pointer",
      fontWeight: 500,
      borderRadius: "0px",
      width: "100%",
      marginTop: "10px",
      border: "1px solid",
      textDecoration: "none",
      fontSize: "15px",
    },
    buttonGoogle: {
      color: "#19283F",
      height: "37px",
      fontWeight: 500,
      borderRadius: "0px",
      width: "100%",
      marginTop: "10px",
      border: "1px solid",
    },
    textFiel: {
      marginTop: "10px",
    },
    suscribeMsg: {
      fontSize: "14px",
      fontStyle: "italic",
      marginRight: "5px",
      color: "red",
    },
    suscribeCtn: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      justifyContent: "center",
      marginLeft: "50%",
      [theme.breakpoints.down("sm")]: {
        marginLeft: 0,
      },
      "& p": {
        textAlign: "center",
      },
    },
    forgotpasswordClick: {
      paddingLeft: "2px",
      color: "#19283F",
      fontWeight: "bold",
      "&:hover": {
        textDecoration: "underline",
      },
      fontSize: "17px !important",
      [theme.breakpoints.down("md")]: {
        fontSize: "15px !important",
      },
    },
    forgotpassword: {
      marginTop: "10px",
      textAlign: "center",
      color: "rgba(0, 0, 0, 0.54)",
      fontSize: "17px !important",
      [theme.breakpoints.down("md")]: {
        fontSize: "15px !important",
      },
    },
  })
);
