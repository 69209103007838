import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    buttonFacebook: {
      cursor: "pointer",
      color: "#FFFFFF",
      width: "100%",
      border: "1px solid",
      textTransform: "none",
      background: "#1977ED",
      fontSize: "14px",
      height: "34px",
      fontWeight: 500,
      borderRadius: "12px",
      "&:hover": {
        opacity: ".9",
      },
    },
    buttonFacebookLogin: {
      color: "#19283F",
      height: "37px",
      fontWeight: 500,
      borderRadius: "0px",
      width: "100%",
      marginTop: "10px",
      border: "1px solid",
    },
  })
);
