import { Button } from "@material-ui/core";
import axios from "axios";
import { useContext } from "react";
import GoogleLogin from "react-google-login";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { userContext } from "../../Context/UserSesion/UserContext";
import api from "../../Utils/AxiosConfig";
import GoogleIcon from "../CustomIcon";
import useStyles from "./styles";

interface GoogleAuthProps{
  isSignUp: boolean;
  isInvestor: boolean;
  callback?: any;
}

interface TokenResponse {
  token : string;
  result: string;
}

export default function GoogleAuth(props: GoogleAuthProps): JSX.Element {
  
  const { setUser } = useContext(userContext);
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();
  const googleClientId: string = process.env
    .REACT_APP_GOOGLE_CLIENTID as string;
  
  const errorGoogle = (response:any) => {
    alert(t("Error, could not connect with google, please try again later."))
  }
  const validateGoogleAuth = (data: object, token: string) => {

    const headers = {
      Authorization: token,
      'Content-Type': 'application/json'
    };
    const apiBase: string = process.env.REACT_APP_API_LINK as string;
    
    axios.post<TokenResponse>(apiBase + 'google-login/', data, {headers})
    .then((response) => {
      const result = response.data.result;
      if (result === 'no-account'){
        alert(t("Looks like you have not created an account with us yet, please first signup"));
      }
      else{
        const user = {token: response.data.token};
        localStorage.setItem("intellifund.Auth", JSON.stringify(user));
        setUser({
          token: response.data.token,
        });

        api.get("/api/users/profile/").then(response => {
          if (response.data.user.status === 0){
            history.push("/", response.data);  
          }
          else{
            if (props.isInvestor){
              history.push("/signUp/Investor", response.data);
            }
            else{
              history.push("/signUp/Entrepreneur", response.data);
            }
          }
          if (props.callback){
            props.callback();
          }
        });  
      }

    }, (error) => {
      
    });
  }
  const responseGoogle = (response: any) => {
    const idToken = response.tokenId;
    const data = {
      email: response.profileObj.email,
      first_name: response.profileObj.givenName,
      last_name: response.profileObj.familyName,
      is_investor: props.isInvestor,
      is_sign_up: props.isSignUp,
    };
    return validateGoogleAuth(data, idToken);
  }
  
  
  return (
    <GoogleLogin
      render={(renderProps) => (
        <Button
          onClick={renderProps.onClick}
          className={props.isSignUp? classes.buttonGoogle : classes.buttonGoogleLogin}
          endIcon={<GoogleIcon />}
        >
          {props.isSignUp ? t(`Sign Up with Google`) : t(`Login with Google`)}
        </Button>
      )}
      clientId={googleClientId}
      cookiePolicy={"single_host_origin"}
      onSuccess={responseGoogle}
      onFailure={errorGoogle}
            
    />
  )
}