import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      padding: "20px",
      width: "100%",
      textAlign: "left",
    },
    subContainer: {
      display: "flex",
      flexDirection: "column",
      "& h5": {
        fontSize: "22px",
        fontWeight: "bold",
        fontFamily: "Helvetica",
        [theme.breakpoints.down("md")]: {
          fontSize: "20px",
        },
      },
      "& h6": {
        fontSize: "20px",
        fontWeight: "bold",
        fontFamily: "Helvetica",
        [theme.breakpoints.down("md")]: {
          fontSize: "16px",
        },
      },
      "& p": {
        fontSize: "14px",
        fontFamily: "Helvetica",
        [theme.breakpoints.down("md")]: {
          fontSize: "12px",
        },
      },
    },
    exitContainer: {
      display: "flex",
      justifyContent: "flex-end",
      marginBottom: "1%",
      paddingTop: "2%",
      paddingRight: "2%",
      [theme.breakpoints.down("sm")]: {
        marginBottom: "4%",
        paddingTop: "4%",
        paddingRight: "4%",
      },
      [theme.breakpoints.between("sm", "md")]: {
        marginBottom: "1%",
        paddingTop: "2%",
        paddingRight: "2%",
      },
    },
    ico: {
      width: "10px",
      height: "10px",
      cursor: "pointer",
    },
    line: {
      marginTop: "1%",
      height: "4px",
      width: "98%",
      backgroundColor: "#000",
      margin: "auto",
    },
    firstLevel: {
      paddingLeft: "2%",
    },
    secondLevel: {
      paddingLeft: "3%",
    },
    subtitle: {
      fontSize: "18px !important",
    },
    modalTitle: {
      fontFamily: "Helvetica",
      fontSize: "22px",
      fontWeight: "bold",
      [theme.breakpoints.down("sm")]: {
        fontSize: "20px",
      },
    },
  })
);
