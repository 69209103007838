import { useState } from "react";
import { useQuery } from "react-query";
import { useTranslation, Trans } from "react-i18next";

import { Grid, Typography } from "@material-ui/core";
import ReactLoading from "react-loading";

import useStyles from "./styles";
import api from "Utils/AxiosConfig";
import { LabelType } from "Common/Types/LabelType";
import { serializeLabelToContext } from "Utils/Serializers/serializeLabel";
import { homeTitleSlug, homeSubtitleSlug } from "Utils/Constants";


export default function Banner() {
  const classes = useStyles();
  const { i18n, t } = useTranslation();
  const [labelsData, setLabelsData] = useState<LabelType[]>([]);

  const { isLoading: labelsLoading } = useQuery(
    "labelsFetch",
    async () => api.get("api/generics/labels"),
    {
      onSuccess: (res) => {
        const data = res.data;
        setLabelsData(serializeLabelToContext(data));
      },
      onError: (error) => {
        console.error("Error:", error);
      },
    }
  );

  const searchLabel = (slug: string) => {
    for (const label of labelsData) {
      if (label.nameSlug === slug) {
        return i18n.language === 'EN' ? label.valueEn : label.valueEs;
      }
    }

    return "";
  }

  return (
    <Grid className={classes.wrapper}>
      <video
        src="https://i.imgur.com/PhHCpLG.mp4"
        autoPlay
        loop
        muted
        className={classes.video}
      />

      <Grid className={classes.textHome}>
        {!labelsLoading ? (
          <>
            <Typography variant="h1" className={classes.h1}>
              {searchLabel(homeTitleSlug)}
            </Typography>
            <Typography variant="h4" className={classes.h4}>
              {searchLabel(homeSubtitleSlug)}
            </Typography>
          </>
        ) : (
          <ReactLoading
            type="spinningBubbles"
            color="#FFFFFF"
            height={"100px"}
            width={"100px"}
          />
        )}
      </Grid>
    </Grid>
  );
}
