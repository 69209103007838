import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    autoCompleteText:{
      
      "&>.MuiInputLabel-outlined": {
        fontSize: "17px",
        paddingLeft: "8px",
        paddingTop: "4px",
        fontStyle: "italic",
        fontFamily: "Aero Matics Regular",
        marginTop: "-10px",
        marginLeft: "-15px",
      },
      "&>.MuiInputLabel-shrink": {
        fontSize: "17px",
        paddingLeft: "8px",
        paddingTop: "4px",
        fontStyle: "italic",
        fontFamily: "Aero Matics Regular",
        marginTop: "0",
        marginLeft: "4px",

      },
    },
    autoComplete: {
      marginTop: "10px",
      "label": {
        top: "-2px",
        left: "4px",
        color: "#19283F",
        fontSize: "17px",
        fontStyle: "italic",
        fontFamily: "Aero Matics Regular",
      },
      "&>.MuiInputLabel-animated": {
        fontSize: "17px",
        paddingLeft: "8px",
        paddingTop: "4px",
        fontStyle: "italic",
        fontFamily: "Aero Matics Regular",
      },
      "&>.MuiFormControl-root": {
        "&>.MuiOutlinedInput-root": {
          "&::placeholder": {
            color: theme.palette.secondary.main,
          },
          "& input": {
            fontSize: "17px",
            color: theme.palette.secondary.main,
            fontStyle: "italic",
            fontFamily: "Aero Matics Regular",
            height: "34px",
            padding: "0px",
            [theme.breakpoints.down("sm")]: {
              fontSize: "15px",
              height: "32px",
            },
            [theme.breakpoints.between("sm", "md")]: {
              fontSize: "16px",
            },
            "&::placeholder": {
              opacity: "0.7",
            },
          },
          "& fieldset": {
            padding: "0px",
            borderColor: "transparent",
          },
          padding: "0px 20px",
          outline: "none",
          borderRadius: "12px",
          border: `2px solid ${theme.palette.secondary.main}`,
          fontSize: "17px",
          paddingLeft: "8px",
          paddingTop: "4px",
          fontStyle: "italic",
          fontFamily: "Aero Matics Regular",
        },
      },
    },
    imgFlag: {
      marginRight: "20px",
    },
  })
);
