import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

import { Typography, Link } from "@material-ui/core";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";

import { ItemMenuPropsMobile } from "Components/Header/PropsType";
import useStyles from "./styles";

export default function ItemMenu({
  name,
  handleOpenMenu,
  header,
  data,
}: ItemMenuPropsMobile) {
  const [openCategory, setOpenCategory] = useState(false);
  const { t } = useTranslation();

  const props = {
    transform: openCategory ? "rotate(270deg)" : "rotate(90deg)",
  };
  const classes = useStyles(props);

  return (
    <>
      <div
        className={classes.textContainer}
        onClick={() => {
          setOpenCategory(!openCategory);
          handleOpenMenu(name);
        }}
      >
        <Typography
          variant="h6"
          className={header ? classes.textBlack : classes.textWhite}
        >
          {name}
        </Typography>
        <PlayArrowIcon color="primary" className={classes.arroIcon} />
      </div>
      {openCategory && (
        <>
          {data.length < 1 ? (
            <Typography
              align="left"
              variant="h5"
              style={{ cursor: "pointer", color: "#606060", marginTop: "10px" }}
            >
              {t("There is no data to display at this time")}
            </Typography>
          ) : (
            <>
              {React.Children.toArray(
                data.map((item) => (
                  <Link
                    align="left"
                    variant="h5"
                    className={classes.textCategories}
                    href={`/${item.link}`}
                  >
                    {i18n.language === "EN" ? item.nameEn : item.nameEs}
                  </Link>
                ))
              )}
            </>
          )}
        </>
      )}
    </>
  );
}
