import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      marginTop: "5%",
      marginBottom: "5%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        paddingTop: "36%",
        paddingBottom: "26%",
      },
    },
    banner: {
      marginTop: "80px",
      width: "100%",
      height: "240px",
      objectFit: "cover",
      objectPosition: "top",
    },
  })
);
