import React from "react";
import { useTranslation, Trans } from "react-i18next";

import { Typography } from "@material-ui/core";

import useStyles from "./styles";

export default function ActivationEmail() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Typography className={classes.title}>
        <Trans i18nKey="GREAT! activation email forwarded.">
          GREAT! <b>activation email forwarded.</b>
        </Trans>
      </Typography>
      <Typography className={classes.description}>
        {t("Please check your email inbox")}
      </Typography>
    </>
  );
}
