import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      padding: "20px",
    },
    subContainer: {
      display: "flex",
      flexDirection: "column",
    },
    exitContainer: {
      display: "flex",
      justifyContent: "flex-end",
    },
    ico: {
      width: "10px",
      height: "10px",
      cursor: "pointer",
    },
    line: {
      marginTop: "20px",
      height: "4px",
      width: "100%",
      backgroundColor: "#000",
    },
    imgContianer: {
      width: "100%",
      height: "200px",
      cursor: "pointer",
      transition: "transform 0.5s",
      "&:hover": {
        transform: "scale(1.01)",
        border: "1px solid black",
      },
    },
    img: {
      width: "100%",
      height: "100%",
    },
    userSign: {
      display: "flex",
      flexDirection: "column",
      "& a ": {
        color: "black",
        cursor: "pointer",
      },
    },
  })
);
