import {
  createStyles,
  fade,
  makeStyles,
  Theme,
} from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    contInvestments: {
      overflowY: "auto",
      [theme.breakpoints.down("sm")]: {
        marginBottom: "2.5rem",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: fade(theme.palette.info.main, 0.05),
      },
      "&::-webkit-scrollbar": {
        width: " 3px",
        backgroundColor: fade(theme.palette.info.main, 0.05),
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: fade(theme.palette.info.main, 0.6),
      },
      "& div:nth-child(3)": {
        paddingRight: "0",
      },
      "&> div": {
        paddingTop: "30px",
      },
    },
    itemInvestment: {
      paddingRight: "22px",
      paddingBottom: "1rem",
      [theme.breakpoints.down("md")]: {
        paddingRight: "1rem",
        paddingBottom: "1rem",
      },
      [theme.breakpoints.down("xs")]: {
        paddingRight: "0",
        paddingBottom: "1rem",
      },
      "& .makeStyles-cardCtn-62": {
        backgroundColor: "#f0f3f7",
      },
      "&> div": {
        borderRadius: "0",
        marginBottom: "20px",
        "& .makeStyles-potencialCard-74": {
          marginBottom: "5%",
        },
      },
    },
    textNotFoundResults: {
      fontSize: "1.3rem",
      lineHeight: "normal",
      color: fade(theme.palette.info.main, 0.4),
    },
  })
);
