import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
// Import Material Ui / Formik Related
import {
  AppBar,
  Container,
  FormControl,
  Hidden,
  IconButton,
  MenuItem,
  Select,
  Toolbar,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
// Import locals
import IntelliFund from "Components/IntelliFund";
import NavList from "Components/Header/NavLists";
import ListOptionsMenu from "Components/Header/ListOptionsMenu";
import NavListButtons from "Components/Header/NavListButtons";
import MenuSidebar from "Components/Header/Mobile/MenuSidebar";
import ButtonsMobile from "Components/Header/Mobile/ButtonsMobile";
import {
  arrayOfEntrepreneurs,
  arrayOfInvestors,
} from "Common/Mocks/mockOption";
import { availableLanguages } from "Utils/Constants";
import { DataListProps } from "Components/Header/PropsType";
import { serializeCategoryToContext } from "Utils/Serializers/serializeCategory";
import api from "Utils/AxiosConfig";
import useStyles from "./styles";

import setYupLocale from "Utils/Yup";

export default function Header() {
  const classes = useStyles();
  const [header, setHeader] = useState(true);
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [titleMenu, setTitleMenu] = useState("");
  const [openMenu, setOpenMenu] = useState(false);
  const [data, setData] = useState<DataListProps[]>([]);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const { i18n, t } = useTranslation();

  const query = useQuery(
    "categories",
    async () => api.get(`api/investments/categories`),
    {
      refetchOnWindowFocus: false,
      onSuccess: (res) => {
        const data = res.data;
        setData(serializeCategoryToContext(data));
      },
      onError: () => {
        setData([]);
      },
    }
  );

  const handleOpenMenu = (item: string) => {
    setIsOpenMenu(true);
    switch (item) {
      case t("Investment Opportunities"):
        setData(serializeCategoryToContext(query.data?.data) || []);
        setTitleMenu("CATEGORIES");
        break;
      case t("Entrepreneurs"):
        setData(arrayOfEntrepreneurs);
        setTitleMenu("Entrepreneurs");
        break;
      case t("Investors"):
        setData(arrayOfInvestors);
        setTitleMenu("Investors");
        break;
      default:
        break;
    }
  };

  const handleChageLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    setYupLocale();
  };

  const handleToggle = () => {
    setOpenMenu((prevOpen) => !prevOpen);
  };

  useEffect(() => {
    if (openMenu) {
      setHeader(true);
    }
  }, [openMenu]);
  const currPath = window.location.pathname;
  const pathPrefix = "/";

  return (
    <>
      <Hidden only={["md", "sm", "xs"]}>
        <AppBar
          position="fixed"
          elevation={0}
          classes={{
            colorPrimary:
              header || currPath !== pathPrefix
                ? classes.scrollHeader
                : classes.noScrollHeader,
          }}
        >
          <Toolbar className={classes.boxHeader}>
            <Container maxWidth="xl">
              <nav className={classes.nav}>
                <Link to="/" className={classes.contLogo}>
                  <IntelliFund header={header} size="h4" />
                </Link>
                <NavList handleOpenMenu={handleOpenMenu} header={header} />
                <NavListButtons header={header} />
              </nav>
            </Container>
          </Toolbar>
        </AppBar>
      </Hidden>

      <Hidden only={["lg", "xl"]}>
        <AppBar
          position="fixed"
          elevation={0}
          className={classes.header}
          classes={{
            colorPrimary:
              header || currPath !== pathPrefix
                ? classes.scrollHeader
                : classes.noScrollHeader,
          }}
        >
          <Toolbar className={classes.boxHeaderMobile}>
            <Link to="/" className={classes.contLogo}>
              <IntelliFund header={header} size="h6" />
            </Link>
            <div className={classes.containerButon}>
              <ButtonsMobile header={header} />
              <FormControl className={classes.formControl}>
                <Select
                  value={i18n.language}
                  displayEmpty
                  className={classes.select}
                  inputProps={{
                    classes: {
                      icon: classes.icon,
                    },
                  }}
                >
                  {React.Children.toArray(
                    availableLanguages.map((lng: string, index: number) => (
                      <MenuItem
                        value={availableLanguages[index]}
                        onClick={() => handleChageLanguage(lng)}
                        className={classes.languageMenu}
                      >
                        {availableLanguages[index]}
                      </MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>
              <IconButton
                className={classes.iconMenu}
                ref={anchorRef}
                onClick={handleToggle}
              >
                <MenuIcon fontSize={"medium"} />
              </IconButton>
              {openMenu && (
                <MenuSidebar
                  closeMenu={setOpenMenu}
                  openMenu={openMenu}
                  data={data}
                  title={titleMenu}
                />
              )}
            </div>
          </Toolbar>
        </AppBar>
      </Hidden>
      {isOpenMenu && (
        <ListOptionsMenu
          listenScrollEvent={() => {}}
          setIsOpenMenu={setIsOpenMenu}
          setHeader={setHeader}
          data={data}
          title={titleMenu}
        />
      )}
    </>
  );
}
