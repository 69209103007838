import React, { Children } from "react";
import ReactLoading from "react-loading";

import { Grid } from "@material-ui/core";

import Investment from "Components/Investment";
import { InvestmentType } from "Common/Types/InvestmentType";
import useStyles from "./styles";

interface Props {
  data: InvestmentType[];
  isLoading: boolean;
}

export default function GridProducts({ data, isLoading }: Props) {
  const classes = useStyles();

  return (
    <Grid container className={classes.contInvestments}>
      {!isLoading ? (
        <>
          {data &&
            Children.toArray(
              data.map((item: any) => (
                <Grid
                  item
                  md={4}
                  sm={6}
                  xs={12}
                  className={classes.itemInvestment}
                >
                  <Investment
                    id={item.id}
                    name={item.name}
                    shortDescription={item.shortDescription}
                    amount={item.amount}
                    sharesPercentage={item.sharesPercentage}
                    marketValue={item.marketValue}
                    potentialValue={item.potentialValue}
                    estimatedReturnMonths={item.estimatedReturnMonths}
                    mainPictureThumbnail={item.mainPictureThumbnail}
                  />
                </Grid>
              ))
            )}
        </>
      ) : (
        <ReactLoading
          type="spinningBubbles"
          color="#19283F"
          height={"8%"}
          width={"8%"}
        />
      )}
    </Grid>
  );
}
