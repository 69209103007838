export const arrayOfEntrepreneurs = [
  {
    id: 1,
    nameEs: "Registro",
    nameEn: "Registration",
    link: "signUp/Entrepreneur",
  },
  {
    id: 2,
    nameEs: "Petición de fondos",
    nameEn: "Funding Request",
    link: "fundingRequest",
  },
  {
    id: 3,
    nameEs: "Recursos",
    nameEn: "Resources",
    link: "resources/entrepreneurs",
  },
  {
    id: 4,
    nameEs: "¿Cómo funciona?",
    nameEn: "How it works",
    link: "resources/entrepreneur_how_it_works",
  },
];
export const arrayOfInvestors = [
  {
    id: 1,
    nameEs: "Registro",
    nameEn: "Registration",
    link: "signUp/Investor",
  },
  {
    id: 2,
    nameEs: "Recursos",
    nameEn: "Resources",
    link: "resources/investors",
  },
  {
    id: 3,
    nameEs: "¿Cómo funciona?",
    nameEn: "How it works",
    link: "resources/investor_how_it_works",
  },
];

export const categorySelect = {
  aerospace: false,
  agriculture: false,
  automotive: false,
  biotech: false,
  eCommerce: false,
  education: false,
  energy: false,
  entertainment: false,
  financial: false,
  hospitalityAndFoodServices: false,
  healthAndWellness: false,
  inventions: false,
  technologyWebApps: false,
  realState: false,
  retailClothing: false,
  sportsRecreation: false,
};
