import React from "react";
import { useTranslation } from "react-i18next";
// Import Material Ui / Formik Related
import { Grid } from "@material-ui/core";
// Import locals
import { NavListProps } from "Components/Header/PropsType";
import ItemMenu from "Components/Header/ItemMenu";
import useStyles from "./styles";

export default function NavList({ handleOpenMenu, header }: NavListProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const arrayOfList = [
    t("Investment Opportunities"),
    t("Entrepreneurs"),
    t("Investors"),
  ];
  return (
    <Grid className={classes.container}>
      {React.Children.toArray(
        arrayOfList.map((item) => (
          <ItemMenu
            name={item}
            handleOpenMenu={handleOpenMenu}
            header={header}
          />
        ))
      )}
    </Grid>
  );
}
