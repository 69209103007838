import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      width: "100%",
      height: "530px",
      display: "flex",
      justifyContent: "center",
      backgroundColor: theme.palette.info.main,
      [theme.breakpoints.down("md")]: {
        height: "auto",
        padding: "0 10px",
      },
    },
    mainText: {
      paddingTop: "4% !important",
      textAlign: "left",
      color: "#19283F",
      "& p": {
        fontSize: "25px",
        [theme.breakpoints.down("md")]: {
          fontSize: "20px",
        },
      },
      "& .BrainhubCarousel__container": {
        [theme.breakpoints.down("md")]: {
          marginTop: "-16%",
        },
      },
    },
    image: {
      maxWidth: "100%",
      height: "560px",
      position: "absolute",
      bottom: "0",
      [theme.breakpoints.down("md")]: {
        position: "static",
        height: "auto",
        display: "none",
      },
    },
    fadeInSection: {
      opacity: 0,
      transform: "translateY(10vh)",
      visibility: "hidden",
      transition:
        "opacity 1200ms ease-out, transform 600ms ease-out, visibility 1200ms ease-out",
      willChange: "opacity, transform, visibility",
    },
    isVisible: {
      opacity: 1,
      transform: "none",
      visibility: "visible",
    },
    title: {
      paddingBottom: "5%",
      "& b": {
        fontWeight: "bold",
      },
    },
    box: {
      padding: "50px",
      margin: "20px 0",
      "& span": {
        backgroundColor: "rgba(255, 255, 255, 0.5)",
        display: "inline-block",
        padding: "5px",
        borderRadius: "3px",
      },
    },
    paperCont: {
      width: 475,
      height: 320,
      background: "transparent",
      overflowY: "scroll",
      msOverflowStyle: "none",
      scrollbarWidth: "none",
      "&::-webkit-scrollbar": {
        display: "none",
      },
      boxShadow: "none",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
    cardCont: {
      display: "flex",
      background: "transparent",
      boxShadow: "none",
    },
    cardDetails: {
      display: "flex",
      flexDirection: "column",
    },
    content: {
      width: "400px",
      flex: "1 0 auto",
      paddingTop: "25px",
      [theme.breakpoints.down("md")]: {
        width: "100%",
        paddingTop: "16px",
      },
    },
    carousel: {
      transform: "rotate(90deg)",
      "& .BrainhubCarousel__track": {
        height: "480px",
      },
      "& .BrainhubCarousel__trackContainer": {
        maxWidth: "425px",
      },
    },
    carouselDetails: {
      transform: "rotate(270deg)",
      width: "525px",
    },
    imageComment: {
      width: "85px",
      borderRadius: "50%",
      height: "80px",
      marginTop: "30px",
    },
    comment: {
      fontSize: "17px !important",
      color: "#606060",
      fontWeight: "bold",
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px !important",
      },
    },
    author: {
      fontSize: "19px !important",
      color: "#606060",
      fontStyle: "italic !important",
      [theme.breakpoints.down("sm")]: {
        fontSize: "15px !important",
      },
    },
    container: {
      position: "relative",
      padding: 0,
      [theme.breakpoints.down("md")]: {
        width: "100%",
        marginTop: "30px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "static",
      },
    },
    errorLoading: {
      marginLeft: "40%",
    },
  })
);
