import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      marginTop: "5%",
      marginBottom: "5%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        paddingTop: "26%",
        paddingBottom: "16%",
      },
      [theme.breakpoints.between("sm", "md")]: {
        paddingTop: "12%",
        paddingBottom: "5%",
      },
    },
    banner: {
      marginTop: "80px",
      width: "100%",
      height: "240px",
      objectFit: "cover",
      objectPosition: "top",
    },
    title: {
      fontSize: "28px",
      marginBottom: "3%",
      [theme.breakpoints.down("md")]: {
        fontSize: "24px",
      },
    },
    box: {
      height: "700px",
      overflowY: "auto",
      marginTop: "2%",
      "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
        backgroundColor: "#dedede",
        width: "6px",
      },
      "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
        backgroundColor: "#1f2f46",
      },
      "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
        backgroundColor: "#192434",
      },
    },
    buttonDisable: {
      width: "100%",
      fontSize: "16px",
      fontFamily: "Helvetica",
      fontWeight: "bold",
      borderRadius: 0,
      borderBottom: "1px solid",
      borderTop: "1px solid transparent",
      color: "#47A6BE",
      display: "flex",
      justifyContent: "flex-start",
      paddingLeft: "5%",
      "&:hover": {
        border: "1px solid #47A6BE",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
        width: "100%",
      },
    },
    buttonActive: {
      width: "100%",
      fontSize: "16px",
      fontFamily: "Helvetica",
      fontWeight: "bold",
      borderRadius: 0,
      border: "1px solid #47A6BE",
      backgroundColor: "#47A6BE",
      color: "#FFFFFF",
      display: "flex",
      justifyContent: "flex-start",
      paddingLeft: "5%",
      "&:hover": {
        color: "#47A6BE",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
        width: "100%",
        "&:hover": {
          border: "1px solid #47A6BE",
          backgroundColor: "#47A6BE",
          color: "#FFFFFF",
        },
      },
    },
    buttonMainContainer: {
      [theme.breakpoints.down("md")]: {
        display: "flex",
        justifyContent: "space-evenly",
        "&> div": {
          width: "50%",
        },
      },
    },
  })
);
