// Import core modules
import React, { Dispatch } from "react";
import { DataListProps } from "../../PropsType";
// Import Material Ui / Formik Related

// Import Locals
import NavListMobile from "Components/Header/Mobile/NavListsMobile";
import {
  arrayOfEntrepreneurs,
  arrayOfInvestors,
} from "Common/Mocks/mockOption";
import useStyles from "./styles";

interface IMenu {
  closeMenu: Dispatch<React.SetStateAction<boolean>>;
  openMenu: boolean;
  data: DataListProps[];
  title: string;
}

export default function MenuSideBar({ data, title }: IMenu) {
  const classes = useStyles();
  return (
      <div className={classes.sideBar}>
        <NavListMobile
          arrayOfInvesment={data}
          arrayOfEntrepreneurs={arrayOfEntrepreneurs}
          arrayOfInvestors={arrayOfInvestors}
        />
      </div>
  );
}
