import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    textWhite: {
      color: "#FFFFFF",
    },
    textBlack: {
      color: "#19283F",
    },
    lightBlue: {
      color: "#47A6BE",
    },
    intellifund: {
      display: "flex",
    },
  })
);
