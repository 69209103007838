import React, { useState } from "react";
import * as yup from "yup";
import { useTranslation, Trans } from "react-i18next";
import { useMutation } from "react-query";

import {
  Typography,
  Button,
  FormControl,
  Box,
  Tooltip,
  Divider,
} from "@material-ui/core";
import LoadingButton from "@mui/lab/LoadingButton";
import HelpIcon from "@mui/icons-material/Help";
import { TextField } from "formik-material-ui";
import { Field, Formik } from "formik";

import { serializeMarketOpportunity } from "Utils/Serializers/serializeInvestment";
import {
  MarketOpportunity,
  BackendMarketOpportunity,
} from "Common/Types/InvestmentType";
import api from "Utils/AxiosConfig";
import useStyles from "Components/FundingRequest/styles";

interface MarketOpportunitiesProps {
  changeStep: (value: number) => void;
  userID: number;
  marketInfo: MarketOpportunity;
}

const INITIAL_VALUES = {
  internalAppraisal: "",
  externalAppraisal: "",
  strengths: "",
  weakness: "",
  opportunities: "",
  threats: "",
  marketNeeds: "",
  competitiveAdvantages: "",
};

export default function MarketOpportunities({
  changeStep,
  userID,
  marketInfo,
}: MarketOpportunitiesProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = yup.object().shape({

  });

  const mutation = useMutation(
    (data: BackendMarketOpportunity) =>
      api.put(
        `/api/investments/investments/create/${userID}/market_opportunity/`,
        data
      ),
    {
      onError(error) {
        setIsLoading(false);
        console.error("Error", error);
      },
      onSuccess: (data) => {
        changeStep(5);
      },
    }
  );

  function Submit(
    internalAppraisal: string,
    externalAppraisal: string,
    strengths: string,
    weakness: string,
    opportunities: string,
    threats: string,
    marketNeeds: string,
    competitiveAdvantages: string
  ) {
    const body: MarketOpportunity = {
      internalAppraisal,
      externalAppraisal,
      strengths,
      weakness,
      opportunities,
      threats,
      marketNeeds,
      competitiveAdvantages,
    };
    setIsLoading(true);
    mutation.mutate(serializeMarketOpportunity(body));
  }

  return (
    <>
      <Typography className={classes.title}>
        <Trans i18nKey="Strategic Business Plan / Market Evaluation">
          <strong>Strategic Business Plan / Market{" "}
          Evaluation</strong>
        </Trans>
      </Typography>

      <Formik
        initialValues={marketInfo ? marketInfo : INITIAL_VALUES}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          Submit(
            values.internalAppraisal,
            values.externalAppraisal,
            values.strengths,
            values.weakness,
            values.opportunities,
            values.threats,
            values.marketNeeds,
            values.competitiveAdvantages
          );
          resetForm({ values: INITIAL_VALUES });
          setSubmitting(false);
        }}
      >
        {({ submitForm, isSubmitting, errors, touched, values }) => (
          <form className={classes.mainForm} autoComplete="off">
            <Box className={classes.subtitleCtn}>
              <Typography className={classes.subtitle}>
                <Trans i18nKey="Internal Appraisal">
                  <strong>Internal Appraisal</strong>
                </Trans>
              </Typography>

              <Tooltip
                title={
                  <span className={classes.tooltip}>
                    <Trans i18nKey="Internal Appraisal Description">
                      {t(
                        `Internal appraisal means to evaluate the firms own capacities and to
                      meet the requirements of existing activities efficiently and effectively and
                      also to meet the challenges or threats indicated on the basis of external
                      appraisal.`
                      )}
                    </Trans>
                  </span>
                }
                placement="right"
              >
                <HelpIcon className={classes.helpIcon} />
              </Tooltip>
            </Box>
            <Divider className={classes.divider} />
            <FormControl fullWidth className={classes.formControlTextBox}>
              <Box className={classes.boxInput}>
                <Field
                  className={classes.inputTextBox}
                  placeholder={t("Limit to 1500 characters")}
                  name="internalAppraisal"
                  component={TextField}
                  InputProps={{ disableUnderline: true }}
                  multiline={true}
                  rows="5"
                  inputProps={{ maxLength: 1500 }}
                />
              </Box>
            </FormControl>
            <Box className={classes.subtitleCtn}>
              <Typography className={classes.subtitle}>
                <Trans i18nKey="External Appraisal">
                  <strong>External Appraisal</strong>
                </Trans>
              </Typography>
              <Tooltip
                title={
                  <span className={classes.tooltip}>
                    <Trans i18nKey="External Appraisal Description">
                      {t(
                        `External Appraisal is required to identity profit making
                      opportunities which can be exploited by the company’s strengths and
                      also to anticipate environmental threats (a declining economy,
                      competitors, actions, government legislation, industrial unrest etc.)
                      against which the company must protect itself.`
                      )}
                    </Trans>
                  </span>
                }
                placement="right"
              >
                <HelpIcon className={classes.helpIcon} />
              </Tooltip>
            </Box>
            <Divider className={classes.divider} />
            <FormControl fullWidth className={classes.formControlTextBox}>
              <Box className={classes.boxInput}>
                <Field
                  className={classes.inputTextBox}
                  placeholder={t("Limit to 1500 characters")}
                  name="externalAppraisal"
                  component={TextField}
                  InputProps={{ disableUnderline: true }}
                  multiline={true}
                  rows="5"
                  inputProps={{ maxLength: 1500 }}
                />
              </Box>
            </FormControl>
            <Box className={classes.subtitleCtn}>
              <Typography className={classes.subtitle}>
                <Trans i18nKey="SWOT Analysis">
                  <strong>SWOT Analysis</strong>
                </Trans>
              </Typography>
              <Tooltip
                title={
                  <span className={classes.tooltip}>
                    <Trans i18nKey="SWOT Analysis Description">
                      {t(
                        `SWOT (strengths, weaknesses, opportunities, and threats)
                      analysis is a framework used to evaluate a company&#39;s competitive
                      position and to develop strategic planning. SWOT analysis
                      assesses internal and external factors, as well as current and
                      future potential. A SWOT analysis is designed to facilitate a
                      realistic, fact-based, data-driven look at the strengths and
                      weaknesses of an organization, initiatives, or within its industry.
                      The organization needs to keep the analysis accurate by avoiding
                      pre-conceived beliefs or gray areas and instead focusing on real-
                      life contexts. Companies should use it as a guide and not
                      necessarily as a prescription.`
                      )}
                    </Trans>
                  </span>
                }
                placement="right"
              >
                <HelpIcon className={classes.helpIcon} />
              </Tooltip>
            </Box>
            <Divider className={classes.divider} />
            <Box className={classes.secondSubtitle}>
              <Typography variant="h5">{t("Strengths")}</Typography>
              <Tooltip
                title={
                  <span className={classes.tooltip}>
                    <Trans i18nKey="Strengths Description">
                      {t(
                        `Strengths describe what an organization excels at and
                      what separates it from the competition: a strong brand, loyal
                      customer base, a strong balance sheet, unique technology, and
                      so on. For example, a hedge fund may have developed a
                      proprietary trading strategy that returns market-beating results. It
                      must then decide how to use those results to attract new
                      investors.`
                      )}
                    </Trans>
                  </span>
                }
                placement="right"
              >
                <HelpIcon className={classes.helpIcon} />
              </Tooltip>
            </Box>
            <FormControl fullWidth className={classes.formControlTextBox}>
              <Box className={classes.boxInput}>
                <Field
                  className={classes.inputTextBox}
                  placeholder={t("Limit to 1500 characters")}
                  name="strengths"
                  component={TextField}
                  InputProps={{ disableUnderline: true }}
                  multiline={true}
                  rows="5"
                  inputProps={{ maxLength: 1500 }}
                />
              </Box>
            </FormControl>
            <Box className={classes.secondSubtitle}>
              <Typography variant="h5">{t("Weaknesses")}</Typography>
              <Tooltip
                title={
                  <span className={classes.tooltip}>
                    <Trans i18nKey="Weaknesses Description">
                      {t(
                        `Weaknesses stop an organization from performing at its optimum
                      level. They are areas where the business needs to improve to
                      remain competitive: a weak brand, higher-than-average turnover,
                      high levels of debt, an inadequate supply chain, or lack of capital.`
                      )}
                    </Trans>
                  </span>
                }
                placement="right"
              >
                <HelpIcon className={classes.helpIcon} />
              </Tooltip>
            </Box>
            <FormControl fullWidth className={classes.formControlTextBox}>
              <Box className={classes.boxInput}>
                <Field
                  className={classes.inputTextBox}
                  placeholder={t("Limit to 1500 characters")}
                  name="weakness"
                  component={TextField}
                  InputProps={{ disableUnderline: true }}
                  multiline={true}
                  rows="5"
                  inputProps={{ maxLength: 1500 }}
                />
              </Box>
            </FormControl>
            <Box className={classes.secondSubtitle}>
              <Typography variant="h5">{t("Opportunities")}</Typography>
              <Tooltip
                title={
                  <span className={classes.tooltip}>
                    <Trans i18nKey="Opportunities Description">
                      {t(
                        `Opportunities refer to favorable external factors that could give an
                      organization a competitive advantage. For example, if a country
                      cuts tariffs, a car manufacturer can export its cars into a new
                      market, increasing sales and market share.`
                      )}
                    </Trans>
                  </span>
                }
                placement="right"
              >
                <HelpIcon className={classes.helpIcon} />
              </Tooltip>
            </Box>
            <FormControl fullWidth className={classes.formControlTextBox}>
              <Box className={classes.boxInput}>
                <Field
                  className={classes.inputTextBox}
                  placeholder={t("Limit to 1500 characters")}
                  name="opportunities"
                  component={TextField}
                  InputProps={{ disableUnderline: true }}
                  multiline={true}
                  rows="5"
                  inputProps={{ maxLength: 1500 }}
                />
              </Box>
            </FormControl>
            <Box className={classes.secondSubtitle}>
              <Typography variant="h5">{t("Threats")}</Typography>
              <Tooltip
                title={
                  <span className={classes.tooltip}>
                    <Trans i18nKey="Threats Description">
                      {t(
                        `Threats refer to factors that have the potential to harm an
                      organization. For example, a drought is a threat to a wheat-
                      producing company, as it may destroy or reduce the crop yield.
                      Other common threats include things like rising costs for
                      materials, increasing competition, tight labor supply. and so on.`
                      )}
                    </Trans>
                  </span>
                }
                placement="right"
              >
                <HelpIcon className={classes.helpIcon} />
              </Tooltip>
            </Box>
            <FormControl fullWidth className={classes.formControlTextBox}>
              <Box className={classes.boxInput}>
                <Field
                  className={classes.inputTextBox}
                  placeholder={t("Limit to 1500 characters")}
                  name="threats"
                  component={TextField}
                  InputProps={{ disableUnderline: true }}
                  multiline={true}
                  rows="5"
                  inputProps={{ maxLength: 1500 }}
                />
              </Box>
            </FormControl>
            <Divider className={classes.divider} />
            <Box className={classes.secondSubtitle}>
              <Typography variant="h5">{t("Market Needs and Gaps")}</Typography>
              <Tooltip
                title={
                  <span className={classes.tooltip}>
                    <Trans i18nKey="Market Needs and Gaps Description">
                      {t(
                        `Needs are basic or advanced urges or demands that lead us to take
                      an appropriate action to fulfill them. In terms of marketing, needs are
                      the gaps which the companies try to fulfill with their products and
                      services.`
                      )}
                    </Trans>
                  </span>
                }
                placement="right"
              >
                <HelpIcon className={classes.helpIcon} />
              </Tooltip>
            </Box>
            <FormControl fullWidth className={classes.formControlTextBox}>
              <Box className={classes.boxInput}>
                <Field
                  className={classes.inputTextBox}
                  placeholder={t("Limit to 1500 characters")}
                  name="marketNeeds"
                  component={TextField}
                  InputProps={{ disableUnderline: true }}
                  multiline={true}
                  rows="5"
                  inputProps={{ maxLength: 1500 }}
                />
              </Box>
            </FormControl>
            <Box className={classes.secondSubtitle}>
              <Typography variant="h5">
                {t("Sustainable Competitive Advantages")}
              </Typography>
              <Tooltip
                title={
                  <span className={classes.tooltip}>
                    <Trans i18nKey="Sustainable Competitive Advantages Description">
                      {t(
                        `Sustainable competitive advantage describes company assets, abilities,
                      or attributes that are difficult to duplicate or exceed. The qualities of
                      these attributes mean the company that possesses them can enjoy a
                      superior and long-term position in its market or industry.`
                      )}
                    </Trans>
                  </span>
                }
                placement="right"
              >
                <HelpIcon className={classes.helpIcon} />
              </Tooltip>
            </Box>
            <FormControl fullWidth className={classes.formControlTextBox}>
              <Box className={classes.boxInput}>
                <Field
                  className={classes.inputTextBox}
                  placeholder={t("Limit to 1500 characters")}
                  name="competitiveAdvantages"
                  component={TextField}
                  InputProps={{ disableUnderline: true }}
                  multiline={true}
                  rows="5"
                  inputProps={{ maxLength: 1500 }}
                />
              </Box>
            </FormControl>
            <Box>
              <Button onClick={() => changeStep(3)} className={classes.button}>
                {t("BACK")}
              </Button>
              <LoadingButton
                className={!isLoading ? classes.button : classes.none}
                onClick={submitForm}
                loading={isLoading}
                variant="outlined"
              >
                {t("NEXT")}
              </LoadingButton>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
}
