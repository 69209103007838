import React from "react";
import { useTranslation, Trans } from "react-i18next";

import { Typography, Button } from "@material-ui/core";

import useStyles from "./styles";

export default function ResetSuccess() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Typography className={classes.title}>
        <Trans i18nKey="Your password has been modified successfully">
          Your password <b> has been modified successfully</b>
        </Trans>
      </Typography>
      <Typography className={classes.description}>
        {t("Please click on LOG IN to start again")}
      </Typography>
      <Button className={classes.button} href="/?login=true">
        {t("LOG IN")}
      </Button>
    </>
  );
}
