import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: "24px",
      marginBottom: "3%",
      [theme.breakpoints.down("md")]: {
        fontSize: "19px",
      },
    },
    formControl: {
      width: "55%",
      height: "80px",
      "&>div": {
        position: "relative",
        paddingBottom: ".85rem",
      },
      [theme.breakpoints.down("md")]: {
        width: "85%",
        height: "80px",
      },
    },
    input: {
      fontSize: "17px",
      padding: "4px 20px 4px 20px",
      textAlign: "start",
      fontStyle: "italic",
      fontFamily: "Aero Matics Regular",
      "& input": {
        fontSize: "17px",
        color: "#47A1B9",
        marginLeft: "3%",
        [theme.breakpoints.down("md")]: {
          fontSize: "12px",
        },
      },
      "&::placeholder": {
        color: "#47A1B9",
      },
      "&:focus": {
        outline: "none",
      },
    },
    mainForm: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    passwordBox: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "60px",
      "&>.MuiInputAdornment-root": {
        position: "absolute",
        top: "44%",
        right: "8%",
        "& button": {
          color: "#47A1B9",
        },
        [theme.breakpoints.down("md")]: {
          top: "40%",
          right: "10%",
        },
      },
      "&>.MuiFormControl-root": {
        "&>.MuiInputBase-root": {
          height: "45px",
          borderRadius: "12px",
          border: "2px solid #47A1B9",
          [theme.breakpoints.down("md")]: {
            height: "40px",
          },
        },
        "&>.MuiFormHelperText-root": {
          textAlign: "center",
          fontSize: "14px",
          fontWeight: "bold",
          [theme.breakpoints.down("md")]: {
            fontSize: "11px",
          },
        },
      },
    },
    button: {
      marginTop: "2%",
      background: "#19283F",
      borderRadius: "12px",
      border: "2px solid",
      color: "#FFFFFF",
      fontSize: "17px",
      letterSpacing: "1.5px",
      fontWeight: 500,
      paddingLeft: "15px",
      paddingRight: "15px",
      "&:hover": {
        background: "transparent",
        color: "#19283F",
        borderColor: "#19283F",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "12px",
        marginTop: 0,
      },
    },
  })
);
