import { Button, Container, Hidden, Typography } from "@material-ui/core";
import bannerEntrepreneur from "Assets/images/bannerEntrepreneur.jpg";
import bannerInvestor from "Assets/images/bannerInvestor.jpg";
import { UserStatusType } from "Common/Types/UserStatusType";
import AddressInformationEntrepreneur from "Components/Register/Entrepreneur/AddressInformation";
import PersonalInformationEntrepreneur from "Components/Register/Entrepreneur/PersonalInformation";
import StepperListEntrepreneur from "Components/Register/Entrepreneur/StepperList";
import AddressInformationInvestor from "Components/Register/Investor/AddressInformation";
import CompanyInformationInvestor from "Components/Register/Investor/CompanyInformation";
import InterestedIndustries from "Components/Register/Investor/InterestedIndustries";
import PersonalInformationInvestor from "Components/Register/Investor/PersonalInformation";
import StepperListInvestor from "Components/Register/Investor/StepperList";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Img } from "react-image";
import { useParams } from "react-router-dom";
import useStyles from "./styles";



interface SignUpType {
  type: string;
}

function RegisterSuccess() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Container className={classes.successContainer}>
      <Typography className={classes.title}>
        <Trans i18nKey="CONGRATULATIONS! your registration is complete.">
          CONGRATULATIONS!
          <b> your registration is complete</b>
        </Trans>
      </Typography>
      <Typography className={classes.description}>
        {t("Please click GO BACK HOME to start Wintellinvest.")}
      </Typography>
      <Button className={classes.button} href="/">
        {t("GO BACK HOME")}
      </Button>
    </Container>
  );
}

export default function SignUp(props: { location: { state: UserStatusType } }) {
  const classes = useStyles();
  const { location } = props;
  const [activeStep, setActiveStep] = useState(0);
  const [userID, setUserID] = useState(0);
  const [investorID, setInvestorID] = useState(0);
  const { type } = useParams<SignUpType>();
  
  useEffect(() => {
    if (location.state) {
      
      setActiveStep(location.state.user.status - 1);
      setUserID(location.state.id);
      setInvestorID(location.state.investor);
    }
  }, [location.state]);

  return (
    <>
      <Hidden only={["sm", "xs"]}>
        <Img
          src={type === "Entrepreneur" ? bannerEntrepreneur : bannerInvestor}
          className={classes.banner}
        />
      </Hidden>
      <Container maxWidth="md" className={classes.mainContainer}>
        {type === "Entrepreneur" ? (
          <>
            {activeStep !== 2 && (
              <StepperListEntrepreneur activeStep={activeStep} />
            )}
            {(() => {
              switch (activeStep) {
                case 0:
                  return (
                    <PersonalInformationEntrepreneur
                      changeStep={setActiveStep}
                      changeUserID={setUserID}
                    />
                  );
                case 1:
                  return (
                    <AddressInformationEntrepreneur
                      changeStep={setActiveStep}
                      userID={userID}
                    />
                  );
                case 2:
                  return <RegisterSuccess />;
                default:
                  break;
              }
            })()}
          </>
        ) : (
          <>
            {activeStep !== 4 && (
              <StepperListInvestor activeStep={activeStep} />
            )}
            {(() => {
              switch (activeStep) {
                case 0:
                  return (
                    <PersonalInformationInvestor
                      changeStep={setActiveStep}
                      changeUserID={setUserID}
                    />
                  );
                case 1:
                  return (
                    <AddressInformationInvestor
                      changeStep={setActiveStep}
                      userID={userID}
                    />
                  );
                case 2:
                  return (
                    <CompanyInformationInvestor
                      changeStep={setActiveStep}
                      userID={investorID}
                    />
                  );
                case 3:
                  return (
                    <InterestedIndustries
                      changeStep={setActiveStep}
                      userID={investorID}
                    />
                  );
                case 4:
                  return <RegisterSuccess />;
                default:
                  break;
              }
            })()}
          </>
        )}
      </Container>
    </>
  );
}
