import { BackendLabel, LabelType } from "../../Common/Types/LabelType";


export const serializeLabelToContext = (
  label: BackendLabel[]
): LabelType[] => {
  const serializeLabelMap = label && label.map
    ? label.map((item) => {
        const serializedLabel: LabelType = {
          nameSlug: item.name_slug,
          valueEn: item.value_en,
          valueEs: item.value_es,
        };
        return serializedLabel;
      })
    : [];
  return serializeLabelMap;
};
