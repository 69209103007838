import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useHistory } from "react-router-dom";

import {
  Dialog,
  FormControl,
  Grid,
  MenuItem,
  Select,
  IconButton,
  Button,
  Paper,
  Input,
} from "@material-ui/core";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import SearchIcon from "@mui/icons-material/Search";

import LogIn from "Components/LogIn";
import setYupLocale from "Utils/Yup";
import { availableLanguages } from "Utils/Constants";
import SignUp from "Components/SignUpDialog";
import CustomButton from "Components/CustomButton";
import { userContext } from "Context/UserSesion/UserContext";
import useStyles from "./styles";

function GetQueryParams() {
  return new URLSearchParams(useLocation().search);
}

export default function NavListButtons({ header }: { header: boolean }) {
  const [isOpenModalLogIn, setIsOpenModalLogIn] = useState(false);
  const [isOpenModalSignUp, setIsOpenModalSignUp] = useState(false);
  const [onSearch, setOnSearch] = useState("");
  const history = useHistory();
  const { user: isAuth, setUser } = useContext(userContext);
  const { i18n, t } = useTranslation();
  const props = {
    color: header ? "#000" : "#FFF",
  };

  const loginParam = GetQueryParams().get("login");
  const signUpParam = GetQueryParams().get("signUp");

  useEffect(() => {
    if (loginParam) setIsOpenModalLogIn(true);
    if (signUpParam) setIsOpenModalSignUp(true);
  }, [loginParam, signUpParam]);

  const classes = useStyles(props);

  const handleChageLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    setYupLocale();
  };

  const logout = () => {
    setUser({
      token: "",
    });
  };
  const handlePushShearch = () => {
    history.push("/search", onSearch);
  };
  const handleChange = (e: { target: { value: string } }) => {
    const { value } = e.target;
    setOnSearch(value);
  };

  return (
    <Grid className={!isAuth.token ? classes.container : classes.searchCtn}>
      <Paper className={classes.searchBar}>
        <IconButton>
          <SearchIcon fontSize={"small"} />
        </IconButton>
        <Input
          placeholder={t("Search")}
          disableUnderline={true}
          onChange={handleChange}
          onKeyPress={(e) => {
            if (e.key === "Enter") handlePushShearch();
          }}
        />
      </Paper>
      {!isAuth.token ? (
        <>
          <CustomButton
            label={t("LOG IN")}
            header={header}
            outlined
            mobileMode={false}
            handleOpenModal={() => setIsOpenModalLogIn(true)}
          />
          <CustomButton
            label={t("SIGN UP")}
            header={false}
            outlined={false}
            mobileMode={false}
            handleOpenModal={() => setIsOpenModalSignUp(true)}
          />
        </>
      ) : (
        <Button className={classes.logout} onClick={logout} endIcon={<ExitToAppIcon/>} href="/">
          Logout
        </Button>
      )}
      <FormControl className={classes.formControl}>
        <Select
          value={i18n.language}
          displayEmpty
          className={classes.select}
          inputProps={{
            classes: {
              icon: classes.icon,
            },
          }}
        >
          {React.Children.toArray(
            availableLanguages.map((lng: string, index: number) => (
              <MenuItem
                value={availableLanguages[index]}
                onClick={() => handleChageLanguage(lng)}
                className={classes.languageMenu}
              >
                {availableLanguages[index]}
              </MenuItem>
            ))
          )}
        </Select>
      </FormControl>
      {isOpenModalLogIn && (
        <Dialog
          open={isOpenModalLogIn}
          onClose={() => setIsOpenModalLogIn(false)}
          maxWidth={"xl"}
          scroll={"body"}
        >
          <LogIn onClose={() => setIsOpenModalLogIn(false)} />
        </Dialog>
      )}

      {isOpenModalSignUp && (
        <Dialog
          open={isOpenModalSignUp}
          onClose={() => setIsOpenModalSignUp(false)}
          maxWidth={"xl"}
          scroll={"body"}
        >
          <SignUp onClose={() => setIsOpenModalSignUp(false)} />
        </Dialog>
      )}
    </Grid>
  );
}
