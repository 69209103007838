import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import * as Yup from "yup";
import { Trans, useTranslation } from "react-i18next";

import {
  Grid,
  Typography,
  Paper,
  Button,
  FormHelperText,
  Container,
  Box,
  FormControl,
  InputAdornment,
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";

import api from "Utils/AxiosConfig";
import useStyles from "./styles";

const INITIAL_VALUES = {
  email: "",
};

export default function Newsletter() {
  const classes = useStyles();
  const [visibleCheck, setVisibleCheck] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const timeout = setTimeout(() => {
      setVisibleCheck(false);
      setErrorMessage(false);
    }, 3000);
    return () => clearTimeout(timeout);
  }, [visibleCheck, errorMessage]);

  const mutation = useMutation(
    (data: { email: string }) => api.post("api/users/subscriptions/", data),
    {
      onError: (error) => {
        console.error("Error: ", error);
        setErrorMessage(true);
      },
      onSuccess: () => {
        setVisibleCheck(true);
      },
    }
  );

  const handleSendButton = (email: string) => {
    const body = {
      email,
    };
    mutation.mutate(body);
  };

  const validationSchema = () =>
    Yup.object({
      email: Yup.string()
        .email(t("Invalid email address"))
        .required(t("Email required")),
    });

  return (
    <Container maxWidth="md" className={classes.container}>
      <Grid
        container
        justifyContent="center"
        spacing={2}
        className={classes.nsContainer}
      >
        <Grid item md={7} className={classes.mainText}>
          <Typography>
            <Trans i18nKey="Be the first to hear about our investment <br /> opportunities when they launch.">
              Be the first to hear about our investment <br />
              opportunities when they launch.
            </Trans>
          </Typography>
        </Grid>
        <Grid item md={5} className={classes.mainInput}>
          <Formik
            initialValues={INITIAL_VALUES}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              handleSendButton(values.email);
              resetForm({ values: INITIAL_VALUES });
              setSubmitting(false);
            }}
          >
            {({ submitForm, isSubmitting, touched, errors }) => (
              <Form className={classes.form}>
                <FormControl fullWidth>
                  <Paper className={classes.emailInput}>
                    <Field
                      component={TextField}
                      className={classes.input}
                      placeholder={t("E-mail")}
                      name="email"
                      InputProps={{ disableUnderline: true }}
                    />
                    <InputAdornment position="end">
                      <Button
                        type="submit"
                        disabled={isSubmitting}
                        className={classes.iconButton}
                      >
                        {visibleCheck ? `${t("SUBSCRIBED")}` : `${t("SEND")}`}
                      </Button>
                    </InputAdornment>
                  </Paper>
                  {touched.email && errors.email ? (
                    <Box className={classes.suscribeCtn}>
                      <FormHelperText className={classes.suscribeErrMsg}>
                        {errors.email}
                      </FormHelperText>
                    </Box>
                  ) : null}
                  {visibleCheck && (
                    <Box className={classes.suscribeCtn}>
                      <FormHelperText className={classes.suscribeMsg}>
                        {t("¡Thanks for suscribing!")}
                      </FormHelperText>
                      <CheckCircleIcon className={classes.checkIcon} />
                    </Box>
                  )}
                  {errorMessage && (
                    <Box className={classes.suscribeCtn}>
                      <FormHelperText className={classes.suscribeErrMsg}>
                        {t("¡You are already subscribed!")}
                      </FormHelperText>
                    </Box>
                  )}
                </FormControl>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Container>
  );
}
