import { Box, CircularProgress } from "@material-ui/core";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Country } from "Common/Types/Country";
import { SetStateAction, useState } from "react";
import { useQuery } from "react-query";
import api from "Utils/AxiosConfig";
import { serializeCountry } from "Utils/Serializers/serializeCountry";
import useStyles from "./styles";



interface Props {
  placeholder: string;
  label?: string;
  handleChangeFormik: any;
  type: string;
  currentCountry?: string;
  currentState?: string;
  disabled?: boolean;
  handleForm?: (value: Country | null) => void;
}

function AutoComplete({
  placeholder,
  label,
  handleChangeFormik,
  type,
  currentCountry,
  currentState,
  handleForm,
}: Props) {
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [countries, setCountries] = useState<Country[]>([]);
  const [searchContries, setSearchContries] = useState("");
  const getCountry = (page: number) =>
    api
      .get(`/api/places/countries/?page=${page}&search=${searchContries}`)
      .then((res) => res);

  const getState = (page: number) =>
    api
      .get(`/api/generics/states/?page=${page}&search=${searchContries}&country=${currentCountry}`)
      .then((res) => res);

  let url = `/api/generics/cities/?page=${page}&search=${searchContries}&country=${currentCountry}&state=${currentState}`;
  if (String(currentCountry) !== "237"){
    // not U.S.
    url = `/api/generics/cities/?page=${page}&search=${searchContries}&country=${currentCountry}`;
  }
  const getCity = (page: number) =>
    api
      .get(url)
      .then((res) => res);

  const { isLoading } = useQuery(
    [type, page, searchContries, currentCountry, currentState],
    () => {
      if (type === "country") {
        return getCountry(page);
      }
      if (type == "state") {
        return getState(page);
      }
      if (type == "city") {
        return getCity(page);
      }
      return getCity(page);
    },
    {
      keepPreviousData: true,
      onSuccess: (res) => {
        if (type === "country") {
          const data = serializeCountry(res.data.results);
          setCountries((prevState) =>
            page !== 1 ? prevState.concat(data) : data
          );
        }
        if (type === "state") {
          const listStates = res.data && res.data.map ? res.data.map((item: any) => {
            return {
              code: '',
              id: item.id,
              name: item.name,
            };
          }) : [];
          const data = serializeCountry(listStates);
          setCountries((prevState) =>
            page !== 1 ? prevState.concat(data) : data
          );
        }
        if (type === "city") {
          const listCities = res.data && res.data.map ? res.data.map((item: any) => {
            return {
              code: '',
              id: item.id,
              name: item.name,
            };
          }) : [];
          const data = serializeCountry(listCities);
          setCountries((prevState) =>
            page !== 1 ? prevState.concat(data) : data
          );
        }
      },
    }
  );

  const handleSearch = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setSearchContries(event.target.value);
    setPage(1);
  };
  return (
    <form noValidate>
    <Autocomplete
      id={type}
      options={countries}
      onChange={(e, value) => {
        handleChangeFormik(type, value !== null ? value.id : "");
        handleForm && handleForm(value);
      }}
      ListboxProps={{
        onScroll: (event: React.SyntheticEvent) => {
          const listboxNode = event.currentTarget;
          if (
            listboxNode.scrollTop + listboxNode.clientHeight ===
            listboxNode.scrollHeight
          ) {
            setPage((prev) => prev + 1);
          }
        },
      }}
      getOptionLabel={(option) => option.name}
      className={classes.autoComplete}
      loading={isLoading}
      placeholder={placeholder}
      renderOption={(props, option) =>
        type !== "county" ? (
          <Box component="li" {...props}>
            <img
              loading="lazy"
              width="20"
              className={classes.imgFlag}
              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
              alt=""
            />
            {option.name}
            {type === "phoneNumber" ? `(+${option.phoneCode})` : ""}
          </Box>
        ) : (
          <Box component="li" {...props}>
            {option.name}
          </Box>
        )
      }
      renderInput={(params) => {
        return (<TextField
          {...params}
          name={type}
          className={classes.autoCompleteText}
          onClick={() => {
            setSearchContries("");
            setPage(1);
          }}
          onChange={handleSearch}
          label={label}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />);
      }}
    />
    </form>
  );
}

export default AutoComplete;
