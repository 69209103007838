import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: "24px",
      marginBottom: "3%",
      marginTop: "2%",
      [theme.breakpoints.down("sm")]: {
        marginBottom: "7%",
        marginTop: "6%",
        fontSize: "20px",
      },
      [theme.breakpoints.between("sm", "md")]: {
        marginBottom: "4%",
        marginTop: "3%",
      },
    },
    secondSubtitle: {
      display: "flex",
      width: "50%",
      alignItems: "flex-end",
      "& h5": {
        fontSize: "17px",
        textAlign: "left",
        fontWeight: 'bold',
      },
      "& h6": {
        fontSize: "15px",
        textAlign: "left",
      },
    },
    formControl: {
      width: "55%",
      height: "65px",
      padding: "4px 20px 4px 20px",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        height: "60px",
      },
      [theme.breakpoints.between("sm", "md")]: {
        height: "65px",
      },
    },
    input: {
      fontSize: "17px",
      fontStyle: "italic",
      fontFamily: "Aero Matics Regular",
      "& ::-ms-reveal": {
        display: "none",
      },
      "& input": {
        fontSize: "17px",
        color: theme.palette.secondary.main,
        padding: "7px 20px",
        [theme.breakpoints.down("sm")]: {
          fontSize: "15px",
        },
        [theme.breakpoints.between("sm", "md")]: {
          fontSize: "16px",
        },
        "&::placeholder": {
          opacity: "0.7",
        },
      },
      "&:focus": {
        outline: "none",
      },
    },
    mainForm: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    boxInput: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      paddingBottom: "4%",
      "&>.MuiFormControl-root": {
        "&>.MuiInputBase-root": {
          borderRadius: "12px",
          border: `2px solid ${theme.palette.secondary.main}`,
        },
        "&>.MuiFormHelperText-root": {
          textAlign: "center",
          fontSize: "14px",
          fontWeight: "bold",
          color: "red",
          [theme.breakpoints.down("sm")]: {
            fontSize: "12px",
          },
          [theme.breakpoints.between("sm", "md")]: {
            fontSize: "13px",
          },
        },
      },
      [theme.breakpoints.between("sm", "md")]: {
        paddingBottom: "2%",
      },
    },
    button: {
      marginTop: "2%",
      background: "#19283F",
      borderRadius: "12px",
      border: "2px solid",
      color: "#FFFFFF",
      fontSize: "17px",
      letterSpacing: "1.5px",
      fontWeight: 500,
      paddingLeft: "15px",
      paddingRight: "15px",
      "&:hover": {
        background: "transparent",
        color: "#19283F",
        borderColor: "#19283F",
      },
    },
    buttonGoogle: {
      textTransform: "none",
      background: "#E7E6E6",
      fontSize: "14px",
      height: "34px",
      color: "#6E6E6E",
      fontWeight: 500,
      borderRadius: "12px",
    },
    buttonFacebook: {
      cursor: "pointer",
      color: "#FFFFFF",
      width: "100%",
      border: "1px solid",
      textTransform: "none",
      background: "#1977ED",
      fontSize: "14px",
      height: "34px",
      fontWeight: 500,
      borderRadius: "12px",
      "&:hover": {
        opacity: ".9",
      },
    },
    formGroup: {
      marginTop: "2%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      width: "50%",
      textAlign: "left",
      "& span": {
        fontSize: "16px",
        lineHeight: "1.2",
        [theme.breakpoints.down("sm")]: {
          fontSize: "15px",
        },
        [theme.breakpoints.between("sm", "md")]: {
          fontSize: "16px",
        },
      },
      "& label": {
        paddingBottom: "2%",
        [theme.breakpoints.between("sm", "md")]: {
          paddingBottom: 0,
        },
      },
      [theme.breakpoints.down("sm")]: {
        marginTop: "5%",
        marginBottom: "2%",
        width: "85%",
      },
      [theme.breakpoints.between("sm", "md")]: {
        marginTop: "2%",
        width: "92%",
      },
    },
    helpText: {
      color: "red",
      fontSize: "14px",
      fontWeight: 500,
    },
    buttonTerms: {
      padding: 0,
      textTransform: "none",
      fontFamily: "'Aero Matics Regular'",
      fontSize: "0.85rem",
      color: "#47A6BE",
      paddingBottom: "2px",
      "& span": {
        color: theme.palette.secondary.main,
        fontWeight: "bold",
        textDecoration: "underline",
      },
    },
    socialContainer: {
      width: "56%",
      [theme.breakpoints.down("sm")]: {
        width: "98%",
      },
      [theme.breakpoints.between("sm", "md")]: {
        width: "100%",
      },
    },
  })
);
