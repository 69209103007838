import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { styled } from "@mui/material";

export default makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      width: "15%",
      [theme.breakpoints.down("sm")]: {
        width: "35%",
      },
      [theme.breakpoints.between("sm", "md")]: {
        width: "20%",
      },
    },
    stepLabel: {
      backgroundColor: "red",
    },
  })
);

export const ColorlibStepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ ownerState }) => ({
  border: "2px solid #19283F",
  zIndex: 1,
  color: "#19283F",
  width: 30,
  height: 30,
  display: "flex",
  fontSize: "16px",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    background: "#19283F",
    color: "#fff",
  }),
  ...(ownerState.completed && {
    background: "#19283F",
    color: "#fff",
  }),
}));
