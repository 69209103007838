import {
  Box, Button, Container,
  Dialog,
  Grid, Link, Typography
} from "@material-ui/core";
import fac from "Assets/images/face.svg";
import tiw from "Assets/images/gorjeo.svg";
import ins from "Assets/images/instagram.svg";
import lin from "Assets/images/linkedin.svg";
import IntelliFund from "Components/IntelliFund";
import LogIn from "Components/LogIn";
import SignUp from "Components/SignUpDialog";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Img } from "react-image";
import useStyles from "./styles";



export default function Footer() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isOpenModalLogIn, setIsOpenModalLogIn] = useState(false);
  const [isOpenModalSignUp, setIsOpenModalSignUp] = useState(false);

  return (
    <Grid className={classes.footer}>
      <Container maxWidth="md" className={classes.containerFooter}>
        <Grid className={classes.intellifund}>
          <IntelliFund header={false} size="h5" />
          <Typography align="left" className={classes.text}>
            {t(
              `Wintellinvest provides you with the Financial, Business, and Management support you need you to make your ideas and dreams a reality`
            )}
          </Typography>
          <Grid className={classes.social}>
          <Link
              href={"https://www.facebook.com/Wintellinvest-101709885880800"}
              className={classes.link}
              underline="none"
            >
            <Img src={fac} className={classes.icon} />
            </Link>

            <Link
              href={"https://www.instagram.com/wintellinvest/"}
              className={classes.link}
              underline="none"
            >
            <Img src={ins} className={classes.icon} />
            </Link>

            <Link
              href={"https://twitter.com/wintellinvest"}
              className={classes.link}
              underline="none"
            >
              <Img src={tiw} className={classes.icon} />
            </Link>

            <Link
              href={"https://www.linkedin.com/company/wintellinvest/"}
              className={classes.link}
              underline="none"
            >
              <Img src={lin} className={classes.icon} />
            </Link>
          </Grid>
        </Grid>
        <Grid className={classes.intellifund}>
          <Container disableGutters className={classes.titleRow}>
            <Box className={classes.box} />
            <Typography variant="h5" align="left" className={classes.title}>
              {t("SITE MAP")}
            </Typography>
            <Link
              href={"/fundingRequest"}
              className={classes.link}
              underline="none"
            >
              {t("Investment Opportunities")}
            </Link>
            <Link
              href={"/signUp/Entrepreneur"}
              className={classes.link}
              underline="none"
            >
              {t("Entrepreneurs")}
            </Link>
            <Link
              className={classes.link}
              underline="none"
              href={"/signUp/Investor"}
            >
              {t("Investors")}
            </Link>
            <Link
              onClick={() => setIsOpenModalLogIn(true)}
              className={classes.link}
              underline="none"
            >
              {t("Log In")}
            </Link>
            <Link
              onClick={() => setIsOpenModalSignUp(true)}
              className={classes.link}
              underline="none"
            >
              {t("Sign Up")}
            </Link>
            <Link
              href={"#root"}
              className={classes.link}
              underline="none"
            >
              {t("Search")}
            </Link>
          </Container>
        </Grid>
        <Grid className={classes.intellifund}>
          <Container disableGutters className={classes.titleRow}>
            <Box className={classes.box} />
            <Typography variant="h5" align="left" className={classes.title}>
              {t("ENTREPRENEURS")}
            </Typography>
            <Link
              href={`/resources/entrepreneur_how_it_works`}
              className={classes.link}
              underline="none"
            >
              {t("How it works")}
            </Link>
            <Link
              href={"/resources/entrepreneurs"}
              className={classes.link}
              underline="none"
            >
              {t("Resources")}
            </Link>
            <Link
              href={"/resources/entrepreneur_help_and_support"}
              className={classes.link}
              underline="none"
            >
              {t("Help and Support")}
            </Link>
          </Container>
        </Grid>
        <Grid className={classes.intellifund}>
          <Container disableGutters className={classes.titleRow}>
            <Box className={classes.box} />
            <Typography variant="h5" align="left" className={classes.title}>
              {t("INVESTORS")}
            </Typography>
            <Link
              href={"/resources/investor_how_it_works"}
              className={classes.link}
              underline="none"
            >
              {t("How it works")}
            </Link>
            <Link
              href={"/resources/investors"}
              className={classes.link}
              underline="none"
            >
              {t("Resources")}
            </Link>
            <Link
              href={"/resources/investor_help_and_support"}
              className={classes.link}
              underline="none"
            >
              {t("Help and Support")}
            </Link>
          </Container>
        </Grid>
        <Grid className={classes.Terms}>
          <Typography display={"inline"}>
            Worldwide Intelligent Technologies LLC. -
          </Typography>
          <Typography display={"inline"} className={classes.spanColor}>
            <Button
              className={classes.buttonTerms}
              href="/termsConditions"
              target="_blank"
            >
              {t("Terms & Conditions")}
            </Button>
            <Trans i18nKey="and">and</Trans>
            <Button
              className={classes.buttonTerms}
              href="/intellectualProperty"
              target="_blank"
            >
              {t("Intellectual Property")}
            </Button>
          </Typography>
        </Grid>
        {isOpenModalLogIn && (
          <Dialog
            open={isOpenModalLogIn}
            onClose={() => setIsOpenModalLogIn(false)}
            maxWidth={"xl"}
            scroll={"body"}
          >
            <LogIn onClose={() => setIsOpenModalLogIn(false)} />
          </Dialog>
        )}

        {isOpenModalSignUp && (
          <Dialog
            open={isOpenModalSignUp}
            onClose={() => setIsOpenModalSignUp(false)}
            maxWidth={"xl"}
            scroll={"body"}
          >
            <SignUp onClose={() => setIsOpenModalSignUp(false)} />
          </Dialog>
        )}
      </Container>
    </Grid>
  );
}
