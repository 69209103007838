import {
  Box, Divider, List, ListItem,
  ListItemText, TextField, Typography
} from "@material-ui/core";
import { ListItemButton } from "@mui/material";
import { DataListProps } from "Components/Header/PropsType";
import i18n from "i18next";
import React, { Children, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactLoading from "react-loading";
import { useQuery } from "react-query";
import api from "Utils/AxiosConfig";
import { serializeCategoryToContext } from "Utils/Serializers/serializeCategory";
import useStyles from "./styles";



interface AmountMarket {
  amountlte: number | undefined;
  amountgte: number | undefined;
  marketvaluegte: number | undefined;
  marketvaluelte: number | undefined;
  sharespercentagelte: number | undefined;
  sharespercentagegte: number | undefined;
}

interface Prop {
  setCategory: (index: number) => void;
  setAmountMarket: (amount: AmountMarket) => void;
  amountMarket: AmountMarket;
}

export default function GridInvestments({
  setCategory,
  setAmountMarket,
  amountMarket,
}: Prop) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [categoriesData, setCategoriesData] = useState<DataListProps[]>([]);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const { isLoading } = useQuery(
    "categoriesFetch",
    async () => api.get("api/investments/categories"),
    {
      onSuccess: (res) => {
        const data = res.data;
        setCategoriesData(serializeCategoryToContext(data));
      },
      onError: () => {
        setCategoriesData([]);
      },
    }
  );

  const handleListItemClick = (index: number) => {
    setCategory(index);
    setSelectedIndex(index);
  };

  const handleChangeInvestment = (e: {
    target: { name: string; value: string };
  }) => {
    const { name, value } = e.target;
    setAmountMarket({
      ...amountMarket,
      [name]: value ? Number(value) : undefined,
    });
  };

  return (
    <>
      <Typography className={classes.title} align="left">
        {t("FILTERS")}
      </Typography>
      <Divider className={classes.divider} />
      <Typography className={classes.subtitle} align="left">
        {t("CATEGORIES")}
      </Typography>
      {!isLoading ? (
        <List className={classes.categoriesCtn}>
          {categoriesData &&
            Children.toArray(
              categoriesData.map((category) => (
                <ListItem>
                  <ListItemButton
                    component="a"
                    selected={selectedIndex === category.id}
                  >
                    <ListItemText
                      onClick={(event) => handleListItemClick(category.id)}
                      primary={`${
                        i18n.language === "EN"
                          ? category.nameEn
                          : category.nameEs
                      }`}
                    />
                  </ListItemButton>
                </ListItem>
              ))
            )}
        </List>
      ) : (
        <ReactLoading
          type="spinningBubbles"
          color="#19283F"
          height={"8%"}
          width={"8%"}
        />
      )}
      <Divider className={classes.divider} />
      <Typography className={classes.title} align="left">
        {t("REQUIRED INVESTMENT (USD)")}
      </Typography>
      <Divider className={classes.divider} />
      <Box className={classes.inputCtn}>
        <Typography variant="h5">{t("From")}:</Typography>
        <TextField
          name="amountgte"
          onChange={handleChangeInvestment}
          className={classes.input}
          InputProps={{ disableUnderline: true }}
          type="number"
        />
        <Divider className={classes.inputDivider} />
        <Typography variant="h5">{t("To")}:</Typography>
        <TextField
          name="amountlte"
          onChange={handleChangeInvestment}
          className={classes.input}
          InputProps={{ disableUnderline: true }}
          type="number"
        />
      </Box>
      <Divider className={classes.divider} />
      <Typography className={classes.title} align="left">
        {t("MARKET VALUE (USD)")}
      </Typography>
      <Divider className={classes.divider} />
      <Box className={classes.inputCtn}>
        <Typography variant="h5">{t("From")}:</Typography>
        <TextField
          name="marketvaluegte"
          onChange={handleChangeInvestment}
          className={classes.input}
          InputProps={{ disableUnderline: true }}
          type="number"
        />
        <Divider className={classes.inputDivider} />
        <Typography variant="h5">{t("To")}:</Typography>
        <TextField
          name="marketvaluelte"
          onChange={handleChangeInvestment}
          className={classes.input}
          InputProps={{ disableUnderline: true }}
          type="number"
        />
      </Box>
      <Divider className={classes.divider} />
      <Typography className={classes.title} align="left">
        {t("% OF SHARES")}
      </Typography>
      <Divider className={classes.divider} />
      <Box className={classes.inputCtn}>
        <Typography variant="h5">{t("From")}:</Typography>
        <TextField
          name="sharespercentagegte"
          onChange={handleChangeInvestment}
          className={classes.input}
          InputProps={{ disableUnderline: true }}
          type="number"
        />
        <Divider className={classes.inputDivider} />
        <Typography variant="h5">{t("To")}:</Typography>
        <TextField
          name="sharespercentagelte"
          onChange={handleChangeInvestment}
          className={classes.input}
          InputProps={{ disableUnderline: true }}
          type="number"
        />
      </Box>
      <br />
    </>
  );
}
