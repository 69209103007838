import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";

import { Button, Container, Link } from "@material-ui/core";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import api from "Utils/AxiosConfig";
import useStyles from "./styles";

export default function ResourcesEntrepreneur(props: { blogSlug: string }) {
  const classes = useStyles();
  const { blogSlug } = props;
  const { i18n } = useTranslation();
  const [contentBlog, setContentBlog] = useState("");
  const [contentEsBlog, setContentEsBlog] = useState("");
  const [contentTitle, setContentTitle] = useState("");
  const [contentTitleEs, setContentTitleEs] = useState("");
  const [useEnglishContent, setUseEnglishContent] = useState(true);
  const [bogSet, setBogSet] = useState([]);

  useEffect(() => {
    if (i18n.language === "ES") {
      setUseEnglishContent(false);
    } else {
      setUseEnglishContent(true);
    }
  }, [i18n.language]);

  useQuery(
    "getEntrepreneurBlogs",
    () => api.get("api/blogs/blogs/" + blogSlug + "/"),
    {
      onSuccess: (res) => {
        setContentBlog(res.data["content"]);
        setContentEsBlog(res.data["content_es"]);
        setBogSet(res.data["blog_set"]);
        setContentTitle(res.data["title"]);
        setContentTitleEs(res.data["title_es"]);
      },
    }
  );

  return (
    <>
      <Button className={classes.butonBlog}>
        <b>{useEnglishContent ? contentTitle : contentTitleEs}</b>
      </Button>
      <Container className={classes.textInvestment}>
        {
          bogSet.map(({ id, title, title_es, slug }, index) =>
            <>
              <Link
                variant="h6"
                align="left"
                underline="none"
                href={`/resources/${slug}/`}
                className={classes.textItem}
              >
                {useEnglishContent ? title : title_es}
                <ArrowForwardIosIcon color="primary" className={classes.arrowIcon} />
              </Link>
              <br />
            </>
          )
        }
      </Container>
      <div
        className={classes.containerResource}
        dangerouslySetInnerHTML={{
          __html: useEnglishContent ? contentBlog : contentEsBlog,
        }}
      />
    </>
  );
}
