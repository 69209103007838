import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import enTranslation from "Common/language/locale/EN/enTranslation.json";
import esTranslation from "Common/language/locale/ES/esTranslation.json";
import setYupLocale from "Utils/Yup";

const defaulLgn = (): string => {
  const lng: string = localStorage.getItem("intellifund-language") || "es-US";
  return lng?.toString() === "es-US" ? "EN" : lng?.toString();
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(
    {
      detection: {
        lookupLocalStorage: "intellifund-language",
        lookupSessionStorage: "intellifund-language",
      },
      lng: defaulLgn(),
      debug: false,
      fallbackLng: ["EN", "ES"],
      interpolation: {
        escapeValue: false,
      },
      resources: {
        ES: esTranslation,
        EN: enTranslation,
      },
    },
    () => setYupLocale()
  );

export default i18n;
