import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: "24px",
      marginBottom: "3%",
      marginTop: "2%",
      [theme.breakpoints.down("sm")]: {
        marginBottom: "7%",
        marginTop: "6%",
        fontSize: "20px",
      },
      [theme.breakpoints.between("sm", "md")]: {
        marginBottom: "4%",
        marginTop: "3%",
      },
    },
    secondSubtitleFull: {
      display: "flex",
      alignItems: "flex-end",
      "& h5": {
        fontSize: "17px",
        textAlign: "left",
        fontWeight: 'bold',
      },
      "& h6": {
        fontSize: "15px",
        textAlign: "left",
      },
    },
    secondSubtitle: {
      display: "flex",
      width: "50%",
      alignItems: "flex-end",
      "& h5": {
        fontSize: "17px",
        textAlign: "left",
        fontWeight: 'bold',
      },
      "& h6": {
        fontSize: "15px",
        textAlign: "left",
      },
    },
    formControl: {
      width: "55%",
      padding: "4px 20px 4px 20px",
      
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      
      },
      [theme.breakpoints.between("sm", "md")]: {
      
      },
    },
    input: {
      fontSize: "17px",
      fontStyle: "italic",
      fontFamily: "Aero Matics Regular",
      "& input": {
        fontSize: "17px",
        color: theme.palette.secondary.main,
        padding: "7px 20px",
        [theme.breakpoints.down("sm")]: {
          fontSize: "15px",
        },
        [theme.breakpoints.between("sm", "md")]: {
          fontSize: "16px",
        },
        "&::placeholder": {
          opacity: "0.7",
        },
      },
      "&::placeholder": {
        color: theme.palette.secondary.main,
      },
      "&:focus": {
        outline: "none",
      },
    },
    countryBox: {
      display: "flex",
      width: "100%",
    },
    mainForm: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    boxInput: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      "&>.MuiFormControl-root": {
        "&>.MuiInputBase-root": {
          borderRadius: "12px",
          border: `2px solid ${theme.palette.secondary.main}`,
        },
        "&>.MuiFormHelperText-root": {
          textAlign: "center",
          fontSize: "14px",
          fontWeight: "bold",
          [theme.breakpoints.down("sm")]: {
            fontSize: "12px",
          },
          [theme.breakpoints.between("sm", "md")]: {
            fontSize: "13px",
          },
        },
      },
      [theme.breakpoints.between("sm", "md")]: {
        paddingBottom: "2%",
      },
    },
    button: {
      marginTop: "2%",
      background: "#19283F",
      borderRadius: "12px",
      border: "2px solid",
      color: "#FFFFFF",
      fontSize: "17px",
      letterSpacing: "1.5px",
      fontWeight: 500,
      paddingLeft: "15px",
      paddingRight: "15px",
      "&:hover": {
        background: "transparent",
        color: "#19283F",
        borderColor: "#19283F",
      },
    },
    buttonClass: {
      "&>.selected-flag": {
        "&:hover": {
          backgroundColor: "transparent !important",
        },
      },
    },
    error: {
      marginTop: "3px",
      color: "#f44336",
      fontSize: "14px",
      fontStyle: "italic",
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.between("sm", "md")]: {
        fontSize: "13px",
      },
    },
  })
);
export const buttonStyle = {
  background: "transparent",
  outline: "none",
  border: "0px",
};
export const inputStyle = {
  width: "100%",
  outline: "none",
  borderRadius: "12px",
  border: `2px solid #19283F`,
  fontSize: "17px",
  color: "#19283F",
  height: "37px",
};

export const dropdownStyle = {
  width: "200px",
  display: "flex",
  flexDirection: "column" as "column",
  margin: "0",
  justifyContent: "flex-start",
  alignItems: "flex-start",
};
