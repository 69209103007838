import React from "react";
import { useTranslation, Trans } from "react-i18next";

import { Container, Typography, Button } from "@material-ui/core";

import useStyles from "./styles";

export default function NotFoundPage() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.image}>
      <Container maxWidth="md" className={classes.mainContainer}>
        <Typography className={classes.title}>
          <Trans i18nKey="Page not found">
            Page <b>not found</b>
          </Trans>
        </Typography>
        <Typography className={classes.description}>
          {t(
            "Uh oh, we can't seem to find the page you're looking for. Try going back to the previous page or see our HELP CENTER for more information."
          )}
        </Typography>
        <Button className={classes.button} href="/">
          {t("GO BACK HOME")}
        </Button>
      </Container>
    </div>
  );
}
