import React from "react";

import { Box, Stepper, Step, StepLabel } from "@mui/material";
import { StepIconProps } from "@mui/material/StepIcon";

import useStyles, { ColorlibStepIconRoot } from "./styles";

interface StepperListProps {
  activeStep: number;
}

export default function StepperListEntrepreneur({
  activeStep,
}: StepperListProps) {
  const classes = useStyles();
  const steps = ["", ""];

  function ColorlibStepIcon(props: StepIconProps) {
    const { active, completed, className, icon } = props;
    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {icon}
      </ColorlibStepIconRoot>
    );
  }

  return (
    <Box className={classes.mainContainer}>
      <Stepper activeStep={activeStep}>
        {steps.map((label) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel StepIconComponent={ColorlibStepIcon} {...labelProps}>
                {label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
}
