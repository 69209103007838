import React, { useState } from "react";
import * as yup from "yup";
import { useTranslation, Trans } from "react-i18next";
import { useMutation } from "react-query";

import {
  Typography,
  Button,
  FormControl,
  Box,
  Tooltip,
} from "@material-ui/core";
import LoadingButton from "@mui/lab/LoadingButton";
import HelpIcon from "@mui/icons-material/Help";
import { TextField } from "formik-material-ui";
import { Field, Formik } from "formik";

import { serializeStrategyImplementation } from "Utils/Serializers/serializeInvestment";
import {
  StrategyImplementationType,
  BackendStrategyImplementation,
} from "Common/Types/InvestmentType";
import api from "Utils/AxiosConfig";
import useStyles from "Components/FundingRequest/styles";

interface StrategyImplementationProps {
  changeStep: (value: number) => void;
  optionalID: number;
  getProject: StrategyImplementationType;
}

const INITIAL_VALUES = {
  communicateVision: "",
  developmentSkills: "",
  incentives: "",
  resources: "",
  executionPlan: "",
};

export default function StrategyImplementation({
  changeStep,
  optionalID,
  getProject,
}: StrategyImplementationProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = yup.object().shape({
    communicateVision: yup.string(),
    developmentSkills: yup.string(),
    incentives: yup.string(),
    resources: yup.string(),
    executionPlan: yup.string(),
  });

  const mutation = useMutation(
    (data: BackendStrategyImplementation) =>
      api.put(`/api/investments/investments/optionals/${optionalID}/`, data),
    {
      onError(error) {
        setIsLoading(false);
        console.error("Error", error);
      },
      onSuccess: (data) => {
        changeStep(8);
      },
    }
  );

  function Submit(
    communicateVision: string,
    developmentSkills: string,
    incentives: string,
    resources: string,
    executionPlan: string
  ) {
    const body: StrategyImplementationType = {
      communicateVision,
      developmentSkills,
      incentives,
      resources,
      executionPlan,
    };
    setIsLoading(true);
    mutation.mutate(serializeStrategyImplementation(body));
  }

  return (
    <>
      <Box className={classes.titleBox}>
        <Typography className={classes.title}>
          <Trans i18nKey="Strategy Implementation">
            <strong>Strategy Implementation</strong>
          </Trans>
        </Typography>
        <Tooltip
          title={
            <span className={classes.tooltip}>
              {t("This step is not mandatory")}
            </span>
          }
          placement="right"
        >
          <HelpIcon className={classes.helpIcon} />
        </Tooltip>
      </Box>

      <Formik
        initialValues={getProject ? getProject : INITIAL_VALUES}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(false);
          Submit(
            values.communicateVision,
            values.developmentSkills,
            values.incentives,
            values.resources,
            values.executionPlan
          );
          resetForm({ values: INITIAL_VALUES });
        }}
      >
        {({ submitForm, isSubmitting, errors, touched, values }) => (
          <form className={classes.mainForm} autoComplete="off">
            <Box className={classes.secondSubtitle}>
              <Typography variant="h5">{t("Communicate Vision")}</Typography>
              <Tooltip
                title={
                  <span className={classes.tooltip}>
                    <Trans i18nKey="Communicate Vision Description">
                      {t(
                        `Communicate the vision in a way that matters to
                      people. You want people in the organization to believe
                      the vision and to pass it on to others. Leaders need to
                      get the word out about the organization’s vision in
                      multiple ways — and keep the message going.`
                      )}
                    </Trans>
                  </span>
                }
                placement="right"
              >
                <HelpIcon className={classes.helpIcon} />
              </Tooltip>
            </Box>
            <FormControl fullWidth className={classes.formControlTextBox}>
              <Box className={classes.boxInput}>
                <Field
                  className={classes.inputTextBox}
                  placeholder={t("Limit to 1500 characters")}
                  name="communicateVision"
                  component={TextField}
                  InputProps={{ disableUnderline: true }}
                  multiline={true}
                  rows="5"
                  inputProps={{ maxLength: 1500 }}
                />
              </Box>
            </FormControl>
            <Box className={classes.secondSubtitle}>
              <Typography variant="h5">{t("Development Skills")}</Typography>
              <Tooltip
                title={
                  <span className={classes.tooltip}>
                    <Trans i18nKey="Development Skills Description">
                      {t(
                        `At this stage it is very important to define the
                      skill required by the member of the organization to
                      achieve the goals set up in the Strategic Development
                      phase.`
                      )}
                    </Trans>
                  </span>
                }
                placement="right"
              >
                <HelpIcon className={classes.helpIcon} />
              </Tooltip>
            </Box>
            <FormControl fullWidth className={classes.formControlTextBox}>
              <Box className={classes.boxInput}>
                <Field
                  className={classes.inputTextBox}
                  placeholder={t("Limit to 1500 characters")}
                  name="developmentSkills"
                  component={TextField}
                  InputProps={{ disableUnderline: true }}
                  multiline={true}
                  rows="5"
                  inputProps={{ maxLength: 1500 }}
                />
              </Box>
            </FormControl>
            <Box className={classes.secondSubtitle}>
              <Typography variant="h5">{t("Incentives")}</Typography>
              <Tooltip
                title={
                  <span className={classes.tooltip}>
                    <Trans i18nKey="Incentives Description">
                      {t(
                        `The annual incentive plan should be aligned with the
                      Strategic Business Development Plan and ideally reward
                      the performance required to achieve the strategic plan
                      over time.`
                      )}
                    </Trans>
                  </span>
                }
                placement="right"
              >
                <HelpIcon className={classes.helpIcon} />
              </Tooltip>
            </Box>
            <FormControl fullWidth className={classes.formControlTextBox}>
              <Box className={classes.boxInput}>
                <Field
                  className={classes.inputTextBox}
                  placeholder={t("Limit to 1500 characters")}
                  name="incentives"
                  component={TextField}
                  InputProps={{ disableUnderline: true }}
                  multiline={true}
                  rows="5"
                  inputProps={{ maxLength: 1500 }}
                />
              </Box>
            </FormControl>
            <Box className={classes.secondSubtitle}>
              <Typography variant="h5">{t("Resources")}</Typography>
              <Tooltip
                title={
                  <span className={classes.tooltip}>
                    <Trans i18nKey="Resources Description">
                      <p>People : A team that not only understands the strategy you want to implement but also has the skills and bandwidth to support you.</p>
                      <p>Organization: Everyone in your organization needs to know what their responsibilities are so they can be accountable for their part in implementing the strategy.</p>
                      <p>Systems: The tools, capabilities, and systems you’ve put in place are another key component.</p>
                      <p>Culture: Rolling out new strategies can be confusing and stressful for teams. Ensuring that everyone knows what they need to know and feels valued and included.</p>
                    </Trans>
                  </span>
                }
                placement="right"
              >
                <HelpIcon className={classes.helpIcon} />
              </Tooltip>
            </Box>
            <FormControl fullWidth className={classes.formControlTextBox}>
              <Box className={classes.boxInput}>
                <Field
                  className={classes.inputTextBox}
                  placeholder={t("Limit to 1500 characters")}
                  name="resources"
                  component={TextField}
                  InputProps={{ disableUnderline: true }}
                  multiline={true}
                  rows="5"
                  inputProps={{ maxLength: 1500 }}
                />
              </Box>
            </FormControl>
            <Box className={classes.secondSubtitle}>
              <Typography variant="h5">{t("Execution Plan")}</Typography>
              <Tooltip
                title={
                  <span className={classes.tooltip}>
                    <Trans i18nKey="Execution Plan Description">
                      {t(
                        `A project execution plan is a document used to define
                      how you will execute a project. That should be obvious
                      from the name, but it also addresses the project
                      scheduling, monitoring and controlling needed to bring
                      the project deliverables.`
                      )}
                    </Trans>
                  </span>
                }
                placement="right"
              >
                <HelpIcon className={classes.helpIcon} />
              </Tooltip>
            </Box>
            <FormControl fullWidth className={classes.formControlTextBox}>
              <Box className={classes.boxInput}>
                <Field
                  className={classes.inputTextBox}
                  placeholder={t("Limit to 1500 characters")}
                  name="executionPlan"
                  component={TextField}
                  InputProps={{ disableUnderline: true }}
                  multiline={true}
                  rows="5"
                  inputProps={{ maxLength: 1500 }}
                />
              </Box>
            </FormControl>
            <Box>
              <Button onClick={() => changeStep(6)} className={classes.button}>
                {t("BACK")}
              </Button>
              <LoadingButton
                className={!isLoading ? classes.button : classes.none}
                onClick={submitForm}
                loading={isLoading}
                variant="outlined"
              >
                {t("NEXT")}
              </LoadingButton>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
}
