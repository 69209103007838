import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    exitContainer: {
      display: "flex",
      justifyContent: "flex-end",
      marginBottom: "1%",
      paddingTop: "2%",
      paddingRight: "2%",
      [theme.breakpoints.down("sm")]: {
        marginBottom: "4%",
        paddingTop: "4%",
        paddingRight: "4%",
      },
      [theme.breakpoints.between("sm", "md")]: {
        marginBottom: "1%",
        paddingTop: "2%",
        paddingRight: "2%",
      },
    },
    ico: {
      width: "10px",
      height: "10px",
      cursor: "pointer",
    },
    line: {
      marginTop: "1%",
      height: "4px",
      width: "98%",
      backgroundColor: "#000",
      margin: "auto",
    },
    title: {
      fontSize: "22px",
      fontWeight: "bold",
      [theme.breakpoints.down("sm")]: {
        fontSize: "20px",
      },
    },
    description: {
      fontFamily: "Helvetica",
      fontSize: "16px",
      marginTop: "25px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
      },
    },
    container: {
      padding: "10px 20px 40px 20px",
    },
  })
);
