import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: "20px",
      color: theme.palette.secondary.main,
      fontWeight: "bold",
    },
    subtitle: {
      fontSize: "18px",
      color: theme.palette.secondary.main,
      fontWeight: "bold",
    },
    divider: {
      marginBottom: "20px",
      marginTop: "20px",
      backgroundColor: theme.palette.secondary.main,
      height: "2px",
    },
    banner: {
      marginTop: "80px",
      width: "100%",
    },
    categoriesCtn: {
      overflow: "auto",
      height: "200px",
      "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
        backgroundColor: "#dedede",
        width: "4px",
      },
      "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
        backgroundColor: "#1f2f46",
      },
      "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
        backgroundColor: "#192434",
      },
      "& li": {
        padding: 0,
        "& span": {
          fontFamily: "Helvetica",
          fontSize: "15px",
        },
      },
      "& .MuiListItemButton-root": {
        paddingLeft: 0,
      },
    },
    input: {
      border: `1px solid #d2d1d1`,
      background: "#f1f1f1",
      "& input": {
        textAlign: "center",
        "&[type=number]": {
          "-moz-appearance": "textfield",
        },
        "&::-webkit-outer-spin-button": {
          "-webkit-appearance": "none",
          margin: 0,
        },
        "&::-webkit-inner-spin-button": {
          "-webkit-appearance": "none",
          margin: 0,
        },
      },
    },
    inputCtn: {
      display: "flex",
      alignItems: "center",
      "& h5": {
        paddingRight: "2px",
        fontSize: "14px",
      },
    },
    inputDivider: {
      margin: "10px",
    },
  })
);
