import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: "24px",
      marginBottom: "3%",
      [theme.breakpoints.down("md")]: {
        fontSize: "19px",
      },
    },
    description: {
      fontSize: "20px",
      opacity: "0.6",
      marginBottom: "3%",
      [theme.breakpoints.down("md")]: {
        fontSize: "15px",
      },
    },
  })
);
