import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    containerCategories: {
      display: "flex",
      position: "fixed",
      zIndex: 3,
      top: "86px",
      width: "100%",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: "50px",
      backgroundColor: "#192434",
    },
    containerEntrepreneurs: {
      display: "flex",
      position: "fixed",
      zIndex: 3,
      top: "86px",
      left: "43%",
      width: "260px",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: "20px",
      backgroundColor: "#192434",
      [theme.breakpoints.down("lg")]: {
        left: "40%",
        width: "220px",
      },
    },
    containerInvestors: {
      display: "flex",
      position: "fixed",
      zIndex: 3,
      top: "86px",
      left: "56%",
      width: "260px",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: "20px",
      backgroundColor: "#192434",
      [theme.breakpoints.down("lg")]: {
        left: "54%",
        width: "220px",
      },
    },
    gridElements: {
      display: "grid",
      gridTemplateRows: "1fr 1fr 1fr 1fr",
      gridAutoFlow: "column",
      gridGap: "20px",
      width: "80%",
    },
    titleSubMenu: {
      width: "80%",
      color: "#47A6BE",
      marginBottom: "15px",
      fontFamily: "Helvetica",
      fontWeight: "bold",
      fontSize: "15px",
    },
    noData: {
      cursor: "pointer",
      color: "#606060",
    },
    categories: {
      color: "#FFFFFF",
      fontFamily: "Helvetica",
      fontSize: "16px",
    },
    noLink: {
      color: "#FFFFFF",
      fontFamily: "Helvetica",
      fontSize: "16px",
      pointerEvents: "none",
    },
  })
);
