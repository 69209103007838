import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gridColumnGap: "10px",
      alignItems: "center",
    },
    formControl: {
      marginLeft: theme.spacing(1.7),
      [theme.breakpoints.down("xs")]: {
        "& div": {
          fontSize: "10px",
        },
      },
    },
    select: {
      "&:before": {
        borderColor: "transparent",
      },
      "&:after": {
        borderColor: "transparent",
      },

      "& .MuiInputBase-input": {
        color: "#FFFFFF",
        fontWeight: 500,
      },
    },
    selectBlack: {
      "&:before": {
        borderColor: "transparent",
      },
      "&:after": {
        borderColor: "transparent",
      },

      "& .MuiInputBase-input": {
        color: "#FFFFFF",
        fontWeight: 500,
      },
    },
    icon: {
      fill: "#47A6BE",
    },
    logout: {
      padding: 0,
      color: "#FFFFFF",
      marginLeft: "140%",
    },
  })
);
