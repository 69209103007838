import {
  Box, Button,
  FormControl, Link, Tooltip, Typography
} from "@material-ui/core";
import HelpIcon from "@mui/icons-material/Help";
import LoadingButton from "@mui/lab/LoadingButton";
import { ErrorFilesType } from "Common/Types/ErrorResponseType";
import {
  BackendProjectInformationType, ProjectInformationType
} from "Common/Types/InvestmentType";
import useStyles from "Components/FundingRequest/styles";
import { Field, Formik } from "formik";
import { TextField } from "formik-material-ui";
import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import api from "Utils/AxiosConfig";
import { serializeProjectInformation } from "Utils/Serializers/serializeInvestment";
import * as yup from "yup";



interface ProjectInformationProps {
  changeStep: (value: number) => void;
  changeUserID: (value: number) => void;
  getProject: ProjectInformationType;
  newProject: boolean;
  idProject: number;
}

interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}

const INITIAL_VALUES = {
  name: "",
  companyName: "",
  companyWebsite: "",
  mainPicture: "",
  videoUrl: "",
  strategicPlan: "",
  videoType: "",
  videoFile: "",
  strategicPlanUrl: "",
  mainPictureUrl: "",
  videoFileUrl: "",
  pitch: "",
  pitchUrl: "",
  financialForecast: "",
  financialForecastUrl: "",
};

export default function ProjectInformation({
  changeStep,
  changeUserID,
  getProject,
  newProject,
  idProject,
}: ProjectInformationProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [projectPhotoFile, setProjectPhotoFile] = useState("");
  const [projectPdfFile, setProjectPdfFile] = useState("");
  const [projectVideoFile, setProjectVideoFile] = useState("");
  const [projectPhotoName, setProjectPhotoName] = useState("");
  const [projectPdfName, setProjectPdfName] = useState("");
  const [projectVideoName, setProjectVideoName] = useState("");
  const [errorPhotoFile, setErrorPhotoFile] = useState(false);
  const [errorPdfFile, setErrorPdfFile] = useState(false);
  const [errorVideoFile, setErrorVideoFile] = useState(false);
  const [errorName, setErrorName] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [projectPitchPdfFile, setProjectPitchPdfFile] = useState("");
  const [projectPitchName, setProjectPitchName] = useState("");
  const [errorPitchFile, setErrorPitchFile] = useState(false);
  const [financialForecastFile, setFinancialForecastFile] = useState("");
  const [financialForecastName, setFinancialForecastName] = useState("");
  const [errorFinancialForecastFile, setErrorFinancialForecastFile] = useState(false);

  const validationSchema = yup.object().shape({
    name: yup.string().required(t("Project name is required")),
    companyName: yup.string().required(t("Company name is required")),
    companyWebsite: yup
      .string()
      .matches(
        /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/,
        t("Please enter a valid URL")
      )
      .required(t("Company website is required")),
    mainPicture: yup.mixed().when('mainPictureUrl', {
      is: (value: any) => {
        if (value) {
          return true;
        }
        return false;
      },
      then: yup.mixed()
    }),
    videoUrl: yup
      .string()
      .url(t("Please enter a valid Youtube URL"))
      .matches(
        /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/,
        t("Only urls from YouTube allowed")
      ),
    strategicPlan: yup.mixed().when('strategicPlanUrl', {
      is: (value: any) => {
        if (value) {
          return true;
        }
        return false;
      },
      then: yup.string().notRequired(),
    }),
    pitch: yup.mixed().when('pitchUrl', {
      is: (value: any) => {
        if (value) {
          return true;
        }
        return false;
      },
      then: yup.string().notRequired(),
    }),
    financialForecast: yup.mixed().when('financialForecastUrl', {
      is: (value: any) => {
        if (value) {
          return true;
        }
        return false;
      },
      then: yup.string().notRequired(),
    }),
  });

  const mutation = useMutation(
    (data: BackendProjectInformationType) =>
      api.post(`/api/investments/investments/create/`, data),
    {
      onError(error: ErrorFilesType) {
        setIsLoading(false);
        const typeError = error.response.data;
        if (typeError.name) {
          setIsLoading(false);
          setErrorName(true);
        }
        if (typeError.main_picture) {
          setIsLoading(false);
          setErrorPhotoFile(true);
        }
        if (typeError.strategic_plan) {
          setIsLoading(false);
          setErrorPdfFile(true);
        }
        if (typeError.pitch) {
          setIsLoading(false);
          setErrorPitchFile(true);
        }
        if (typeError.financialForecast) {
          setIsLoading(false);
          setErrorFinancialForecastFile(true);
        }
      },
      onSuccess: (data) => {
        changeUserID(data.data.id);
        setTimeout(() => {
          changeStep(1);
        }, 2000);
      },
    }
  );

  const updateMutation = useMutation(
    (data: BackendProjectInformationType) =>
      api.put(
        `/api/investments/investments/create/${idProject}/update_key_information/`,
        data
      ),
    {
      onError(error: ErrorFilesType) {
        setIsLoading(false);
        const typeError = error.response.data;
        if (typeError.name) {
          setIsLoading(false);
          setErrorName(true);
        }
        if (typeError.main_picture) {
          setIsLoading(false);
          setErrorPhotoFile(true);
        }
        if (typeError.strategic_plan) {
          setIsLoading(false);
          setErrorPdfFile(true);
        }
        if (typeError.pitch) {
          setIsLoading(false);
          setErrorPitchFile(true);
        }
        if (typeError.financialForecast) {
          setIsLoading(false);
          setErrorFinancialForecastFile(true);
        }
      },
      onSuccess: (data) => {
        changeUserID(data.data.id);
        changeStep(1);
      },
    }
  );

  function Submit(
    name: string,
    companyName: string,
    companyWebsite: string,
    mainPicture: string,
    videoUrl: string,
    strategicPlan: string,
    videoType: string,
    videoFile: string,
    strategicPlanUrl: string,
    mainPictureUrl: string,
    videoFileUrl: string,
    pitch: string,
    pitchUrl: string,
    financialForecast: string,
    financialForecastUrl: string,
  ) {
    const body: ProjectInformationType = {
      name,
      companyName,
      companyWebsite,
      mainPicture: projectPhotoFile,
      videoUrl,
      strategicPlan: projectPdfFile,
      videoType,
      videoFile: projectVideoFile,
      strategicPlanUrl: "",
      mainPictureUrl: "",
      videoFileUrl: "",
      pitch: projectPitchPdfFile,
      pitchUrl: "",
      financialForecast: financialForecastFile,
      financialForecastUrl: "",
    };
    if (!mainPictureUrl && !mainPicture && !strategicPlan && !strategicPlanUrl
      && !pitchUrl && !pitch && !financialForecastUrl && !financialForecast) {
      return false;
    }
    if (videoType === 'V' && !videoFile && !videoFileUrl) {
      return false;
    }
    if (idProject) {
      setIsLoading(true);
      updateMutation.mutate(serializeProjectInformation(body));
    }
    if (!idProject) {
      setIsLoading(true);
      mutation.mutate(serializeProjectInformation(body));
    }
  }

  const onLoadPhoto = (
    fileString: ArrayBuffer | React.SetStateAction<string> | null,
    value: string
  ) => {
    const photoFile = fileString?.toString();
    if (photoFile) {
      setProjectPhotoFile(photoFile);
    }
    setProjectPhotoName(value);
  };

  const onLoadPdf = (
    fileString: ArrayBuffer | React.SetStateAction<string> | null,
    value: string
  ) => {
    const pdfFile = fileString?.toString();
    if (pdfFile) {
      setProjectPdfFile(pdfFile);
    }
    setProjectPdfName(value);
  };

  const onLoadPitch = (
    fileString: ArrayBuffer | React.SetStateAction<string> | null,
    value: string
  ) => {
    const pdfFile = fileString?.toString();
    if (pdfFile) {
      setProjectPitchPdfFile(pdfFile);
    }
    setProjectPitchName(value);
  };

  const onLoadFinancialForecast = (
    fileString: ArrayBuffer | React.SetStateAction<string> | null,
    value: string
  ) => {
    const pdfFile = fileString?.toString();
    if (pdfFile) {
      setFinancialForecastFile(pdfFile);
    }
    setFinancialForecastName(value);
  };

  const onLoadVideo = (
    fileString: ArrayBuffer | React.SetStateAction<string> | null,
    value: string
  ) => {
    const videoFile = fileString?.toString();
    if (videoFile) {
      setProjectVideoFile(videoFile);
    }
    setProjectVideoName(value);
  };

  const getBase64 = (file: Blob, type: string, value: string) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    if (type === "mainPicture") {
      if (file.type === "image/jpeg" || file.type === "image/png") {
        setErrorPhotoFile(false);
        reader.onload = () => {
          onLoadPhoto(reader.result, value);
        };
      } else {
        setErrorPhotoFile(true);
      }
    } else if (type === "strategicPlan") {
      if (file.type === "application/pdf") {
        setErrorPdfFile(false);
        reader.onload = () => {
          onLoadPdf(reader.result, value);
        };
      } else {
        setErrorPdfFile(true);
      }
    } else if (type === "pitch") {
      if (file.type === "application/pdf") {
        setErrorPitchFile(false);
        reader.onload = () => {
          onLoadPitch(reader.result, value);
        };
      } else {
        setErrorPitchFile(true);
      }
    } else if (type === "financialForecast") {
      if (file.type === "application/vnd.ms-excel"
        || file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        || file.type === "application/vnd.ms-excel.sheet.macroEnabled.12") {
        setErrorFinancialForecastFile(false);
        reader.onload = () => {
          onLoadFinancialForecast(reader.result, value);
        };
      } else {
        setErrorFinancialForecastFile(true);
      }
    } else {
      if (file.type === "video/mp4") {
        setErrorVideoFile(false);
        reader.onload = () => {
          onLoadVideo(reader.result, value);
        };
      } else {
        setErrorVideoFile(true);
      }
    }
  };

  const onChange = (e: HTMLInputEvent) => {
    const files = e?.target.files;
    if (files) {
      const file = files[0];
      getBase64(file, e.target.name, e.target.value);
    }
  };

  return (
    <>
      <Box className={classes.titleBox}>
        <Typography className={classes.title}>
          <Trans i18nKey="Key Project Information">
            <strong>Key Project Information</strong>
          </Trans>
        </Typography>
        <Tooltip
          title={
            <span className={classes.tooltip}>
              {t(
                "All the information in this page will go automatically to the Home page investment thumbnails"
              )}
            </span>
          }
          placement="right"
        >
          <HelpIcon className={classes.helpIcon} />
        </Tooltip>
      </Box>

      <Formik
        initialValues={getProject ? getProject : INITIAL_VALUES}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(false);
          setIsSubmit(true);
          Submit(
            values.name,
            values.companyName,
            values.companyWebsite,
            projectPhotoFile,
            values.videoUrl,
            projectPdfFile,
            values.videoType,
            projectVideoFile,
            values.strategicPlanUrl,
            values.mainPictureUrl,
            values.videoFileUrl,
            projectPitchPdfFile,
            values.pitchUrl,
            financialForecastFile,
            values.financialForecastUrl,
          );
        }}
      >
        {({ submitForm, isSubmitting, errors, touched, values }) => (
          <form className={classes.mainForm} autoComplete="off">

            <FormControl fullWidth className={classes.formControl}>
              <Box className={classes.secondSubtitle}>
                <Typography variant="h5">
                  {t("Project Name")}
                </Typography>
              </Box>
              <Box className={classes.boxInput}>
                <Field
                  className={classes.input}
                  name="name"
                  component={TextField}
                  InputProps={{ disableUnderline: true }}
                />
                {errorName && (
                  <h1 className={classes.error}>
                    {t("An investment with this name already exists")}
                  </h1>
                )}
              </Box>
            </FormControl>

            <FormControl fullWidth className={classes.formControl}>
              <Box className={classes.secondSubtitle}>
                <Typography variant="h5">
                  {t("Company Name")}
                </Typography>
              </Box>
              <Box className={classes.boxInput}>
                <Field
                  className={classes.input}
                  name="companyName"
                  component={TextField}
                  InputProps={{ disableUnderline: true }}
                />
              </Box>
            </FormControl>

            <FormControl fullWidth className={classes.formControl}>
              <Box className={classes.secondSubtitle}>
                <Typography variant="h5">
                  {t("Company Website")}
                </Typography>
              </Box>

              <Box className={classes.boxInput}>
                <Field
                  className={classes.input}
                  name="companyWebsite"
                  component={TextField}
                  InputProps={{ disableUnderline: true }}
                />
              </Box>
            </FormControl>

            <FormControl fullWidth className={classes.formControl}>
              <Box className={classes.secondSubtitleFull}>
                <Typography variant="h5">
                  {t("Photo of the Idea / Invention / Business")}
                </Typography>
              </Box>
              <Box className={classes.boxInput}>
                <label htmlFor="mainPicture" className={classes.fileCtn}>
                  <div className={classes.fileInputCtn}>
                    <Field
                      className={classes.fileInput}
                      name="mainPictureText"
                      component={TextField}
                      InputProps={{ disableUnderline: true }}
                      value={projectPhotoName}
                      disabled={true}
                    />
                    <Field
                      className={classes.file}
                      id="mainPicture"
                      name="mainPicture"
                      type="file"
                      component={TextField}
                      InputProps={{ disableUnderline: true }}
                      onChange={onChange}
                    />
                    {isSubmit && !values.mainPicture && !touched.mainPicture
                      && values.mainPictureUrl === "" ? (
                      <h1 className={classes.error}>
                        {t("Project photo is required")}
                      </h1>
                    ) : errorPhotoFile ? (
                      <h1 className={classes.error}>
                        {t("Please upload a valid image")}
                      </h1>
                    ) : null}
                  </div>
                  <Button
                    variant="contained"
                    component="span"
                    className={classes.fileButton}
                  >
                    {t("Upload")}
                  </Button>
                </label>
              </Box>
            </FormControl>
            {values.mainPictureUrl && (
              <Box className={classes.secondSubtitle}>
                <Typography variant="h6">
                  <Trans i18nKey="Currently">
                    <strong>Currently: </strong>
                  </Trans>
                  <Link target="_blank" className={classes.link} href={values.mainPictureUrl}>
                    {values.mainPictureUrl}
                  </Link>
                </Typography>
              </Box>
            )}
            <Box sx={{ height: 32 }} />

            <FormControl fullWidth className={classes.formControl}>
              <Box className={classes.boxInput}>
                <div role="group" className={classes.radioGroup}>
                  <label>
                    <Field type="radio" name="videoType" value="Y" />
                    Use a video from YouTube
                  </label>
                  <label>
                    <Field type="radio" name="videoType" value="V" />
                    Upload a video from my computer
                  </label>
                </div>
              </Box>
            </FormControl>
            {
              values.videoType === 'Y' ? (
                <FormControl fullWidth className={classes.formControl} >
                  <Box className={classes.secondSubtitle} style={{ width: '100%' }}>
                    <Typography variant="h5">
                      {t("Youtube url video of the Idea / Invention / Business")}
                    </Typography>
                  </Box>
                  <Box className={classes.boxInput}>
                    <Field
                      className={classes.input}
                      name="videoUrl"
                      component={TextField}
                      InputProps={{ disableUnderline: true }}
                    />
                  </Box>
                </FormControl>
              ) : (
                <>
                  <FormControl fullWidth className={classes.formControl}>
                    <Box className={classes.secondSubtitleFull}>
                      <Typography variant="h5">
                        {t("Video of the Idea /Invention /Business (MP4)")}
                      </Typography>
                    </Box>
                    <Box className={classes.boxInput}>
                      <label htmlFor="videoFile" className={classes.fileCtn}>
                        <div className={classes.fileInputCtn}>
                          <Field
                            className={classes.fileInput}
                            name="videoFile"
                            component={TextField}
                            InputProps={{ disableUnderline: true }}
                            value={projectVideoName}
                            disabled={true}
                          />
                          <Field
                            className={classes.file}
                            id="videoFile"
                            name="videoFile"
                            type="file"
                            component={TextField}
                            InputProps={{ disableUnderline: true }}
                            onChange={onChange}
                          />
                          {errorVideoFile ? (
                            <h1 className={classes.error}>
                              {t("Please upload a valid video file")}
                            </h1>
                          ) : null}
                        </div>
                        <Button
                          variant="contained"
                          component="span"
                          className={classes.fileButton}
                        >
                          {t("Upload")}
                        </Button>
                      </label>
                    </Box>
                  </FormControl>
                  {values.videoFileUrl && (
                    <Box className={classes.secondSubtitle}>
                      <Typography variant="h6">
                        <Trans i18nKey="Currently">
                          <strong>Currently: </strong>
                        </Trans>
                        <Link target="_blank" className={classes.link} href={values.videoFileUrl}>
                          {values.videoFileUrl}
                        </Link>
                      </Typography>
                    </Box>
                  )}
                </>
              )
            }
            <br></br>
            <Box className={classes.secondSubtitle}>
              <Typography variant="h5">
                {t("Strategic Business Plan (PDF)")}
              </Typography>
            </Box>
            <FormControl fullWidth className={classes.formControl}>
              <Box className={classes.boxInput}>
                <label htmlFor="strategicPlan" className={classes.fileCtn}>
                  <div className={classes.fileInputCtn}>
                    <Field
                      className={classes.fileInput}
                      name="strategicPlan"
                      component={TextField}
                      InputProps={{ disableUnderline: true }}
                      value={projectPdfName}
                      disabled={true}
                    />
                    <Field
                      className={classes.file}
                      id="strategicPlan"
                      name="strategicPlan"
                      type="file"
                      component={TextField}
                      InputProps={{ disableUnderline: true }}
                      onChange={onChange}
                    />
                    {isSubmit && !values.strategicPlan && !touched.strategicPlan
                      && values.strategicPlanUrl === "" ? (
                      <h1 className={classes.error}>
                        {t("Project PDF is required")}
                      </h1>
                    ) : errorPdfFile ? (
                      <h1 className={classes.error}>
                        {t("Please upload a valid PDF file")}
                      </h1>
                    ) : null}
                  </div>
                  <Button
                    variant="contained"
                    component="span"
                    className={classes.fileButton}
                  >
                    {t("Upload")}
                  </Button>
                </label>
              </Box>
            </FormControl>
            {values.strategicPlanUrl && (
              <Box className={classes.secondSubtitle}>
                <Typography variant="h6">
                  <Trans i18nKey="Currently">
                    <strong>Currently: </strong>
                  </Trans>
                  <Link target="_blank" className={classes.link} href={values.strategicPlanUrl}>
                    {values.strategicPlanUrl}
                  </Link>
                </Typography>
              </Box>
            )}
            <br/>

            <Box className={classes.secondSubtitle}>
              <Typography variant="h5">
                {t("Pitch for Investors (PDF)")}
              </Typography>
            </Box>
            <FormControl fullWidth className={classes.formControl}>
              <Box className={classes.boxInput}>
                <label htmlFor="pitch" className={classes.fileCtn}>
                  <div className={classes.fileInputCtn}>
                    <Field
                      className={classes.fileInput}
                      name="pitch"
                      component={TextField}
                      InputProps={{ disableUnderline: true }}
                      value={projectPitchName}
                      disabled={true}
                    />
                    <Field
                      className={classes.file}
                      id="pitch"
                      name="pitch"
                      type="file"
                      component={TextField}
                      InputProps={{ disableUnderline: true }}
                      onChange={onChange}
                    />
                    {isSubmit && !values.pitch && !touched.pitch
                      && values.pitchUrl === "" ? (
                      <h1 className={classes.error}>
                        {t("Pitch for Investors is required")}
                      </h1>
                    ) : errorPitchFile ? (
                      <h1 className={classes.error}>
                        {t("Please upload a valid PDF file")}
                      </h1>
                    ) : null}
                  </div>
                  <Button
                    variant="contained"
                    component="span"
                    className={classes.fileButton}
                  >
                    {t("Upload")}
                  </Button>
                </label>
              </Box>
            </FormControl>
            {values.pitchUrl && (
              <Box className={classes.secondSubtitle}>
                <Typography variant="h6">
                  <Trans i18nKey="Currently">
                    <strong>Currently: </strong>
                  </Trans>
                  <Link target="_blank" className={classes.link} href={values.pitchUrl}>
                    {values.pitchUrl}
                  </Link>
                </Typography>
              </Box>
            )}
            <br/>

            <Box className={classes.secondSubtitle}>
              <Typography variant="h5">
                {t("Financial Forecast (XLSX, XLS or XLSM)")}
              </Typography>
            </Box>
            <FormControl fullWidth className={classes.formControl}>
              <Box className={classes.boxInput}>
                <label htmlFor="financialForecast" className={classes.fileCtn}>
                  <div className={classes.fileInputCtn}>
                    <Field
                      className={classes.fileInput}
                      name="financialForecast"
                      component={TextField}
                      InputProps={{ disableUnderline: true }}
                      value={financialForecastName}
                      disabled={true}
                    />
                    <Field
                      className={classes.file}
                      id="financialForecast"
                      name="financialForecast"
                      type="file"
                      component={TextField}
                      InputProps={{ disableUnderline: true }}
                      onChange={onChange}
                    />
                    {isSubmit && !values.financialForecast
                      && !touched.financialForecast
                      && values.financialForecastUrl === "" ? (
                      <h1 className={classes.error}>
                        {t("Financial Forecast is required")}
                      </h1>
                    ) : errorFinancialForecastFile ? (
                      <h1 className={classes.error}>
                        {t("Please upload a valid xlsx, xls or xlsm file")}
                      </h1>
                    ) : null}
                  </div>
                  <Button
                    variant="contained"
                    component="span"
                    className={classes.fileButton}
                  >
                    {t("Upload")}
                  </Button>
                </label>
              </Box>
            </FormControl>
            {values.financialForecastUrl && (
              <Box className={classes.secondSubtitle}>
                <Typography variant="h6">
                  <Trans i18nKey="Currently">
                    <strong>Currently: </strong>
                  </Trans>
                  <Link target="_blank" className={classes.link} href={values.financialForecastUrl}>
                    {values.financialForecastUrl}
                  </Link>
                </Typography>
              </Box>
            )}
            <br/>

            <LoadingButton
              className={!isLoading ? classes.button : classes.none}
              onClick={submitForm}
              loading={isLoading}
              variant="outlined"
            >
              {t("NEXT")}
            </LoadingButton>
          </form>
        )}
      </Formik>
    </>
  );
}
