import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      marginTop: "5%",
      marginBottom: "5%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        paddingTop: "36%",
        paddingBottom: "26%",
      },
    },
    newFounding: {
      width: "100%",
      border: "2px solid #47A1B9",
      color: "#47A1B9",
      fontSize: "16px",
      textTransform: "none",
      letterSpacing: "1.0px",
    },
    accordion: {
      width: "100%",
      marginTop: "3rem",
      boxShadow: "none",
      letterSpacing: "0.18px",
    },
    accordionDetails: {
      padding: "0px",
    },
    arrow: {
      color: "white",
    },
    accordionSummary: {
      backgroundColor: "#47A1B9",
      color: "white",
      minHeight: "45px !important",
      height: "45px",
    },
    tableHeader: {
      color: "#47A1B9",
      fontSize: "15px",
      borderColor: "#47A1B9",
      backgroundColor: "#FAFAFA",
    },
    tableBody: {
      fontSize: "15px",
      borderColor: "#47A1B9",
      backgroundColor: "#FAFAFA",
      "& :hover": {
        backgroundColor: "#FAFAFA",
      },
    },
    buttonText: {
      color: "#5B5B5B !important",
    },
    banner: {
      marginTop: "80px",
      width: "100%",
      height: "240px",
      objectFit: "cover",
      objectPosition: "top",
    },
    mainCtn: {
      marginTop: "5%",
      marginBottom: "5%",
      width: "55%",
      [theme.breakpoints.down("sm")]: {
        marginTop: "28%",
        marginBottom: "30%",
      },
      [theme.breakpoints.between("sm", "md")]: {
        marginTop: "5%",
        marginBottom: "12%",
      },
    },
    title: {
      fontSize: "24px",
      marginBottom: "5%",
      [theme.breakpoints.down("sm")]: {
        fontSize: "19px",
        marginBottom: "8%",
      },
      [theme.breakpoints.between("sm", "md")]: {
        fontSize: "19px",
        marginBottom: "4%",
      },
    },
    description: {
      fontSize: "20px",
      opacity: "0.6",
      marginBottom: "1%",
      [theme.breakpoints.down("md")]: {
        fontSize: "15px",
      },
    },
    button: {
      marginTop: "4%",
      background: "#19283F",
      borderRadius: "12px",
      border: "2px solid",
      color: "#FFFFFF",
      fontSize: "17px",
      letterSpacing: "1.5px",
      fontWeight: 500,
      paddingLeft: "15px",
      paddingRight: "15px",
      "&:hover": {
        background: "transparent",
        color: "#19283F",
        borderColor: "#19283F",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "12px",
        marginTop: "7%",
      },
      [theme.breakpoints.between("sm", "md")]: {
        fontSize: "12px",
        marginTop: "5%",
      },
    },
    successCtn: {
      marginTop: "5%",
      marginBottom: "5%",
      width: "80%",
      [theme.breakpoints.down("sm")]: {
        marginTop: "28%",
        marginBottom: "30%",
      },
      [theme.breakpoints.between("sm", "md")]: {
        marginTop: "5%",
        marginBottom: "12%",
      },
    },
  })
);
