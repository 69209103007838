import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary, {
  AccordionSummaryProps
} from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      marginTop: "120px",
      marginBottom: "5%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        paddingTop: "36%",
        paddingBottom: "26%",
      },
    },
    image: {
      width: "100%",
      height: "100%",
    },
    video: {
      width: "100% !important",
      height: "100% !important",
    },
    descriptionCtn: {
      background: "#f1f1f1",
    },
    breadcrumb: {
      display: "flex",
      justifyContent: "flex-start",
      width: "100%",
      marginBottom: "2%",
      "& a": {
        fontSize: "16px",
      },
      "& h5": {
        fontSize: "16px",
      },
    },
    title: {
      display: "flex",
      justifyContent: "flex-start",
      marginBottom: "20px",
      width: "100%",
      "& h1": {
        fontSize: "24px",
      },
    },
    titleBox: {
      display: "flex",
      justifyContent: "center",
      padding: "20px",
      "& h1": {
        width: "100%",
        background: theme.palette.secondary.main,
        color: "#FFFFFF",
        fontSize: "18px",
        padding: "4px",
      },
    },
    detailBox: {
      display: "flex",
      alignItems: "flex-start",
      padding: "10px 20px 10px 20px",
      flexDirection: "column",
      "& h2": {
        color: theme.palette.secondary.main,
        fontSize: "15px",
        fontWeight: "bold",
        paddingBottom: "8px",
        fontFamily: "Helvetica",
        textAlign: "left",
      },
      "& h4": {
        fontSize: "14px",
        paddingBottom: "12px",
        fontFamily: "Helvetica",
      },
    },
    buttonBox: {
      marginTop: "4%",
      marginBottom: "4%",
      border: `2px solid ${theme.palette.secondary.main}`,
      borderRadius: "12px",
      "& button": {
        textTransform: "none",
        fontSize: "18px",
        width: "100%",
        padding: 0,
        backgroundColor: theme.palette.secondary.main,
        color: "#FFFFFF",
        "&:hover": {
          color: theme.palette.secondary.main,
          backgroundColor: "#FFFFFF",
          borderRadius: "12px",
        },
      },
    },
    buttonBoxSecond: {
      marginBottom: "4%",
      border: `2px solid ${theme.palette.secondary.main}`,
      borderRadius: "12px",
      "& button": {
        textTransform: "none",
        fontSize: "18px",
        width: "100%",
        padding: 0,
        backgroundColor: theme.palette.secondary.main,
        color: "#FFFFFF",
        "&:hover": {
          color: theme.palette.secondary.main,
          backgroundColor: "#FFFFFF",
          borderRadius: "12px",
        },
      },
    },
    buttonMail: {
      marginBottom: "4%",
      border: `2px solid ${theme.palette.secondary.main}`,
      borderRadius: "12px",
      backgroundColor: theme.palette.secondary.main,
      "& a": {
        textTransform: "none",
        fontSize: "18px",
        width: "100%",
        padding: 0,
        color: "#FFFFFF",
        "&:hover": {
          color: theme.palette.secondary.main,
          backgroundColor: "#FFFFFF",
          borderRadius: "12px",
        },
      }
    },
    summaryCtn: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
      alignItems: "flex-start",
      "& h1": {
        fontSize: "18px",
        color: theme.palette.secondary.main,
        paddingBottom: "2%",
      },
      "& h5": {
        fontSize: "16px",
        textAlign: "left",
        fontFamily: "Helvitica",
        paddingBottom: "10px",
      },
    },
    summaryParseCtn: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
      alignItems: "flex-start",
      fontFamily: "Helvitica",
      marginBottom: "20px",
      "& h1": {
        fontSize: "18px",
        color: theme.palette.secondary.main,
        fontWeight: "bold",
      },
      "& h2": {
        fontSize: "18px",
        color: theme.palette.secondary.main,
      },
      "& p": {
        textAlign: "left",
        fontSize: "16px",
        margin: 0,
      },
      "& li": {
        textAlign: "left",
        fontSize: "16px",
      },
    },
    divider: {
      height: "20px",
    },
  })
);

export const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

export const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "#19283F",
  color: "#FFFFFF",
  flexDirection: "row",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
  "& svg": {
    color: "#FFFFFF",
  },
  "& p": {
    fontSize: "18px",
  },
}));

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
  textAlign: "left",
  "& p": {
    fontFamily: "Helvitica",
    fontSize: "16px",
  },
}));
