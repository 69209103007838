import {
  Box, Button, Container,
  Dialog, FormControl, FormHelperText, IconButton, InputAdornment, Typography
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { BackendUserRegister, UserRegister } from "Common/Types/RegisterUser";
import FacebookAuth from "Components/FacebookAuth";
import GoogleAuth from "Components/GoogleAuth";
import IntellectualEntrepreneurModal from "Components/IntellectualProperty/Modal/Entrepreneur";
import TermsEntrepreneurModal from "Components/TermsConditions/Modal/Entrepreneur";
import { Field, Formik } from "formik";
import { CheckboxWithLabel, TextField } from "formik-material-ui";
import i18n from "i18next";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import api from "Utils/AxiosConfig";
import { serializeRegisterUse } from "Utils/Serializers/serializeRegisterUse";
import * as yup from "yup";
import useStyles from "./styles";



interface PersonalProps {
  changeStep: (value: number) => void;
  changeUserID: (value: number) => void;
}
let INITIAL_VALUES = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  terms: false,
};

export default function PersonalInformationEntrepreneur({
  changeStep,
  changeUserID,
}: PersonalProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [newSletter, setNewSletter] = useState({ signMeUp: true });
  
  const language = i18n.language.toLowerCase();
  const [isOpenModalTerms, setIsOpenModalTerms] = useState(false);
  const [isOpenModalPrivacy, setIsOpenModalPrivacy] = useState(false);
  const history = useHistory();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const validationSchema = yup.object().shape({
    firstName: yup.string().required(t("First name is required")),
    lastName: yup.string().required(t("Last name is required")),
    email: yup
      .string()
      .email(t("Enter a valid email"))
      .required(t("Email is required")),
    password: yup
      .string()
      .min(8, t("Password should be of minimum 8 characters length"))
      .required(t("Password is required")),
    terms: yup
      .boolean()
      .oneOf(
        [true],
        t("You must accept the terms of use and intellectual property terms")
      ),
  });

  const mutation = useMutation(
    (data: BackendUserRegister) =>
      api.post(`api/users/personal_information/?language=${language}`, data),
    {
      onError(error) {
        setEmailError(true);
        console.error(error);
      },
      onSuccess: (data) => {
        changeUserID(data.data.id);
        changeStep(1);
        history.push("/registerActivation");
      },
    }
  );
  const { signMeUp } = newSletter;

  const addNewSletter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewSletter({
      ...newSletter,
      [event.target.name]: event.target.checked,
    });
  };

  function Submit(
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    isSubscribed: boolean,
    userType: string
  ) {
    const body: UserRegister = {
      firstName,
      lastName,
      email,
      password,
      isSubscribed: signMeUp,
      userType,
    };
    mutation.mutate(serializeRegisterUse(body));
  }
  
  

  return (
    <>
      <Typography className={classes.title}>
        <Trans i18nKey="Personal Information">
          Personal <strong>Information</strong>
        </Trans>
      </Typography>
      <Container className={classes.socialContainer}>
        <Box className={classes.boxInput}>
          <GoogleAuth isInvestor={false} isSignUp={true}/>
        </Box>
        <Box className={classes.boxInput}>
        <FacebookAuth isInvestor={false} isSignUp={true}/>
        </Box>
      </Container>  
      
      <Formik
        initialValues={INITIAL_VALUES}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(false);
          Submit(
            values.firstName,
            values.lastName,
            values.email,
            values.password,
            signMeUp,
            "E"
          );
          resetForm({ values: INITIAL_VALUES });
        }}
      >
        {({ submitForm, isSubmitting, errors, touched, values }) => (
          <form className={classes.mainForm} autoComplete="off">
            <FormControl fullWidth className={classes.formControl}>
              <Box className={classes.secondSubtitle}>
                <Typography variant="h5">
                    {t("First Name")}
                </Typography>
              </Box>
              <Box className={classes.boxInput}>
                <Field
                  className={classes.input}
                  name="firstName"
                  component={TextField}
                  InputProps={{ disableUnderline: true }}
                />
              </Box>
            </FormControl>

            <FormControl fullWidth className={classes.formControl}>
            <Box className={classes.secondSubtitle}>
                <Typography variant="h5">
                    {t("Last Name")}
                </Typography>
              </Box>
              <Box className={classes.boxInput}>
                <Field
                  className={classes.input}
                  name="lastName"
                  component={TextField}
                  InputProps={{ disableUnderline: true }}
                />
              </Box>
            </FormControl>
            <FormControl fullWidth className={classes.formControl}>
              <Box className={classes.secondSubtitle}>
                <Typography variant="h5">
                    {t("E-mail")}
                </Typography>
              </Box>
              <Box className={classes.boxInput}>
                <Field
                  className={classes.input}
                  name="email"
                  component={TextField}
                  InputProps={{ disableUnderline: true }}
                  error={(touched.email && values.email === "") || emailError}
                  helperText={
                    emailError
                      ? t(
                          "Email has already been taken. Please choose another one or log in."
                        )
                      : ""
                  }
                />
              </Box>
            </FormControl>
            <FormControl fullWidth className={classes.formControl}>
            <Box className={classes.secondSubtitle}>
                <Typography variant="h5">
                    {t("Password")}
                </Typography>
              </Box>
              <Box className={classes.boxInput}>
                <Field
                  className={classes.input}
                  type={showPassword ? "text" : "password"}
                  name="password"
                  component={TextField}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                    disableUnderline: true,
                  }}
                />
              </Box>
            </FormControl>
            <Box className={classes.formGroup}>
              <Field
                component={CheckboxWithLabel}
                type="checkbox"
                name="signMeUp"
                checked={signMeUp}
                indeterminate={false}
                onChange={addNewSletter}
                Label={{
                  label: t("Sign me up for the Wintellinvest Newsletter"),
                }}
              />
              <Field
                component={CheckboxWithLabel}
                type="checkbox"
                name="terms"
                Label={{
                  label: (
                    <label>
                      <span>{t("I agree to the ")}</span>
                      <Button
                        className={classes.buttonTerms}
                        onClick={() => setIsOpenModalTerms(true)}
                      >
                        {t("Terms & Conditions")}
                      </Button>
                      <span>{t(" and ")}</span>
                      <Button
                        className={classes.buttonTerms}
                        onClick={() => setIsOpenModalPrivacy(true)}
                      >
                        {t("Intellectual Property")}
                      </Button>
                    </label>
                  ),
                }}
              />
              {errors.terms && touched.terms && (
                <FormHelperText className={classes.helpText}>
                  {errors.terms}
                </FormHelperText>
              )}
            </Box>
            <Button
              className={classes.button}
              onClick={submitForm}
              disabled={isSubmitting}
            >
              {t("NEXT")}
            </Button>
          </form>
        )}
      </Formik>
      
      {isOpenModalTerms && (
        <Dialog
          open={isOpenModalTerms}
          onClose={() => setIsOpenModalTerms(false)}
          maxWidth={"xl"}
          scroll={"body"}
        >
          <TermsEntrepreneurModal onClose={() => setIsOpenModalTerms(false)} />
        </Dialog>
      )}

      {isOpenModalPrivacy && (
        <Dialog
          open={isOpenModalPrivacy}
          onClose={() => setIsOpenModalPrivacy(false)}
          maxWidth={"xl"}
          scroll={"body"}
        >
          <IntellectualEntrepreneurModal
            onClose={() => setIsOpenModalPrivacy(false)}
          />
        </Dialog>
      )}
    </>
  );
}
