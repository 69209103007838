import { CssBaseline } from "@material-ui/core";
import UserProvider from "Context/UserSesion/UserContext";
import Footer from "Components/Footer";
import Header from "Components/Header";
import Routes from "Routes";
import setYupLocale from "./Utils/Yup";
import "./App.css";

function App() {
  setYupLocale();
  return (
    <>
      <CssBaseline />
      <UserProvider>
        <div className="App">
          <Header />
          <Routes />
          <Footer />
        </div>
      </UserProvider>
    </>
  );
}

export default App;
