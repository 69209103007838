import React, { useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useMutation } from "react-query";

import { Typography, Button } from "@material-ui/core";

import api from "Utils/AxiosConfig";
import ActivationEmail from "Components/ActivationAccount/ActivationEmail";
import useStyles from "./styles";

export default function ActivationResend(props: { email: string }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [sendSuccess, setSendSuccess] = useState(false);
  const { email } = props;

  const mutation = useMutation(
    (data: { email: string }) =>
      api.post("/api/users/resend-activation/", data),
    {
      onError: (error) => {
        console.error("Error: ", error);
        setSendSuccess(false);
      },
      onSuccess: () => {
        setSendSuccess(true);
      },
    }
  );

  const handleSendButton = () => {
    const body = {
      email,
    };
    mutation.mutate(body);
  };

  return (
    <>
      {!sendSuccess ? (
        <>
          <Typography className={classes.title}>
            <Trans i18nKey="Your account needs email confirmation">
              Your account needs <b>email confirmation</b>
            </Trans>
          </Typography>
          <Typography className={classes.description}>
            {t("Please check your email inbox or resend the confirmation")}
          </Typography>
          <Button className={classes.button} onClick={handleSendButton}>
            {t("RESEND VERIFICATION E-MAIL")}
          </Button>
        </>
      ) : (
        <ActivationEmail />
      )}
    </>
  );
}
