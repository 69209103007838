import { BackendCountry, Country } from "Common/Types/Country";

export const serializeCountry = (countries: BackendCountry[]): Country[] => {
  const listCountries = countries && countries.map ? countries.map((item) => {
    return {
      code: item.code,
      id: item.id,
      name: item.name,
      nameEn: item.name_en,
      nameEs: item.name_es,
      phoneCode: item.phone_code,
    };
  }) : [];
  return listCountries;
};
