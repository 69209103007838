import Carousel from "@brainhubeu/react-carousel";
import {
  Card,
  CardContent,
  CardMedia,
  Container, Grid,
  Typography
} from "@material-ui/core";
import userReviews from "Assets/images/userReviews.png";
import { UserReviewType } from "Common/Types/UserReviewType";
import i18n from "i18next";
import React, { Children, useState } from "react";
import { Trans } from "react-i18next";
import { Img } from "react-image";
import ReactLoading from "react-loading";
import { useQuery } from "react-query";
import api from "Utils/AxiosConfig";
import { serializeUserReviewToContext } from "Utils/Serializers/serializeUserReview";
import useStyles from "./styles";



function Review({
  text,
  textEs,
  user,
  avatarPictureThumbnail,
}: UserReviewType) {
  const classes = useStyles();

  return (
    <Card className={classes.cardCont}>
      <CardMedia
        className={classes.imageComment}
        image={avatarPictureThumbnail}
      />
      <div className={classes.cardDetails}>
        <CardContent className={classes.content}>
          <Typography className={classes.comment}>
            {i18n.language === "EN" ? text : textEs}
          </Typography>
          <Typography className={classes.author}>{user}</Typography>
        </CardContent>
      </div>
    </Card>
  );
}

export default function UsersReviews() {
  const classes = useStyles();
  const [reviewsData, setReviewsData] = useState<UserReviewType[]>([]);
  const [value, setValue] = useState(0);

  const onChange = (value: number) => {
    setValue(value);
  };

  const { isLoading } = useQuery(
    "reviewsFetch",
    async () => api.get("api/generics/reviews/"),
    {
      onSuccess: (res) => {
        const data = res.data;
        setReviewsData(serializeUserReviewToContext(data));
      },
      onError: (error) => {
        console.error("Error:", error);
      },
    }
  );

  const firstSlide = reviewsData.slice(0, 3);
  const secondSlide = reviewsData.slice(3, 6);
  const thirdSlide = reviewsData.slice(6, 9);

  const renderFirstSlide = (
    <Container className={classes.carouselDetails}>
      {firstSlide &&
        Children.toArray(
          firstSlide.map((review) => (
            <Review
              text={`"${review?.text}"`}
              textEs={`"${review?.textEs}"`}
              user={review.user}
              avatarPictureThumbnail={review.avatarPictureThumbnail}
            />
          ))
        )}
    </Container>
  );

  const renderSecondSlide = (
    <Container className={classes.carouselDetails}>
      {secondSlide &&
        Children.toArray(
          secondSlide.map((review) => (
            <Review
              text={`"${review?.text}"`}
              textEs={`"${review?.textEs}"`}
              user={review.user}
              avatarPictureThumbnail={review.avatarPictureThumbnail}
            />
          ))
        )}
    </Container>
  );

  const renderThirdSlide = (
    <Container className={classes.carouselDetails}>
      {thirdSlide &&
        Children.toArray(
          thirdSlide.map((review) => (
            <Review
              text={`"${review?.text}"`}
              textEs={`"${review?.textEs}"`}
              user={review.user}
              avatarPictureThumbnail={review.avatarPictureThumbnail}
            />
          ))
        )}
    </Container>
  );

  let renderSlides : any[] = [];
  if (firstSlide.length > 0){
    renderSlides.push(renderFirstSlide);
  }
  if (secondSlide.length > 0){
    renderSlides.push(renderSecondSlide);
  }
  
  if (thirdSlide.length > 0){
    renderSlides.push(renderThirdSlide);
  }

  setTimeout(() => {
    if (value < renderSlides.length) {
      setValue((prev) => prev + 1);
    } else {
      setValue(0);
    }
  }, 7000);
  

  return (
    <Grid className={classes.mainContainer}>
      <Container maxWidth="md" className={classes.container}>
        <Grid item xs={12} md={6} className={classes.mainText}>
          <Typography className={classes.title}>
            <Trans i18nKey="Thousands of investors around the world">
              Thousands of investors <b>around the world</b>
            </Trans>
          </Typography>
          {!isLoading ? (
            <Carousel
              value={value}
              onChange={onChange}
              slides={renderSlides}
              draggable={false}
              className={classes.carousel}
            />
          ) : (
            <ReactLoading
              type="spinningBubbles"
              color="#19283F"
              height={"15%"}
              width={"15%"}
              className={classes.errorLoading}
            />
          )}
        </Grid>
        <Img src={userReviews} className={classes.image} />
      </Container>
    </Grid>
  );
}
