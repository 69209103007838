import { Container, Grid } from "@material-ui/core";
import useStyles from "Components/TermsConditions/styles";
import i18n from "i18next";
import { useState } from "react";
import { useQuery } from "react-query";
import api from "Utils/AxiosConfig";

export default function TermsEntrepreneur() {
  const classes = useStyles();
  const [contentEs, setContentEs] = useState("");
  const [contentEn, setContentEn] = useState("");
  

  useQuery(
    "getEntrepreneurBlogs",
    () => api.get("api/blogs/blogs/terms-conditions-enterprenurs/"),
    {
      onSuccess: (res) => {
        setContentEn(res.data["content"]);
        setContentEs(res.data["content_es"]);
      },
    }
  );

  return (
    <Container className={classes.container}>
      <Grid container className={classes.subContainer}>
          <div
        dangerouslySetInnerHTML={{
          __html: i18n.language === "ES" ? contentEs : contentEn,
        }}
      />
      </Grid>
    </Container>
  );
}
