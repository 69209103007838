import { SummaryType, BackendSummary } from "Common/Types/SummaryType";

export const serializeSummaryToContext = (
  summary: BackendSummary
): SummaryType => {
  const serializedSummary: SummaryType = {
    numInvestments: summary.num_investments,
    totalProjects: summary.total_projects,
    amountRaised: summary.amount_raised,
  };
  return serializedSummary;
};
