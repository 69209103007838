import React from "react";
import { useTranslation } from "react-i18next";

import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

import useStyles from "./styles";
import { numberToString } from "Utils/Functions";
import { InvestmentType } from "Common/Types/InvestmentType";

export default function Investment({
  id,
  name,
  shortDescription,
  amount,
  sharesPercentage,
  marketValue,
  potentialValue,
  estimatedReturnMonths,
  mainPictureThumbnail,
}: InvestmentType) {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const { t } = useTranslation();

  return (
    <Card className={classes.cardCtn}>
      <CardActionArea href={`/investment/${id}`}>
        <Grid container direction={matches ? "row" : "column-reverse"}>
          <Grid item md={12} xs={7} className={classes.contentCtn}>
            <CardContent className={classes.title}>
              <Typography className={classes.titleCard}>{name}</Typography>
            </CardContent>
            <CardContent className={classes.description}>
              <Typography className={classes.descriptionCard}>
                {shortDescription}
              </Typography>
            </CardContent>
            <CardContent className={classes.investCard}>
              <Typography>{t("Investment Required")}</Typography>
            </CardContent>
            <CardContent className={classes.investment}>
              <Grid container direction="row" className={classes.numCtn}>
                <Grid item>
                  <Typography className={classes.amount}>
                    USD <br /> <b>{amount.toLocaleString()}</b>
                  </Typography>
                </Grid>
                <Divider className={classes.divider} />
                <Grid item>
                  <Typography className={classes.amount}>
                    {t("For the")}: <br />
                    <b>{sharesPercentage}%</b>
                    <br /> {t("of the shares")}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
            <CardContent className={classes.marketCard}>
              <Typography>
                {t("Initial Market Value")}: USD. {numberToString(marketValue)}{" "}
                ({t("estimated")})
              </Typography>
            </CardContent>
            <CardContent className={classes.potencialCard}>
              <Typography>
                {t("Potencial Market Value (Year 5)")}: USD.{" "}
                {numberToString(potentialValue)}
              </Typography>
            </CardContent>
          </Grid>
          <Grid item md={12} xs={5} className={classes.contentCtn}>
            <CardMedia
              component="img"
              image={mainPictureThumbnail}
              className={classes.image}
            />
          </Grid>
        </Grid>
      </CardActionArea>
    </Card>
  );
}
