import React from "react";
import { Img } from "react-image";

import { Container, Hidden } from "@material-ui/core";

import banner from "Assets/images/bannerPasswordReset.png";
import ActivationRegister from "Components/ActivationAccount/ActivationRegister";
import useStyles from "./styles";

export default function RegisterAccountPage() {
  const classes = useStyles();

  return (
    <>
      <Hidden only={["sm", "xs"]}>
        <Img src={banner} className={classes.banner} />
      </Hidden>
      <Container maxWidth="md" className={classes.mainContainer}>
        <ActivationRegister />
      </Container>
    </>
  );
}
