import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";

import { Dialog, Grid, Button } from "@material-ui/core";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

import LogIn from "Components/LogIn";
import SignUp from "Components/SignUpDialog";
import CustomButton from "Components/CustomButton";
import { userContext } from "Context/UserSesion/UserContext";
import useStyles from "./styles";

export default function ButtonsMobile({ header }: { header: boolean }) {
  const [isOpenModalLogIn, setIsOpenModalLogIn] = useState(false);
  const [isOpenModalSignUp, setIsOpenModalSignUp] = useState(false);
  const { user: isAuth, setUser } = useContext(userContext);
  const { t } = useTranslation();
  const props = {
    color: header ? "#000" : "#FFF",
  };

  const classes = useStyles(props);

  const logout = () => {
    setUser({
      token: "",
    });
  };

  return (
    <Grid className={classes.container}>
      {!isAuth.token ? (
        <>
          <CustomButton
            label={t("LOG IN")}
            header={header}
            outlined
            mobileMode
            handleOpenModal={() => setIsOpenModalLogIn(true)}
          />
          <CustomButton
            label={t("SIGN UP")}
            mobileMode
            header={false}
            outlined={false}
            handleOpenModal={() => setIsOpenModalSignUp(true)}
          />
        </>
      ) : (
        <Button className={classes.logout} onClick={logout} endIcon={<ExitToAppIcon/>} href="/">
          Logout
        </Button>
      )}
      {isOpenModalLogIn && (
        <Dialog
          open={isOpenModalLogIn}
          onClose={() => setIsOpenModalLogIn(false)}
          maxWidth={"xl"}
          scroll={"body"}
        >
          <LogIn onClose={() => setIsOpenModalLogIn(false)} />
        </Dialog>
      )}
      {isOpenModalSignUp && (
        <Dialog
          open={isOpenModalSignUp}
          onClose={() => setIsOpenModalSignUp(false)}
          maxWidth={"xl"}
          scroll={"body"}
        >
          <SignUp onClose={() => setIsOpenModalSignUp(false)} />
        </Dialog>
      )}
    </Grid>
  );
}
