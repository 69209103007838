import React, { useState } from "react";
import { Img } from "react-image";
import { useTranslation } from "react-i18next";

import {
  Container,
  Hidden,
  Typography,
  Box,
  Grid,
  Button,
} from "@material-ui/core";

import TermsEntrepreneur from "Components/TermsConditions/Entrepreneur";
import TermsInvestor from "Components/TermsConditions/Investor";
import bannerPasswordReset from "Assets/images/bannerPasswordReset.png";
import useStyles from "./styles";

export default function TermsConditionsPage() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [entrepreneurView, setEntrepreneurView] = useState(true);
  const [investorView, setInvestorView] = useState(false);

  const swapTerms = (type: number) => {
    if (type === 1) {
      setEntrepreneurView(true);
      setInvestorView(false);
    }
    if (type === 2) {
      setEntrepreneurView(false);
      setInvestorView(true);
    }
  };

  return (
    <>
      <Hidden only={["sm", "xs"]}>
        <Img src={bannerPasswordReset} className={classes.banner} />
      </Hidden>
      <Container maxWidth="md" className={classes.mainContainer}>
        <Typography className={classes.title}>
          {t("Terms & Conditions")}
        </Typography>
        <Grid container className={classes.buttonMainContainer}>
          <Grid item md={6}>
            <Button
              onClick={() => swapTerms(1)}
              className={
                entrepreneurView ? classes.buttonActive : classes.buttonDisable
              }
            >
              {t("Entrepreneurs")}
            </Button>
          </Grid>
          <Grid item md={6}>
            <Button
              onClick={() => swapTerms(2)}
              className={
                investorView ? classes.buttonActive : classes.buttonDisable
              }
            >
              {t("Investors")}
            </Button>
          </Grid>
        </Grid>
        <Box className={classes.box}>
          {entrepreneurView ? <TermsEntrepreneur /> : <TermsInvestor />}
        </Box>
      </Container>
    </>
  );
}
