import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  root: {
    color: "#FFFFFF",
    backgroundColor: "#47A6BE",
    height: "25px",
    padding: "10px",
    fontWeight: "bold",
    borderRadius: "7px",
    opacity: "0.9",
    minWidth: "100px",
    border: "2px solid #47A6BE",
    fontFamily: "Helvetica",
  },
  outlined: {
    color: "#FFFFFF",
    backgroundColor: "transparent",
    border: "2px solid #47A6BE",
    minWidth: "100px",
    height: "25px",
    fontWeight: "bold",
    opacity: "0.8",
    borderRadius: "7px",
    fontFamily: "Helvetica",
  },
  rootMobile: {
    color: "#FFFFFF",
    backgroundColor: "#47A6BE",
    height: "20px",
    padding: "7px",
    borderRadius: "7px",
    opacity: "0.9",
    border: "1px solid #47A6BE",
    fontFamily: "Helvetica",
    fontWeight: "bold",
    fontSize: "10px",
  },
  outlinedMobile: {
    color: "#FFFFFF",
    backgroundColor: "transparent",
    border: "1px solid #47A6BE",
    height: "20px",
    opacity: "0.9",
    borderRadius: "7px",
    fontFamily: "Helvetica",
    fontWeight: "bold",
    fontSize: "10px",
  },
});
