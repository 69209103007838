import React, { useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useQuery } from "react-query";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import ProjectInformation from "Components/FundingRequest/ProjectInformation";
import FinancialInformation from "Components/FundingRequest/FinancialInformation";
import Industries from "Components/FundingRequest/Industries";
import BusinessPlan from "Components/FundingRequest/BusinessPlan";
import LogIn from "Components/LogIn";
import MarketOpportunities from "Components/FundingRequest/MarketOpportunities";
import StrategyDevelopment from "Components/FundingRequest/StrategyDevelopment";
import StrategyInformation from "Components/FundingRequest/StrategyInformation";
import StrategyImplementation from "Components/FundingRequest/StrategyImplementation";
import StepperList from "Components/FundingRequest/StepperList";
import api from "Utils/AxiosConfig";
import {
  COMPLETED_GREEN,
  REJECTED_RED,
  REVIEW_BLUE,
  NEW_VIOLET,
  Rows,
  ProjectInfo,
  FinancialInfo,
  IndustriesInfo,
  BusinessInfo,
  MarketInfo,
  Strategy,
  StrategyInfo,
  StrategyImple,
} from "Views/FundingRequest/constants";
import {
  serializeProjectInformationFrontend,
  serializeFinancialtInformationFrontend,
  serializeIndustriesFrontend,
  serializeBussinessPlanFrontend,
  serializeMarketOpportunityFrontend,
  serializeStrategyInformationFrontend,
  serializeStrategyImplementationFrontend,
} from "Utils/Serializers/serializeInvestment";

import useStyles from "./styles";

function FundingRequestSuccess() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Container className={classes.successCtn}>
      <Typography className={classes.title}>
        <Trans i18nKey="CONGRATULATIONS.">
          CONGRATULATIONS!
          <b> the registration of the funding request has been completed</b>
        </Trans>
      </Typography>
      <Typography className={classes.description}>
        <Trans i18nKey="Your funding request will be evaluated by the administrators.">
          Your funding request will be evaluated by the administrators. Once
          accepted you will be able to see it in the list of new investment
          opportunities in homepage.
        </Trans>
      </Typography>
      <Typography className={classes.description}>
        <Trans i18nKey="If it has been rejected, you will review the comments of the administrator to your email.">
          If it has been rejected, you will review the comments of the
          administrator to your email. You will be able to update the investment
          with the observations and resubmit it.
        </Trans>
      </Typography>
      <Button className={classes.button} href="/">
        {t("GO BACK HOME")}
      </Button>
    </Container>
  );
}

function AuthEntrepreneur() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isOpenModalLogIn, setIsOpenModalLogIn] = useState(false);

  return (
    <Container className={classes.mainCtn}>
      <Typography className={classes.title}>
        <Trans i18nKey="OH NO! you must login as an entrepreneur to create a funding request">
          OH NO!
          <b> you must login as an entrepreneur to create a funding request</b>
        </Trans>
      </Typography>
      <Typography className={classes.description}>
        {t("Please click LOGIN and enter your credentials to continue.")}
      </Typography>
      <Button
        className={classes.button}
        onClick={() => setIsOpenModalLogIn(true)}
      >
        {t("LOG IN")}
      </Button>
      {isOpenModalLogIn && (
        <Dialog
          open={isOpenModalLogIn}
          onClose={() => setIsOpenModalLogIn(false)}
          maxWidth={"xl"}
          scroll={"body"}
        >
          <LogIn onClose={() => setIsOpenModalLogIn(false)} />
        </Dialog>
      )}
    </Container>
  );
}

export default function FundingRequestPage() {
  const classes = useStyles();
  const [isEntreprenur, setIsEntreprenur] = useState(false);
  const [activeStep, setActiveStep] = useState(-1);
  const [userID, setUserID] = useState(0);
  const [optionalID, setOptionalID] = useState(0);
  const [newFounding, setNewFounding] = useState(false);
  const [newProject, setNewProject] = useState(false);
  const [rows, setRows] = useState([Rows]);
  const [id, setId] = useState(0);
  const [investmentOptionalId, setInvestmentOptionalId] = useState(0);
  const [projectInfo, setProjectInfo] = useState(ProjectInfo);
  const [financialInfo, setFinancialInfo] = useState(FinancialInfo);
  const [industries, setIndustries] = useState(IndustriesInfo);
  const [businessInfo, setBusinessInfo] = useState(BusinessInfo);
  const [marketInfo, setMarketInfo] = useState(MarketInfo);
  const [strategyDev, setStrategyDev] = useState(Strategy);
  const [strategyInfo, setStrategyInfo] = useState(StrategyInfo);
  const [strategyImple, setStrategyImple] = useState(StrategyImple);

  const { t } = useTranslation();

  useQuery(
    "getProyects",
    async () => api.get("api/investments/investments/my/"),
    {
      onSuccess: (res) => {
        if (res.data !== null) {
          setRows(res.data);
        }
      },
      onError: (error) => {
        console.error("Error", error);
      },
    }
  );

  const { refetch } = useQuery(
    "getProyectById",
    async () => await api.get("api/investments/investments/my/" + userID),
    {
      onSuccess: (res) => {
        if (res.data !== null) {
          setProjectInfo(serializeProjectInformationFrontend(res.data));
          setFinancialInfo(serializeFinancialtInformationFrontend(res.data));
          setBusinessInfo(serializeBussinessPlanFrontend(res.data));
          setMarketInfo(serializeMarketOpportunityFrontend(res.data));
          setIndustries(serializeIndustriesFrontend(res.data));
          setInvestmentOptionalId(res.data["investmentoptionals"]["id"]);
          setOptionalID(res.data["investmentoptionals"]["id"]);
          setStrategyDev((prevState) => ({
            ...prevState,
            financialforecast_set: res.data["financialforecast_set"],
          }));
          setStrategyInfo(
            serializeStrategyInformationFrontend(
              res.data["investmentoptionals"]
            )
          );
          setStrategyImple(
            serializeStrategyImplementationFrontend(
              res.data["investmentoptionals"]
            )
          );
        }
        if (activeStep === -1) {
          setActiveStep(
            res.data["status"] === 0
              ? res.data["status"]
              : res.data["status"] - 1
          );
        }
        setNewFounding(true);
      },
      onError: (error) => {
        console.error("Error", error);
      },
      enabled: !!userID,
    }
  );

  useQuery("statusUser", async () => api.get("/api/users/profile/"), {
    onSuccess: (res) => {
      if (res.data.investor === null) {
        setIsEntreprenur(true);
      } else {
        setIsEntreprenur(false);
      }
    },
    onError: (error) => {
      console.error("Error", error);
    },
  });

  function changeStep(step: number) {
    refetch();
    setActiveStep(step);
  }

  return (
    <Container maxWidth="md" className={classes.mainContainer}>
      {!isEntreprenur ? (
        <AuthEntrepreneur />
      ) : newFounding ? (
        <Container maxWidth="md" className={classes.mainContainer}>
          {activeStep !== 8 && <StepperList activeStep={activeStep} />}
          {(() => {
            switch (activeStep) {
              case 0:
                return (
                  <ProjectInformation
                    changeStep={changeStep}
                    changeUserID={setUserID}
                    getProject={projectInfo}
                    newProject={newProject}
                    idProject={userID}
                  />
                );
              case 1:
                return (
                  <FinancialInformation
                    changeStep={changeStep}
                    userID={userID}
                    financialInfo={financialInfo}
                  />
                );
              case 2:
                return (
                  <Industries
                    changeStep={changeStep}
                    userID={userID}
                    industriesInfo={industries}
                  />
                );
              case 3:
                return (
                  <BusinessPlan
                    changeStep={changeStep}
                    userID={userID}
                    businessInfo={businessInfo}
                  />
                );
              case 4:
                return (
                  <MarketOpportunities
                    changeStep={changeStep}
                    userID={userID}
                    marketInfo={marketInfo}
                  />
                );
              case 5:
                return (
                  <StrategyDevelopment
                    changeStep={changeStep}
                    userID={userID}
                    strategyDev={strategyDev}
                  />
                );
              case 6:
                return (
                  <StrategyInformation
                    changeStep={changeStep}
                    userID={userID}
                    changeOptionalID={setOptionalID}
                    strategyInfo={strategyInfo}
                    newProject={newProject}
                    idProject={investmentOptionalId}
                  />
                );
              case 7:
                return (
                  <StrategyImplementation
                    changeStep={changeStep}
                    optionalID={optionalID}
                    getProject={strategyImple}
                  />
                );
              case 8:
                return <FundingRequestSuccess />;
              default:
                break;
            }
          })()}
        </Container>
      ) : (
        <Container maxWidth="md" className={classes.mainContainer}>
          <Button
            className={classes.newFounding}
            onClick={() => {
              setActiveStep(0);
              setNewProject(true);
              setNewFounding(true);
            }}
          >
            <b>{t("Create new funding request")} +</b>
          </Button>

          <Accordion className={classes.accordion}>
            <AccordionSummary
              expandIcon={
                <ArrowDropDownIcon fontSize="large" className={classes.arrow} />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
              className={classes.accordionSummary}
            >
              <Typography>{t("FUNDING REQUESTS")}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <TableContainer>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableHeader}>
                        {t("NAME")}
                      </TableCell>
                      <TableCell className={classes.tableHeader}>
                        {t("STATUS")}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow key={row.name}>
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.tableBody}
                        >
                          <Button
                            disableFocusRipple={true}
                            onClick={() => {
                              setId(row.id);
                              setUserID(row.id);
                              setNewProject(false);
                              if (row.id === id) {
                                refetch();
                              }
                            }}
                            disabled={
                              row.state === "COMPLETED" ||
                              row.state === "REVIEW"
                                ? true
                                : false
                            }
                            className={classes.buttonText}
                          >
                            {row.name}
                          </Button>
                        </TableCell>
                        <TableCell
                          className={classes.tableBody}
                          style={{
                            color:
                              row.state === "COMPLETED"
                                ? COMPLETED_GREEN
                                : row.state === "REJECTED"
                                ? REJECTED_RED
                                : row.state === "REVIEW"
                                ? REVIEW_BLUE
                                : NEW_VIOLET,
                          }}
                        >
                          {row.state === "COMPLETED" && t("COMPLETED")}
                          {row.state === "REJECTED" && t("REJECTED")}
                          {row.state === "NEW" && t("NEW")}
                          {row.state === "REVIEW" && t("REVIEW")}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        </Container>
      )}
    </Container>
  );
}
