import { useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useMutation } from "react-query";
import NumberFormat from "react-number-format";

import { FormHelperText, Typography, Button, Grid } from "@material-ui/core";
import LoadingButton from "@mui/lab/LoadingButton";
import { TextField } from "formik-material-ui";
import { Field, Formik, FieldArray, Form } from "formik";

import api from "Utils/AxiosConfig";
import { StrategyDevelopmentType } from "Common/Types/InvestmentType";
import useStyles from "Components/FundingRequest/styles";

interface StrategyDevelopmentProps {
  changeStep: (value: number) => void;
  userID: number;
  strategyDev: StrategyDevelopmentType;
}

const INITIAL_VALUES = {
  financialforecast_set: [
    {
      revenue: null!,
      operating_expenses: null!,
      income_before_taxes: null!,
      percentage_ibt_revenue: null!,
      year: 1,
    },
    {
      revenue: null!,
      operating_expenses: null!,
      income_before_taxes: null!,
      percentage_ibt_revenue: null!,
      year: 2,
    },
    {
      revenue: null!,
      operating_expenses: null!,
      income_before_taxes: null!,
      percentage_ibt_revenue: null!,
      year: 3,
    },
    {
      revenue: null!,
      operating_expenses: null!,
      income_before_taxes: null!,
      percentage_ibt_revenue: null!,
      year: 4,
    },
    {
      revenue: null!,
      operating_expenses: null!,
      income_before_taxes: null!,
      percentage_ibt_revenue: null!,
      year: 5,
    },
    {
      revenue: null!,
      operating_expenses: null!,
      income_before_taxes: null!,
      percentage_ibt_revenue: null!,
      year: 6,
    },
  ],
};

export default function StrategyDevelopment({
  changeStep,
  userID,
  strategyDev,
}: StrategyDevelopmentProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const MAX_VAL = 100;
  const withValueCap = (inputObj: any) => {
    const { value } = inputObj;
    if (value <= MAX_VAL) return true;
    return false;
  };
  const [isLoading, setIsLoading] = useState(false);

  const mutation = useMutation(
    (data: StrategyDevelopmentType) =>
      api.put(
        `/api/investments/investments/create/${userID}/financial_forecast/`,
        data
      ),
    {
      onSuccess: () => {
        changeStep(6);
      },
      onError: () => {
        setIsLoading(false);
      },
    }
  );

  const parserValue = (val: any) => {
    if (val == null) {
      return null!;
    }
    return parseFloat(val.toString().replaceAll(",", ""));
  };

  const submit = (values: StrategyDevelopmentType) => {
    for (let index = 0; index < values.financialforecast_set.length; index++) {
      values.financialforecast_set[index].revenue = parserValue(values.financialforecast_set[index].revenue)
      values.financialforecast_set[index].operating_expenses = parserValue(values.financialforecast_set[index].operating_expenses)
      values.financialforecast_set[index].income_before_taxes = parserValue(values.financialforecast_set[index].income_before_taxes)
      values.financialforecast_set[index].percentage_ibt_revenue = parserValue(values.financialforecast_set[index].percentage_ibt_revenue)
    }
    setIsLoading(true);
    mutation.mutate(values);
  };

  return (
    <>
      <Typography className={classes.title}>
        <Trans i18nKey="Strategic Business Plan / Financial Forecast">
          <strong>Strategic Business Plan / Financial Forecast</strong>
        </Trans>
      </Typography>
      <Formik
        initialValues={
          strategyDev && strategyDev.financialforecast_set.length !== 0
            ? strategyDev
            : INITIAL_VALUES
        }
        onSubmit={(values, { setSubmitting, resetForm }) => {
          submit(values);
          setSubmitting(false);
        }}
        render={({ values }) => (
          <Form>
            <Grid container className={classes.tableCtn}>
              <Grid item className={classes.tableHead}>
                <Typography variant="h2">{t("Financial Forecast")} </Typography>
                <Typography variant="h5">
                  {t("Year – 1")} <br />
                  {t("USD")}
                </Typography>
                <Typography variant="h5">
                  {t("Year – 2")} <br /> USD
                </Typography>
                <Typography variant="h5">
                  {t("Year – 3")} <br /> USD
                </Typography>
                <Typography variant="h5">
                  {t("Year – 4")} <br /> USD
                </Typography>
                <Typography variant="h5">
                  {t("Year – 5")} <br /> USD
                </Typography>
                <Typography variant="h5">
                  {t("Total 5 Y. –")} <br /> USD
                </Typography>
              </Grid>
              <Grid container direction="row" className={classes.tableBody}>
                <Grid item md={3} className={classes.tableItems}>
                  <Typography variant="h5">{t("Revenue")}</Typography>
                  <Typography variant="h5">
                    {t("Operating")} <br /> {t("Expenses")}
                  </Typography>
                  <Typography variant="h5">
                    {t("Income")} <br /> {t("Before Taxes")}
                  </Typography>
                  <Typography variant="h5">
                    {t("Percentage of")} <br /> {t("IBT / Revenue")}
                  </Typography>
                </Grid>
                <Grid item md={9}>
                  <FieldArray
                    name="friends"
                    render={(arrayHelpers) => (
                      <div className={classes.tableInputs}>
                        {values.financialforecast_set.map((friend, index) => (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <Field
                              className={classes.inputTable}
                              name={`financialforecast_set.${index}.revenue`}
                              component={TextField}
                              InputProps={{
                                disableUnderline: true,
                                inputComponent: NumberFormat,
                                inputProps: {
                                  thousandsGroupStyle: "thousand",
                                  decimalSeparator: ".",
                                  displayType: "input",
                                  type: "text",
                                  thousandSeparator: true,
                                  allowNegative: true,
                                },
                              }}
                            />
                            <Field
                              className={classes.inputTable}
                              name={`financialforecast_set.${index}.operating_expenses`}
                              component={TextField}
                              InputProps={{
                                disableUnderline: true,
                                inputComponent: NumberFormat,
                                inputProps: {
                                  thousandsGroupStyle: "thousand",
                                  decimalSeparator: ".",
                                  displayType: "input",
                                  type: "text",
                                  thousandSeparator: true,
                                  allowNegative: false,
                                },
                              }}
                            />
                            <Field
                              className={classes.inputTable}
                              name={`financialforecast_set.${index}.income_before_taxes`}
                              component={TextField}
                              InputProps={{
                                disableUnderline: true,
                                inputComponent: NumberFormat,
                                inputProps: {
                                  thousandsGroupStyle: "thousand",
                                  decimalSeparator: ".",
                                  displayType: "input",
                                  type: "text",
                                  thousandSeparator: true,
                                  allowNegative: true,
                                },
                              }}
                            />
                            <Field
                              className={classes.inputTable}
                              name={`financialforecast_set.${index}.percentage_ibt_revenue`}
                              component={TextField}
                              InputProps={{
                                disableUnderline: true,
                                inputComponent: NumberFormat,
                                inputProps: {
                                  thousandsGroupStyle: "thousand",
                                  decimalSeparator: ".",
                                  displayType: "input",
                                  type: "text",
                                  thousandSeparator: true,
                                  allowNegative: false,
                                  isAllowed: withValueCap,
                                },
                              }}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Button onClick={() => changeStep(4)} className={classes.button}>
              {t("BACK")}
            </Button>
            <LoadingButton
              className={!isLoading ? classes.button : classes.none}
              type="submit"
              loading={isLoading}
              variant="outlined"
            >
              {t("NEXT")}
            </LoadingButton>
          </Form>
        )}
      />
    </>
  );
}
