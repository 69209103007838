// Import Material Ui / Formik Related
import { Grid, Typography } from "@material-ui/core";

// Import locals
import useStyles from "./styles";

export interface IntelliFundProps {
  header: boolean;
  size: "h3" | "h4" | "h5" | "h6";
}

export default function IntelliFund({ header, size }: IntelliFundProps) {
  const classes = useStyles();
  return (
    <Grid className={classes.intellifund}>
      <Typography variant={size} className={classes.textWhite}>
        Wintell
      </Typography>
      <Typography variant={size} color={"primary"}>
        Invest
      </Typography>
    </Grid>
  );
}
