import {
  Box, Breadcrumbs, Button, Container, Dialog, Divider, Grid, Link, Typography
} from "@material-ui/core";
import { InvestmentDetailType } from "Common/Types/InvestmentType";
import StrategicPlanModal from "Components/StrategicPlan";
import api from "Utils/AxiosConfig";
import { serializeInvestmentDetailToContext } from "Utils/Serializers/serializeInvestment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Img } from "react-image";
import ReactLoading from "react-loading";
import ReactPlayer from "react-player";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import useStyles, {
  Accordion, AccordionDetails, AccordionSummary
} from "./styles";




interface InvestmentBody {
  id: string;
}

export default function InvestmentDetailPage() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { id } = useParams<InvestmentBody>();
  const [investment, setInvestment] = useState<InvestmentDetailType>();
  const [expanded, setExpanded] = useState<string | false>("panel1");
  const [isOpenModalPdf, setIsOpenModalPdf] = useState(false);
  const [isInvestor, setIsInvestor] = useState(false);
  const [pdfLink, setPdfLink] = useState("");
  const [pitchLink, setPitchLink] = useState("");
  const [financialForecastLink, setFinancialForecastLink] = useState("");

  const { isLoading } = useQuery(
    "investmentGet",
    async () => api.get(`/api/investments/investments/detail_view/${id}`),
    {
      onSuccess: (res) => {
        const data = res.data;
        setInvestment(serializeInvestmentDetailToContext(data));
      },
      onError: (error) => {
        console.error("Error:", error);
      },
    }
  );

  useQuery(
    "investorAuth",
    async () =>
      api.get(`/api/investments/investments/detail_view/${id}/strategic_plan`),
    {
      onSuccess: (res) => {
        setIsInvestor(true);
        setPdfLink(res.data.strategic_plan);
      },
      onError: (error) => {
        console.error("Error:", error);
      },
    }
  );

  useQuery(
    "pitch",
    async () =>
      api.get(`/api/investments/investments/detail_view/${id}/pitch`),
    {
      onSuccess: (res) => {
        setIsInvestor(true);
        setPitchLink(res.data.pitch);
      },
      onError: (error) => {
        console.error("Error:", error);
      },
    }
  );

  useQuery(
    "financialForecast",
    async () =>
      api.get(`/api/investments/investments/detail_view/${id}/financial_forecast`),
    {
      onSuccess: (res) => {
        setIsInvestor(true);
        setFinancialForecastLink(res.data.financial_forecast);
      },
      onError: (error) => {
        console.error("Error:", error);
      },
    }
  );

  const pdfValidator = () => {
    if (isInvestor) {
      window.open(pdfLink, "_blank");
    } else {
      setIsOpenModalPdf(true);
    }
  };

  const pitchValidator = () => {
    if (isInvestor) {
      window.open(pitchLink, "_blank");
    } else {
      setIsOpenModalPdf(true);
    }
  };

  const financialForecastLinkValidator = () => {
    if (isInvestor) {
      window.open(financialForecastLink, "_blank");
    } else {
      setIsOpenModalPdf(true);
    }
  };

  const handleChange =
    (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const HtmlToReactParser = require("html-to-react").Parser;
  const htmlToReactParser = new HtmlToReactParser();
  const reactElement = htmlToReactParser.parse(investment?.description);

  const maill = `mailto:cmarchani@wintelltech.com, tsmith@wintelltech.com?subject=I am interested in Investing in the project ${investment?.name}`;
  return (
    <>
      <Container maxWidth="md" className={classes.mainContainer}>
        {!isLoading ? (
          <>
            <Box className={classes.breadcrumb}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href="/">
                  {t("Home Page")}
                </Link>
                <Link underline="hover" color="inherit" href="/search">
                  {t("Search Page")}
                </Link>
                <Typography variant="h5">{investment?.name}</Typography>
              </Breadcrumbs>
            </Box>
            <Box className={classes.title}>
              <Typography variant="h1">{investment?.name}</Typography>
            </Box>
            <Grid container>
              <Grid item md={6}>
                {investment?.videoUrl !== "" ? (
                  <ReactPlayer
                    url={investment?.videoUrl}
                    className={classes.video}
                    controls={true}
                  />
                ) : (
                  <Img
                    src={investment?.mainPicture}
                    className={classes.image}
                  />
                )}
              </Grid>
              <Grid item md={6} className={classes.descriptionCtn}>
                <Box className={classes.titleBox}>
                  <Typography variant="h1">
                    {t("Key Financial and Market Indicators")}
                  </Typography>
                </Box>
                <Box className={classes.detailBox}>
                  <Typography variant="h2">
                    {t("Investment Required (USD)")}
                  </Typography>
                  <Typography variant="h4">
                    {investment?.amount.toLocaleString()}
                  </Typography>
                  <Typography variant="h2">
                    {t("Percentage of shares offered for the investment")}
                  </Typography>
                  <Typography variant="h4">
                    {investment?.sharesPercentage}%
                  </Typography>
                  <Typography variant="h2">
                    {t("Estimated Initial Valuation of the project in year 1 (USD)")}
                  </Typography>
                  <Typography variant="h4">
                    {investment?.marketValue.toLocaleString()}
                  </Typography>
                  <Typography variant="h2">
                    {t("Estimated Total Market in Year 1 (USD)")}
                  </Typography>
                  <Typography variant="h4">
                    {investment?.potentialValue.toLocaleString()}
                  </Typography>
                  <Typography variant="h2">
                    {t("Projected Valuation of the project in year 5 (USD)")}
                  </Typography>
                  <Typography variant="h4">
                    {investment?.yearlyRevenue.toLocaleString()}
                  </Typography>
                  <Typography variant="h2">
                    {t("Projected Total Market in Year 5 (USD)")}
                  </Typography>
                  <Typography variant="h4">
                    {investment?.yearlyRevenueFifth.toLocaleString()}
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={12} className={classes.buttonBox}>
                <Button onClick={pdfValidator}>
                  {t("Download the Strategic Business Plan")}
                </Button>
              </Grid>
              <Grid item md={12} className={classes.buttonBoxSecond}>
                <Button onClick={pitchValidator}>
                  {t("Download the Pitch for Investors")}
                </Button>
              </Grid>
              <Grid item md={12} className={classes.buttonBoxSecond}>
                <Button onClick={financialForecastLinkValidator}>
                  {t("Download the Financial Forecast")}
                </Button>
              </Grid>
              <Grid item md={12} className={classes.buttonMail}>
                <Button
                  href={maill} target="_blank">
                  {t("If you interesting in Investing in this project please click here")}
                </Button>
              </Grid>
              <Grid item md={12} className={classes.summaryCtn}>
                <Box className={classes.summaryParseCtn}>
                  <Typography variant="h1">
                    {t("EXECUTIVE SUMMARY - PROJECT DESCRIPTION")}
                  </Typography>
                  <p>
                    {reactElement}
                  </p>
                </Box>
              </Grid>
              <Grid item md={12}>
                <Accordion
                  expanded={expanded === "panel1"}
                  onChange={handleChange("panel1")}
                >
                  <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                  >
                    <Typography>{t("MARKET OPPORTUNITIES")}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box className={classes.summaryCtn}>
                      <Typography variant="h1">
                        {t("INTERNAL APPRAISAL")}
                      </Typography>
                      <Typography variant="h5">
                        <p> {investment?.internalAppraisal}</p>
                      </Typography>
                      <Divider className={classes.divider} />
                      <Typography variant="h1">
                        {t("EXTERNAL APPRAISAL")}
                      </Typography>
                      <Typography variant="h5">
                        <p>{investment?.externalAppraisal}</p>
                      </Typography>
                    </Box>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === "panel2"}
                  onChange={handleChange("panel2")}
                >
                  <AccordionSummary
                    aria-controls="panel2d-content"
                    id="panel2d-header"
                  >
                    <Typography>{t("SWOT ANALYSIS")}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box className={classes.summaryCtn}>
                      <Typography variant="h1">{t("STRENGTHS")}</Typography>
                      <Typography variant="h5">
                        <p>{investment?.strengths}</p>
                      </Typography>
                      <Divider className={classes.divider} />
                      <Typography variant="h1">{t("WEAKNESSES")}</Typography>
                      <Typography variant="h5">
                        <p>{investment?.weakness}</p>
                      </Typography>
                      <Divider className={classes.divider} />
                      <Typography variant="h1">{t("OPPORTUNITIES")}</Typography>
                      <Typography variant="h5">
                        <p>{investment?.opportunities}</p>
                      </Typography>
                      <Divider className={classes.divider} />
                      <Typography variant="h1">{t("THREATS")}</Typography>
                      <Typography variant="h5">
                        <p>{investment?.threats}</p>
                      </Typography>
                    </Box>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === "panel3"}
                  onChange={handleChange("panel3")}
                >
                  <AccordionSummary
                    aria-controls="panel3d-content"
                    id="panel3d-header"
                  >
                    <Typography>{t("MARKET NEEDS AND GAPS")}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box className={classes.summaryCtn}>
                      <Typography variant="h5">
                        <p>{investment?.marketNeeds}</p>
                      </Typography>
                    </Box>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === "panel4"}
                  onChange={handleChange("panel4")}
                >
                  <AccordionSummary
                    aria-controls="panel4d-content"
                    id="panel4d-header"
                  >
                    <Typography>
                      {t("SUSTAINABLE COMPETITIVE ADVANTAGE")}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box className={classes.summaryCtn}>
                      <Typography variant="h5">
                        <p>{investment?.competitiveAdvantages}</p>
                      </Typography>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
          </>
        ) : (
          <ReactLoading
            type="spinningBubbles"
            color="#19283F"
            height={"8%"}
            width={"8%"}
          />
        )}
      </Container>
      {isOpenModalPdf && (
        <Dialog
          open={isOpenModalPdf}
          onClose={() => setIsOpenModalPdf(false)}
          maxWidth={"xl"}
          scroll={"body"}
        >
          <StrategicPlanModal onClose={() => setIsOpenModalPdf(false)} />
        </Dialog>
      )}
    </>
  );
}
