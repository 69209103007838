import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    mainCtn: {
      marginTop: "40px",
      marginBottom: "40px",
      padding: "0 !important",
      "& h2": {
        fontSize: "42px",
        textAlign: "left",
        marginBottom: "2%",
      },
      "& b": {
        fontWeight: "bold",
      },
      [theme.breakpoints.down("sm")]: {
        marginBottom: "20px",
        "& h2": {
          fontSize: "20px",
          textAlign: "center",
          marginBottom: "2%",
        },
      },
    },
    tableCtn: {
      boxShadow: "none",
      backgroundColor: "transparent",
      "& table": {
        minWidth: 650,
        [theme.breakpoints.down("sm")]: {
          width: "280% !important",
        },
      },
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "2%",
      },
    },
    tableCell: {
      padding: "8px",
      borderBottom: "none",
      [theme.breakpoints.up("sm")]: {
        paddingLeft: 0,
      },
      width: "33.33333%",
    },
    button: {
      marginTop: "1%",
      background: theme.palette.info.contrastText,
      borderRadius: "13px",
      padding: "0 !important",
      alignContent: "center",
      "& button": {
        width: "100%",
        "&:hover": {
          background: "#3f83a6",
          borderRadius: "13px",
        },
      },
      [theme.breakpoints.down("sm")]: {
        width: "80%",
        "& span": {
          fontSize: "15px",
          fontWeight: "bold",
          letterSpacing: "2px",
        },
      },
    },
    linkSeeProject: {
      color: "white",
      fontSize: "21px",
      fontWeight: "bold",
      letterSpacing: "2px",
      textDecoration: "none",
    },
  })
);
