import { useTranslation } from "react-i18next";
import { Img } from "react-image";

import { Grid, Typography, Box, Container } from "@material-ui/core";

import buttonExit from "Assets/images/x.png";
import useStyles from "./styles";

export interface ModalProps {
  onClose: () => void;
}

export default function StrategicPlanModal({ onClose }: ModalProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Container className={classes.container}>
      <Grid container className={classes.exitContainer}>
        <Img
          src={buttonExit}
          alt=""
          className={classes.ico}
          onClick={onClose}
        />
      </Grid>
      <Typography align="center" className={classes.title}>
        {t("WARNING")}
      </Typography>

      <Box className={classes.line} />
      <Typography align="center" className={classes.description}>
        {t(
          "To download the strategic business plan it is necessary to be authenticated as an investor."
        )}
      </Typography>
    </Container>
  );
}
