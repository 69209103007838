import {
  Box, Button,
  FormControl, FormHelperText, Typography
} from "@material-ui/core";
import { categorySelect } from "Common/Mocks/mockOption";
import { BackendUserRegisterIndustry } from "Common/Types/RegisterUser";
import { DataListProps } from "Components/Header/PropsType";
import { Field, Formik } from "formik";
import { CheckboxWithLabel, TextField } from "formik-material-ui";
import i18n from "i18next";
import { Children, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import ReactLoading from "react-loading";
import { useMutation, useQuery } from "react-query";
import api from "Utils/AxiosConfig";
import { serializeCategoryToContext } from "Utils/Serializers/serializeCategory";
import { serializeRegisterUserIndustry } from "Utils/Serializers/serializeRegisterUse";
import * as yup from "yup";
import useStyles from "./styles";



interface IndustriesProps {
  changeStep: (value: number) => void;
  userID: number;
}

interface Body {
  interestedInduestries: number[];
  otherInterestedIndustries: string;
}

const INITIAL_VALUES = {
  interestedInduestries: [],
  otherInterestedIndustries: "",
};

export default function InterestedIndustriesInvestor({
  changeStep,
  userID,
}: IndustriesProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [categoriesData, setCategoriesData] = useState<DataListProps[]>([]);
  const [categoriesSelect, setCategoriesSelect] = useState(categorySelect);
  const categories: number[] = [];
  const [notCategories, setNotCategories] = useState(false);

  const { isLoading } = useQuery(
    "categoriesFetch",
    async () => api.get("api/investments/categories"),
    {
      onSuccess: (res) => {
        const data = res.data;
        setCategoriesData(serializeCategoryToContext(data));
      },
      onError: () => {
        setCategoriesData([]);
      },
    }
  );

  const validationSchema = yup.object().shape({
    otherInterestedIndustries: yup.string(),
  });

  const mutation = useMutation(
    (data: BackendUserRegisterIndustry) =>
      api.put(`/api/users/investors/${userID}/`, data),
    {
      onSuccess: () => {
        changeStep(4);
      },
    }
  );

  const allCategories = categoriesData.slice(0, 16);

  function Submit(
    interestedInduestries: number[],
    otherInterestedIndustries: string
  ) {
    const body: Body = {
      interestedInduestries: categories,
      otherInterestedIndustries,
    };
    iterateSelected();
    if (
      body.interestedInduestries.length > 0 ||
      body.otherInterestedIndustries !== ""
    ) {
      setNotCategories(false);
      mutation.mutate(serializeRegisterUserIndustry(body));
    } else {
      setNotCategories(true);
    }
  }

  const addCategory = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCategoriesSelect({
      ...categoriesSelect,
      [event.target.name]: event.target.checked,
    });
  };

  function iterateSelected() {
    Object.entries(categoriesSelect).forEach(([, value], index) => {
      if (value === true) categories.push(index + 1);
    });
  }

  return (
    <>
      <Typography className={classes.title}>
        <Trans i18nKey="Interested Industries">
          Interested <strong>Industries</strong>
        </Trans>
      </Typography>

      <Formik
        initialValues={INITIAL_VALUES}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(false);
          resetForm({ values: INITIAL_VALUES });
          Submit(categories, values.otherInterestedIndustries);
        }}
      >
        {({ submitForm, setFieldValue, isSubmitting }) => (
          <form className={classes.mainForm} autoComplete="off">
            {!isLoading ? (
              <FormControl fullWidth>
                <Box className={classes.checkGroup}>
                  {allCategories &&
                    Children.toArray(
                      allCategories.map((category, item) => (
                        <Field
                          component={CheckboxWithLabel}
                          type="checkbox"
                          name={Object.keys(categoriesSelect)[item]}
                          color="primary"
                          onChange={addCategory}
                          checked={Object.values(categoriesSelect)[item]}
                          indeterminate={false}
                          Label={{
                            label: `${
                              i18n.language === "EN"
                                ? category.nameEn
                                : category.nameEs
                            }`,
                          }}
                        />
                      ))
                    )}
                </Box>
                {notCategories && (
                  <FormHelperText className={classes.error}>
                    <strong>
                      {t("You must choose at least one category")}
                    </strong>
                  </FormHelperText>
                )}
              </FormControl>
            ) : (
              <ReactLoading
                type="spinningBubbles"
                color="#19283F"
                height={"8%"}
                width={"8%"}
              />
            )}
            <FormControl fullWidth className={classes.formControl}>
              <Box className={classes.boxInput}>
                <Field
                  className={classes.input}
                  label={t("Others Industries")}
                  name="otherInterestedIndustries"
                  component={TextField}
                  InputProps={{ disableUnderline: true }}
                />
              </Box>
            </FormControl>
            <Box>
              <Button
                className={classes.button}
                onClick={() => changeStep(2)}
                disabled={isSubmitting}
              >
                {t("BACK")}
              </Button>
              <Button
                className={classes.button}
                onClick={submitForm}
                disabled={isSubmitting}
              >
                {t("NEXT")}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
}
