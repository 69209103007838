import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      marginTop: "4%",
      marginBottom: "5%",
      padding: 0,
      width: "100%",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "left",
      [theme.breakpoints.down("sm")]: {
        marginTop: "25%",
      },
      [theme.breakpoints.between("sm", "md")]: {
        marginTop: "15%",
      },
    },
    containerBanner: {
      margin: 0,
      padding: 0,
      height: "580px",
      overflow: "hidden",
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    banner: {
      marginTop: "100px",
      width: "100vw",
    },
    banner2: {
      marginTop: "100px",
      width: "100vw",
    },
  })
);
