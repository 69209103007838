import {
  Box, Button,
  FormControl, Tooltip, Typography
} from "@material-ui/core";
import HelpIcon from "@mui/icons-material/Help";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  BackendFinancialInformationType,
  FinancialInformationType
} from "Common/Types/InvestmentType";
import useStyles from "Components/FundingRequest/styles";
import { Field, Formik } from "formik";
import { TextField } from "formik-material-ui";
import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import { useMutation } from "react-query";
import api from "Utils/AxiosConfig";
import { serializeFinancialtInformation } from "Utils/Serializers/serializeInvestment";
import * as yup from "yup";



interface FinancialInformationProps {
  changeStep: (value: number) => void;
  userID: number;
  financialInfo: FinancialInformationType;
}

interface Body {
  amount: string;
  sharesPercentage: string;
  potentialValue: string;
  marketValue: string;
  yearlyRevenue: string;
  yearlyRevenueFifth: string;
}

const INITIAL_VALUES = {
  amount: "",
  sharesPercentage: "",
  potentialValue: "",
  marketValue: "",
  yearlyRevenue: "",
  yearlyRevenueFifth: "",
};

export default function FinancialInformation({
  changeStep,
  userID,
  financialInfo,
}: FinancialInformationProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = yup.object().shape({
    amount: yup.string().required(t("Investment required is required")),
    sharesPercentage: yup
      .number()
      .min(
        1,
        t("Percentage of shares offered must have a value between 1% and 100%")
      )
      .max(
        100,
        t("Percentage of shares offered must have a value between 1% and 100%")
      )
      .required(t("Percentage of shares offered is required")),
    potentialValue: yup
      .string()
      .required(t("Estimated initial market value is required")),
    marketValue: yup
      .string()
      .required(t("Project market value in year 5 required")),
    yearlyRevenue: yup
      .string()
      .required(t("Yearly revenue on year 1 is required")),
    yearlyRevenueFifth: yup
      .string()
      .required(t("Yearly revenue on year 5 is required")),
  });

  const mutation = useMutation(
    (data: BackendFinancialInformationType) =>
      api.put(
        `/api/investments/investments/create/${userID}/financial_information/`,
        data
      ),
    {
      onSuccess: () => {
        changeStep(2);
      },
      onError: () => {
        setIsLoading(false);
      },
    }
  );

  const cleanNumber = (number: string) => {
    const newNumber = number.replace(/,/g, "");
    return newNumber;
  };

  function Submit(
    amount: string,
    sharesPercentage: string,
    potentialValue: string,
    marketValue: string,
    yearlyRevenue: string,
    yearlyRevenueFifth: string
  ) {
    const body: Body = {
      amount,
      sharesPercentage,
      potentialValue,
      marketValue,
      yearlyRevenue,
      yearlyRevenueFifth,
    };
    setIsLoading(true);
    mutation.mutate(serializeFinancialtInformation(body));
  }

  return (
    <>
      <Box className={classes.titleBox}>
        <Typography className={classes.title}>
          <Trans i18nKey="General Financial and Marketing Information">
            <strong>General Financial and Marketing Information</strong>
          </Trans>
        </Typography>
        <Tooltip
          title={
            <span className={classes.tooltip}>
              {t(
                "All the information in this page will go automatically to the Home page investment thumbnails"
              )}
            </span>
          }
          placement="right"
        >
          <HelpIcon className={classes.helpIcon} />
        </Tooltip>
      </Box>

      <Formik
        initialValues={financialInfo ? financialInfo : INITIAL_VALUES}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(false);
          Submit(
            cleanNumber(values.amount),
            values.sharesPercentage,
            cleanNumber(values.potentialValue),
            cleanNumber(values.marketValue),
            cleanNumber(values.yearlyRevenue),
            cleanNumber(values.yearlyRevenueFifth)
          );
          resetForm({ values: INITIAL_VALUES });
        }}
      >
        {({ submitForm, isSubmitting, errors, touched, values }) => (
          <form className={classes.mainForm} autoComplete="off">
         
            
            <FormControl fullWidth className={classes.formControl}>
            <Box className={classes.secondSubtitleFull}>
              <Typography variant="h5">
                  {t("Investment required (USD)")}
              </Typography>
            </Box>
              <Box className={classes.boxInput}>
                <Field
                  className={classes.input}
                  name="amount"
                  component={TextField}
                  InputProps={{
                    disableUnderline: true,
                    inputComponent: NumberFormat,
                    inputProps: {
                      thousandsGroupStyle: "thousand",
                      decimalSeparator: ".",
                      displayType: "input",
                      type: "text",
                      thousandSeparator: true,
                      allowNegative: true,
                    },
                  }}
                />
              </Box>
            </FormControl>
                  <br></br>
            <FormControl fullWidth className={classes.formControl}>
            <Box className={classes.secondSubtitleFull}>
              <Typography variant="h5">
                  {t("Percentage of shares offered for the investment")}
              </Typography>
            </Box>
              <Box className={classes.boxInput}>
                <Field
                  className={classes.input}
                  name="sharesPercentage"
                  type="number"
                  component={TextField}
                  InputProps={{ disableUnderline: true }}
                />
              </Box>
            </FormControl>
            <br></br>
            <FormControl fullWidth className={classes.formControl}>
            <Box className={classes.secondSubtitleFull}>
              <Typography variant="h5">
                  {t("Estimated Initial Valuation of the project in year 1 (USD)")}
              </Typography>
            </Box>
              <Box className={classes.boxInput}>
                <Field
                  className={classes.input}
                  name="potentialValue"
                  type="number"
                  component={TextField}
                  InputProps={{
                    disableUnderline: true,
                    inputComponent: NumberFormat,
                    inputProps: {
                      thousandsGroupStyle: "thousand",
                      decimalSeparator: ".",
                      displayType: "input",
                      type: "text",
                      thousandSeparator: true,
                      allowNegative: true,
                    },
                  }}
                />
              </Box>
            </FormControl>
            <br></br>
            <FormControl fullWidth className={classes.formControl}>
            <Box className={classes.secondSubtitleFull}>
              <Typography variant="h5">
                  {t("Estimated Total Market in Year 1 (USD)")}
              </Typography>
            </Box>

              <Box className={classes.boxInput}>
                <Field
                  className={classes.input}
                  name="marketValue"
                  type="number"
                  component={TextField}
                  InputProps={{
                    disableUnderline: true,
                    inputComponent: NumberFormat,
                    inputProps: {
                      thousandsGroupStyle: "thousand",
                      decimalSeparator: ".",
                      displayType: "input",
                      type: "text",
                      thousandSeparator: true,
                      allowNegative: true,
                    },
                  }}
                />
              </Box>
            </FormControl>
            <br></br>
            <FormControl fullWidth className={classes.formControl}>
            <Box className={classes.secondSubtitleFull}>
              <Typography variant="h5">
                  {t("Projected Valuation of the Project in year 5 (USD)")}
              </Typography>
            </Box>
              <Box className={classes.boxInput}>
                <Field
                  className={classes.input}
                  name="yearlyRevenue"
                  type="number"
                  component={TextField}
                  InputProps={{
                    disableUnderline: true,
                    inputComponent: NumberFormat,
                    inputProps: {
                      thousandsGroupStyle: "thousand",
                      decimalSeparator: ".",
                      displayType: "input",
                      type: "text",
                      thousandSeparator: true,
                      allowNegative: true,
                    },
                  }}
                />
              </Box>
            </FormControl>
            <br></br>
            <FormControl fullWidth className={classes.formControl}>
            <Box className={classes.secondSubtitleFull}>
              <Typography variant="h5">
                  {t("Projected Total Market in Year 5 (USD)")}
              </Typography>
            </Box>
              <Box className={classes.boxInput}>
                <Field
                  className={classes.input}
                  name="yearlyRevenueFifth"
                  type="number"
                  component={TextField}
                  InputProps={{
                    disableUnderline: true,
                    inputComponent: NumberFormat,
                    inputProps: {
                      thousandsGroupStyle: "thousand",
                      decimalSeparator: ".",
                      displayType: "input",
                      type: "text",
                      thousandSeparator: true,
                      allowNegative: true,
                    },
                  }}
                />
              </Box>
            </FormControl>
            <br></br>
            <Box>
              <Button onClick={() => changeStep(0)} className={classes.button}>
                {t("BACK")}
              </Button>
              <LoadingButton
                className={!isLoading ? classes.button : classes.none}
                onClick={submitForm}
                loading={isLoading}
                variant="outlined"
              >
                {t("NEXT")}
              </LoadingButton>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
}
