import { Button, Typography } from "@material-ui/core";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import useStyles from "./styles";



export default function ActivationRegister() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Typography className={classes.title}>
        <Trans i18nKey="GREAT! first step of registration almost ready">
          GREAT! <b>first step of registration almost ready</b>
        </Trans>
      </Typography>
      <Typography className={classes.description}>
        <Trans i18nKey="Activate your Wintellinvest from personal information">
          Activate your Wintellinvest account through the message that we sent to
          your email to continue the registration process!
        </Trans>
      </Typography>
      <Typography className={classes.description}>
        <Trans i18nKey="Activation complete from personal information">
          If you completed the account activation steps, please click LOG IN to
          continue with your registration.
        </Trans>
      </Typography>
      <Button className={classes.button} href="/?login=true">
        {t("LOG IN")}
      </Button>
    </>
  );
}
