import React, { Children, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useQuery } from "react-query";
import ReactLoading from "react-loading";

import {
  Grid,
  Typography,
  Button,
  Container,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

import Investment from "Components/Investment";
import { InvestmentType } from "Common/Types/InvestmentType";
import { serializeInvestmentToContext } from "Utils/Serializers/serializeInvestment";
import api from "Utils/AxiosConfig";
import useStyles from "./styles";
import { Link } from "react-router-dom";

export default function InvestmentOpportunity() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [featuredData, setFeaturedData] = useState<InvestmentType[]>([]);
  const [newestData, setNewestData] = useState<InvestmentType[]>([]);

  const { isLoading: featuredLoading } = useQuery(
    "featuredFetch",
    async () => api.get("api/investments/investments/search/featured"),
    {
      onSuccess: (res) => {
        const data = res.data;
        setFeaturedData(serializeInvestmentToContext(data));
      },
      onError: (error) => {
        console.error("Error:", error);
      },
    }
  );
  const { isLoading: newestLoading } = useQuery(
    "newestFetch",
    async () => api.get("api/investments/investments/search/newest"),
    {
      onSuccess: (res) => {
        const data = res.data;
        setNewestData(serializeInvestmentToContext(data));
      },
      onError: (error) => {
        console.error("Error:", error);
      },
    }
  );

  return (
    <>
      <Container maxWidth="md" className={classes.mainCtn}>
        <Typography variant="h2">
          <Trans i18nKey="Featured Investment Opportunities">
            Featured Investment <b>Opportunities</b>
          </Trans>
        </Typography>
        <Grid container justifyContent="center">
          {!featuredLoading ? (
            <TableContainer component={Paper} className={classes.tableCtn}>
              <Table>
                <TableHead>
                  <TableRow>
                    {featuredData &&
                      Children.toArray(
                        featuredData.map((investment) => (
                          <TableCell
                            align="center"
                            className={classes.tableCell}
                          >
                            <Investment
                              id={investment.id}
                              name={investment.name}
                              shortDescription={investment.shortDescription}
                              amount={investment.amount}
                              sharesPercentage={investment.sharesPercentage}
                              marketValue={investment.marketValue}
                              potentialValue={investment.potentialValue}
                              estimatedReturnMonths={
                                investment.estimatedReturnMonths
                              }
                              mainPictureThumbnail={
                                investment.mainPictureThumbnail
                              }
                            />
                          </TableCell>
                        ))
                      )}
                  </TableRow>
                </TableHead>
              </Table>
            </TableContainer>
          ) : (
            <ReactLoading
              type="spinningBubbles"
              color="#19283F"
              height={"8%"}
              width={"8%"}
            />
          )}
        </Grid>
      </Container>
      <Container maxWidth="md" className={classes.mainCtn}>
        <Typography variant="h2">
          <Trans i18nKey="New Investment Opportunities">
            New Investment <b>Opportunities</b>
          </Trans>
        </Typography>
        <Grid container justifyContent="center">
          {!newestLoading ? (
            <TableContainer component={Paper} className={classes.tableCtn}>
              <Table>
                <TableHead>
                  <TableRow>
                    {newestData &&
                      Children.toArray(
                        newestData.map((investment) => (
                          <TableCell
                            align="center"
                            className={classes.tableCell}
                          >
                            <Investment
                              id={investment.id}
                              name={investment.name}
                              shortDescription={investment.shortDescription}
                              amount={investment.amount}
                              sharesPercentage={investment.sharesPercentage}
                              marketValue={investment.marketValue}
                              potentialValue={investment.potentialValue}
                              estimatedReturnMonths={
                                investment.estimatedReturnMonths
                              }
                              mainPictureThumbnail={
                                investment.mainPictureThumbnail
                              }
                            />
                          </TableCell>
                        ))
                      )}
                  </TableRow>
                </TableHead>
              </Table>
            </TableContainer>
          ) : (
            <ReactLoading
              type="spinningBubbles"
              color="#19283F"
              height={"8%"}
              width={"8%"}
            />
          )}
        </Grid>
      </Container>
      <Container maxWidth="md" className={classes.button}>
        <Button>
          <Link className={classes.linkSeeProject} to="/search">
            {t("SEE ALL PROJECTS")}{" "}
          </Link>
        </Button>
      </Container>
    </>
  );
}
