import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      width: "450px",
      justifyContent: "space-between",
      alignItems: "flex-start",
    },
  })
);
