import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    slogansContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      height: "503px",
      background: "#19283F",
      [theme.breakpoints.down("sm")]: {
        height: "300px",
      },
    },
    SliderShow: {
      width: "50%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        width: "85%",
      },
    },
    icon: {
      width: "80px",
      height: "80px",
      [theme.breakpoints.down("sm")]: {
        width: "60px",
        height: "60px",
      },
    },
    arrowLeft: {
      borderRight: "20px solid #FFF",
      borderTop: "12px solid transparent",
      borderBottom: "12px solid transparent",
      cursor: "pointer",
      [theme.breakpoints.down("sm")]: {
        borderRight: "16px solid #FFF",
        borderTop: "8px solid transparent",
        borderBottom: "8px solid transparent",
      },
    },
    arrowRight: {
      borderLeft: "20px solid #FFF",
      borderTop: "12px solid transparent",
      borderBottom: "12px solid transparent",
      cursor: "pointer",
      [theme.breakpoints.down("sm")]: {
        borderLeft: "16px solid #FFF",
        borderTop: "8px solid transparent",
        borderBottom: "8px solid transparent",
      },
    },
    mainText: {
      color: "#FFFFFF",
      padding: "10px 100px",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "10px",
        paddingRight: "10px",
        fontSize: "20px",
      },
    },
  })
);
