import { Box, Button, FormControl, Typography } from "@material-ui/core";
import { Country } from "Common/Types/Country";
import { BackendUserRegisterAddress } from "Common/Types/RegisterUser";
import Autocomplete from "Components/AutoComplete";
import { Field, Formik } from "formik";
import { TextField as TexFielFormik } from "formik-material-ui";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useMutation } from "react-query";
import api from "Utils/AxiosConfig";
import { serializeRegisterUseAddress } from "Utils/Serializers/serializeRegisterUse";
import * as yup from "yup";
import useStyles, { buttonStyle, dropdownStyle, inputStyle } from "./styles";



interface AddressProps {
  changeStep: (value: number) => void;
  userID: number;
}
interface Body {
  country: string;
  city: string;
  zipCode: string;
  streetAddress: string;
  phoneNumber: string;
  state: string;
}

const INITIAL_VALUES = {
  country: "",
  city: "",
  streetAddress: "",
  zipCode: "",
  phoneNumber: "",
  state: "",
};

export default function AddressInformationEntrepreneur({
  changeStep,
  userID,
}: AddressProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [currentCountryPhone, setCurrentCountryPhone] = useState("");
  const [newState, setNewState] = useState(false);

  const validationSchema = yup.object().shape({
    country: yup.string().required(t("Country is required")),
    city: yup.string().required(t("City is required")),
    streetAddress: yup.string().required(t("Street Address is required")),
    zipCode: yup.string().required(t("Zip Code is required")),
    phoneNumber: yup.string().required(t("Phone Number is required")),
    state: yup.string().when("country", {
      is: (value: string) => value && value === "237",
      then: yup.string().required(t("The state is required")),
    }),
  });

  const mutation = useMutation(
    (data: BackendUserRegisterAddress) =>
      api.put(`api/users/personal_information/${userID}/`, data),
    {
      onSuccess: () => {
        changeStep(2);
      },
    }
  );

  function Submit(
    country: string,
    city: string,
    zipCode: string,
    streetAddress: string,
    phoneNumber: string,
    state: string
  ) {
    const body: Body = {
      country,
      city,
      zipCode,
      streetAddress,
      phoneNumber,
      state,
    };

    mutation.mutate(serializeRegisterUseAddress(body));
  }

  const handleForm = (country: Country | null) => {
    setCurrentCountryPhone(country?.code || "");
    if (country?.code === "US") {
      setNewState(true);
    } else {
      setNewState(false);
    }
  };

  return (
    <>
      <Typography className={classes.title}>
        <Trans i18nKey="Address Information">
          Address <strong>Information</strong>
        </Trans>
      </Typography>
      <Formik
        initialValues={INITIAL_VALUES}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(false);
          Submit(
            values.country,
            values.city,
            values.zipCode,
            values.streetAddress,
            values.phoneNumber,
            values.state
          );
          resetForm({ values: INITIAL_VALUES });
        }}
      >
        {({
          submitForm,
          setFieldValue,
          values,
          isSubmitting,
          touched,
          errors,
        }) => (
          <form className={classes.mainForm} autoComplete="off">
            <FormControl fullWidth className={classes.formControl}>
            <Box className={classes.secondSubtitle}>
                <Typography variant="h5">
                    {t("Country")}
                </Typography>
              </Box>
              <Box className={classes.boxInput}>
                <Field
                  name="country"
                  component={Autocomplete}
                  type="country"
                  handleChangeFormik={setFieldValue}
                  handleForm={handleForm}
                />
                {touched.country && errors.country ? (
                  <h1 className={classes.error}>{errors.country}</h1>
                ) : null}
              </Box>
            </FormControl>

            {newState && (
              <FormControl fullWidth className={classes.formControl}>
                <Box className={classes.secondSubtitle}>
                <Typography variant="h5">
                    {t("State")}
                </Typography>
              </Box>
              <Box className={classes.boxInput}>
                <Field
                  name="state"
                  component={Autocomplete}
                  type="state"
                  currentCountry={values.country}
                  handleChangeFormik={setFieldValue}
                />
                {touched.state && errors.state ? (
                  <h1 className={classes.error}>{errors.state}</h1>
                ) : null}
              </Box>
              </FormControl>
            )}

            <FormControl fullWidth className={classes.formControl}>
            <Box className={classes.secondSubtitle}>
                <Typography variant="h5">
                  {t("City")}
                </Typography>
              </Box>
              <Box className={classes.boxInput}>
                <Field
                  name="city"
                  component={Autocomplete}
                  disabled={values.country ? false : true}
                  type="city"
                  currentCountry={values.country}
                  currentState={values.state}
                  handleChangeFormik={setFieldValue}
                />
                {touched.city && errors.city ? (
                  <h1 className={classes.error}>{errors.city}</h1>
                ) : null}
              </Box>
            </FormControl>
            <FormControl fullWidth className={classes.formControl}>
            <Box className={classes.secondSubtitle}>
                <Typography variant="h5">
                {t("Street Address")}
                </Typography>
              </Box>
              <Box className={classes.boxInput}>
                <Field
                  className={classes.input}
                  name="streetAddress"
                  component={TexFielFormik}
                  InputProps={{ disableUnderline: true }}
                />
              </Box>
            </FormControl>

            <FormControl fullWidth className={classes.formControl}>
            <Box className={classes.secondSubtitle}>
                <Typography variant="h5">
                {t("Zip Code")}
                </Typography>
              </Box>
              <Box className={classes.boxInput}>
                <Field
                  className={classes.input}
                  name="zipCode"
                  component={TexFielFormik}
                  InputProps={{ disableUnderline: true }}
                />
              </Box>
            </FormControl>
            <FormControl fullWidth className={classes.formControl}>
            <Box className={classes.secondSubtitle}>
                <Typography variant="h5">
                    {t("Phone Number")}
                </Typography>
              </Box>
              <Box className={classes.boxInput}>
                <Field
                  name="phoneNumber"
                  component={PhoneInput}
                  onChange={(
                    _value: String,
                    _data: Object,
                    _event: React.ChangeEvent<HTMLInputElement>,
                    formattedValue: String
                  ) => {
                    setFieldValue("phoneNumber", formattedValue);
                  }}
                  
                  country={currentCountryPhone.toLowerCase()}
                  containerClass={classes.countryBox}
                  inputStyle={inputStyle}
                  buttonClass={classes.buttonClass}
                  buttonStyle={buttonStyle}
                  dropdownStyle={dropdownStyle}
                />
                {touched.phoneNumber && errors.phoneNumber ? (
                  <h1 className={classes.error}>{errors.phoneNumber}</h1>
                ) : null}
              </Box>
            </FormControl>
            <Button
              className={classes.button}
              onClick={submitForm}
              disabled={isSubmitting}
            >
              {t("NEXT")}
            </Button>
          </form>
        )}
      </Formik>
    </>
  );
}
