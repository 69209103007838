import {
  createStyles,
  alpha,
  makeStyles,
  Theme,
} from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    header: {
      width: "100%",
      height: "70px",
      transition: "background-color 0.5s ease-out",
      left: 0,
      display: "flex",
      justifyContent: "center",
    },
    boxHeader: {
      padding: "1.6rem 0",
      height: "auto",
      minHeight: "auto",
      [theme.breakpoints.down("sm")]: {
        padding: "1rem 0",
      },
      [theme.breakpoints.down("xs")]: {
        padding: ".8rem 0",
      },
    },
    boxHeaderMobile: {
      display: "flex",
      justifyContent: "space-between",
    },
    noScrollHeader: {
      backgroundColor: "transparent",
    },
    scrollHeader: {
      backgroundColor: "#19283F",
      boxShadow: `0 1px 1px ${alpha(
        theme.palette.primary.dark,
        0.16
      )}, 0 3px 6px ${alpha(theme.palette.primary.dark, 0.23)}`,
      height: "auto !important",
      padding: "0 !important",
      borderTop: "none !important",
    },
    nav: {
      width: "98%",
      display: "flex",
      margin: "0 auto",
      justifyContent: "space-between",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        "& h3": {
          fontSize: "32px",
        },
      },
    },
    contLogo: {
      textDecoration: "none",
      display: "flex",
      alignItems: "center",
      width: "12%",
      borderBottom: "none !important",
    },
    logo: {
      display: "flex",
    },
    textLogo: {
      color: "#000000",
      fontSize: "0.9rem",
      fontWeight: 500,
      letterSpacing: ".11rem",
    },
    iconMenuActive: {
      color: "#FFFFFF",
      padding: "0px",
    },
    iconMenu: {
      padding: "0px",
      color: "#fff",
      borderRadius: "4px",
      paddingLeft: 0,
      paddingRight: 0,
    },
    languageMenu: {
      fontFamily: "Helvetica",
      fontSize: "12px",
      opacity: "0.9",
      fontWeight: "bold",
      minHeight: "auto",
    },
    formControl: {
      marginLeft: theme.spacing(1),
      [theme.breakpoints.down("xs")]: {
        "& div": {
          fontSize: "12px",
          minHeight: "auto",
        },
      },
    },
    select: {
      "&:before": {
        borderColor: "transparent",
      },
      "&:after": {
        borderColor: "transparent",
      },

      "& .MuiInputBase-input": {
        color: "#FFFFFF",
        fontWeight: "bold",
        fontFamily: "Helvetica",
        fontSize: "12px",
        opacity: "0.9",
        minHeight: "auto",
      },
    },
    icon: {
      fill: "#47A6BE",
      fontSize: "16px",
    },
    iconTransparent: {
      fill: "transparent",
      fontSize: "0px",
    },
    containerButon: {
      display: "flex",
      width: "200px",
      justifyContent: "space-between",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        justifyContent: "flex-end",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        justifyContent: "flex-end",
      },
    },
  })
);
