import { Img } from "react-image";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Import Material Ui / Formik Related
import { Grid, Typography, Box, Link } from "@material-ui/core";
// Import locals
import buttonExit from "Assets/images/x.png";
import Entrepreneur from "Assets/images/consulting.jpg";
import Investor from "Assets/images/negotiations.jpg";
import useStyles from "./styles";

export interface LoginProps {
  onClose: () => void;
}

export default function SignUpDialog({ onClose }: LoginProps) {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const handleClick = (type: string) => {
    history.push(`/signUp/${type}`);
    onClose();
  };

  return (
    <Grid className={classes.container}>
      <Grid className={classes.subContainer}>
        <Grid className={classes.exitContainer}>
          <Img
            src={buttonExit}
            alt=""
            className={classes.ico}
            onClick={onClose}
          />
        </Grid>
        <Typography align="center" variant="h5">
          {t("SIGN UP")}
        </Typography>
        <Box className={classes.line} />
        <br />
        <Grid container spacing={2}>
          <Grid item md={6} xs={12} className={classes.userSign}>
            <Box className={classes.imgContianer}>
              <Img
                src={Entrepreneur}
                alt=""
                className={classes.img}
                onClick={() => handleClick("Entrepreneur")}
              />
            </Box>
            <br />
            <Link
              onClick={() => handleClick("Entrepreneur")}
              align="center"
              variant="h5"
            >
              {t("As Entrepreneur")}
            </Link>
          </Grid>
          <Grid item md={6} xs={12} className={classes.userSign}>
            <Box className={classes.imgContianer}>
              <Img
                src={Investor}
                alt=""
                className={classes.img}
                onClick={() => handleClick("Investor")}
              />
            </Box>
            <br />
            <Link
              onClick={() => handleClick("Investor")}
              align="center"
              variant="h5"
            >
              {t("As Investor")}
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
