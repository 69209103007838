import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      marginTop: "5%",
      marginBottom: "5%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        marginTop: "25%",
      },
      [theme.breakpoints.between("sm", "md")]: {
        marginTop: "15%",
      },
    },
    successContainer: {
      [theme.breakpoints.down("sm")]: {
        marginTop: "28%",
        marginBottom: "30%",
      },
      [theme.breakpoints.between("sm", "md")]: {
        marginTop: "5%",
        marginBottom: "12%",
      },
    },
    banner: {
      marginTop: "85px",
      width: "100%",
      height: "580px",
      objectFit: "cover",
      objectPosition: "center",
    },
    title: {
      fontSize: "24px",
      marginBottom: "5%",
      [theme.breakpoints.down("sm")]: {
        fontSize: "19px",
        marginBottom: "8%",
      },
      [theme.breakpoints.between("sm", "md")]: {
        fontSize: "19px",
        marginBottom: "4%",
      },
    },
    description: {
      fontSize: "20px",
      opacity: "0.6",
      marginBottom: "1%",
      [theme.breakpoints.down("md")]: {
        fontSize: "15px",
      },
    },
    button: {
      marginTop: "4%",
      background: "#19283F",
      borderRadius: "12px",
      border: "2px solid",
      color: "#FFFFFF",
      fontSize: "17px",
      letterSpacing: "1.5px",
      fontWeight: 500,
      paddingLeft: "15px",
      paddingRight: "15px",
      "&:hover": {
        background: "transparent",
        color: "#19283F",
        borderColor: "#19283F",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "12px",
        marginTop: "7%",
      },
      [theme.breakpoints.between("sm", "md")]: {
        fontSize: "12px",
        marginTop: "5%",
      },
    },
  })
);
