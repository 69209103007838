import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      margin: 0,
      padding: 0,
      height: "100vh",
      overflow: "hidden",
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    textHome: {
      position: "absolute",
      color: "#FFFFFF",
    },
    h1: {
      fontSize: "98px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "60px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "26px",
      },
    },
    h4: {
      fontSize: "40px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "18px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "12px",
      },
    },
    video: {
      width: "100vw",
      [theme.breakpoints.down("sm")]: {
        height: "100% !important",
        width: "auto",
      },
    },
  })
);
