export const COMPLETED_GREEN = "#52A85A";
export const REJECTED_RED = "#E83D6A";
export const REVIEW_BLUE = "#3897AD";
export const NEW_VIOLET = "#7D6899";

export const Rows = {
  id: 0,
  name: "",
  state: "",
};

export const ProjectInfo = {
  name: "",
  companyName: "",
  companyWebsite: "",
  mainPicture: "",
  videoUrl: "",
  strategicPlan: "",
  videoType: "Y",
  videoFile: "",
  strategicPlanUrl: "",
  mainPictureUrl: "",
  videoFileUrl: "",
  pitch: "",
  pitchUrl: "",
  financialForecast: "",
  financialForecastUrl: "",
};

export const FinancialInfo = {
  amount: "",
  sharesPercentage: "",
  potentialValue: "",
  marketValue: "",
  yearlyRevenue: "",
  yearlyRevenueFifth: "",
};

export const IndustriesInfo = {
  industries: [""],
  otherIndustries: "",
};

export const BusinessInfo = {
  shortDescription: "",
  description: "",
};

export const MarketInfo = {
  internalAppraisal: "",
  externalAppraisal: "",
  strengths: "",
  weakness: "",
  opportunities: "",
  threats: "",
  marketNeeds: "",
  competitiveAdvantages: "",
};

export const Strategy = {
  financialforecast_set: [
    {
      revenue: null!,
      operating_expenses: null!,
      income_before_taxes: null!,
      percentage_ibt_revenue: null!,
      year: 1,
    },
    {
      revenue: null!,
      operating_expenses: null!,
      income_before_taxes: null!,
      percentage_ibt_revenue: null!,
      year: 2,
    },
    {
      revenue: null!,
      operating_expenses: null!,
      income_before_taxes: null!,
      percentage_ibt_revenue: null!,
      year: 3,
    },
    {
      revenue: null!,
      operating_expenses: null!,
      income_before_taxes: null!,
      percentage_ibt_revenue: null!,
      year: 4,
    },
    {
      revenue: null!,
      operating_expenses: null!,
      income_before_taxes: null!,
      percentage_ibt_revenue: null!,
      year: 5,
    },
    {
      revenue: null!,
      operating_expenses: null!,
      income_before_taxes: null!,
      percentage_ibt_revenue: null!,
      year: 6,
    },
  ],
};

export const StrategyInfo = {
  vision: "",
  objectives: "",
  portfolio: "",
  segmentationStrategy: "",
  marketPrice: "",
  marketProduct: "",
  marketPlace: "",
  marketPromotion: "",
  investment: "",
};

export const StrategyImple = {
  communicateVision: "",
  developmentSkills: "",
  executionPlan: "",
  incentives: "",
  resources: "",
};

export const InvestmentDetailInfo = {
  amount: 0,
  sharesPercentage: 0,
  marketValue: 0,
  potentialValue: 0,
  yearlyRevenue: 0,
  yearlyRevenueFifth: 0,
  shortDescription: "",
  internalAppraisal: "",
  externalAppraisal: "",
  strengths: "",
  weakness: "",
  opportunities: "",
  threats: "",
  marketNeeds: "",
  name: "",
  mainPicture: "",
  competitiveAdvantages: "",
  videoUrl: "",
  description: "",
  videoType: "",
}