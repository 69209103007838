import { useTranslation } from "react-i18next";
import { Img } from "react-image";

import { Grid, Typography, Box } from "@material-ui/core";

import buttonExit from "Assets/images/x.png";
import IntellectualEntrepreneur from "Components/IntellectualProperty/Entrepreneur";
import useStyles from "Components/IntellectualProperty/styles";

export interface ModalProps {
  onClose: () => void;
}

export default function IntellectualEntrepreneurModal({ onClose }: ModalProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Grid container className={classes.exitContainer}>
        <Img
          src={buttonExit}
          alt=""
          className={classes.ico}
          onClick={onClose}
        />
      </Grid>
      <Typography align="center" className={classes.modalTitle}>
        {t("INTELLECTUAL PROPERTY FOR ENTREPRENEURS")}
      </Typography>
      <Box className={classes.line} />
      <IntellectualEntrepreneur />
    </>
  );
}
