import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    butonBlog: {
      width: "100%",
      border: "2px solid #19283F",
      borderRadius: "0px !important",
      backgroundColor: "#19283F",
      justifyContent: "flex-center",
      color: "white",
      fontSize: "24px",
      textTransform: "none",
      letterSpacing: "1.5px",
      "&:hover": {
        backgroundColor: "white",
        color: "#19283F",
      },
    },
    textInvestment: {
      width: "100%",
      marginLeft: "0px",
      paddingLeft: "0px",
      marginTop: "3rem",
      color: "#19283F",
    },
    textItem: {
      marginBottom: "0",
      fontSize: "18px",
      alignItems: "center",
      color: "#19283F",
      justifyContent: "flex-center",
    },
    arrowIcon: {
      fontSize: 10,
      padding: "0",
      marginTop: "0",
      height: "12px !important",
      color: "#19283F !important",
    },
    img: {
      width: "100%",
      height: undefined,
      aspectRatio: "2",
    },
    temp: {
      display: "flex",
      width: "100%",
      height: "900px",
      backgroundColor: "#19283F",
      justifyContent: "flex-center",
    }
  })
);
