import React, { useState } from "react";
import * as Yup from "yup";
import { useTranslation, Trans } from "react-i18next";
import { useMutation } from "react-query";
import i18n from "i18next";

import { Typography, Button, FormControl, Box } from "@material-ui/core";
import { TextField } from "formik-material-ui";
import { Field, Form, Formik } from "formik";

import EmailSuccess from "Components/PasswordReset/EmailSuccess";
import api from "Utils/AxiosConfig";
import useStyles from "./styles";

const INITIAL_VALUES = {
  email: "",
};

export default function EmailForm() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [emailSuccess, setEmailSuccess] = useState(false);
  const language = i18n.language.toLowerCase();

  const mutation = useMutation(
    (data: { email: string }) =>
      api.post(`api/users/password-reset/?language=${language}`, data),
    {
      onError: (error) => {
        console.error("Error: ", error);
        setEmailSuccess(false);
      },
      onSuccess: () => {
        setEmailSuccess(true);
      },
    }
  );

  const handleSendButton = (email: string) => {
    const body = {
      email,
    };
    mutation.mutate(body);
  };

  const validationSchema = () =>
    Yup.object({
      email: Yup.string()
        .email(t("Invalid email address"))
        .required(t("Email required")),
    });
  return (
    <>
      {!emailSuccess ? (
        <>
          <Typography className={classes.title}>
            <Trans i18nKey="Forgot your password?">
              Forgot your <b>password?</b>
            </Trans>
          </Typography>
          <Typography className={classes.description}>
            {t(
              "Please fill the form below to receive the instructions to reset your password"
            )}
          </Typography>
          <Formik
            initialValues={INITIAL_VALUES}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              handleSendButton(values.email);
              resetForm({ values: INITIAL_VALUES });
              setSubmitting(false);
            }}
          >
            {({ submitForm, isSubmitting, touched, errors }) => (
              <Form className={classes.mainForm}>
                <FormControl fullWidth className={classes.formControl}>
                  <Box className={classes.emailBox}>
                    <Field
                      className={classes.input}
                      placeholder={t("User e-mail (example@example.com)")}
                      name="email"
                      component={TextField}
                      InputProps={{ disableUnderline: true }}
                    />
                  </Box>
                </FormControl>
                <Button
                  className={classes.button}
                  type="submit"
                  disabled={isSubmitting}
                >
                  {t("SEND INSTRUCTIONS")}
                </Button>
              </Form>
            )}
          </Formik>
        </>
      ) : (
        <EmailSuccess />
      )}
    </>
  );
}
