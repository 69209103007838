import { Container, Hidden } from "@material-ui/core";
import MainResources from "Components/MainResources";
import Entrepreneur from "Components/Resources";
import { useParams } from "react-router-dom";
import useStyles from "./styles";



interface ResourcesType {
  type: string;
}
export default function ResourcesPage() {
  const classes = useStyles();
  const { type } = useParams<ResourcesType>();

  return (
    <>
      <Hidden only={["sm", "xs"]}>
        <div className={classes.containerBanner}>
          {type === "Entrepreneur" ? (
            <video
              src="https://i.imgur.com/eucNAn1.mp4"
              autoPlay
              loop
              className={classes.banner}
            />
          ) : (
            <video
              src="https://i.imgur.com/QqPKtMc.mp4"
              autoPlay
              loop
              className={classes.banner}
            ></video>
          )}
        </div>
      </Hidden>
      {
        type === "entrepreneurs" || type === "investors" ? 
          <div className={classes.mainContainer}>
            <MainResources
              blogSlug={type}
            />
          </div>
          :
          <div className={classes.mainContainer}>
            <Entrepreneur
              blogSlug={type}
            />
          </div>
      }
    </>
  );
}
