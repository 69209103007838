import {
  BackendUserRegister,
  BackendUserRegisterAddress, BackendUserRegisterCompany, BackendUserRegisterIndustry, UserRegister, UserRegisterAddress, UserRegisterCompany, UserRegisterIndustry
} from "Common/Types/RegisterUser";

export const serializeRegisterUse = (
  userBody: UserRegister
): BackendUserRegister => {
  return {
    user: {
      email: userBody.email,
      password: userBody.password,
      user_type: userBody.userType,
    },
    first_name: userBody.firstName,
    last_name: userBody.lastName,
    is_subscribed: userBody.isSubscribed,
  };
};

export const serializeRegisterUseAddress = (
  userAddress: UserRegisterAddress
): BackendUserRegisterAddress => {
  return {
    address: {
      country: userAddress.country,
      city: userAddress.city,
      state: userAddress.state,
      detail: userAddress.streetAddress,
      zip_code: userAddress.zipCode,
      secondary_detail: userAddress.state,
    },
    phone_number: userAddress.phoneNumber,
  };
};

export const serializeRegisterUserCompany = (
  userCompany: UserRegisterCompany
): BackendUserRegisterCompany => {
  return {
    company: {
      name: userCompany.name,
      website: userCompany.website,
      phone_number: userCompany.phoneNumber,
      executive_summary: userCompany.executiveSummary,
      address: {
        country: userCompany.country,
        city: userCompany.city,
        state: userCompany.state,
        detail: userCompany.detail,
        zip_code: userCompany.zipCode,
        secondary_detail: userCompany.state,
      },
    },
  };
};

export const serializeRegisterUserIndustry = (
  userIndustry: UserRegisterIndustry
): BackendUserRegisterIndustry => {
  return {
    interested_induestries: userIndustry.interestedInduestries,
    other_interested_industries: userIndustry.otherInterestedIndustries,
  };
};
