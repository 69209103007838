import {
  Box, Button, Divider, FormControl, Tooltip, Typography
} from "@material-ui/core";
import HelpIcon from "@mui/icons-material/Help";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  BackendStrategyInformationType, StrategyInformationType
} from "Common/Types/InvestmentType";
import useStyles from "Components/FundingRequest/styles";
import { Field, Formik } from "formik";
import { TextField } from "formik-material-ui";
import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import api from "Utils/AxiosConfig";
import { serializeStrategyInformation } from "Utils/Serializers/serializeInvestment";
import * as yup from "yup";



interface StrategyInformationProps {
  changeStep: (value: number) => void;
  userID: number;
  changeOptionalID: (value: number) => void;
  strategyInfo: StrategyInformationType;
  newProject: boolean;
  idProject: number;
}

const INITIAL_VALUES = {
  vision: "",
  objectives: "",
  portfolio: "",
  segmentationStrategy: "",
  marketPrice: "",
  marketProduct: "",
  marketPlace: "",
  marketPromotion: "",
  investment: "",
};

export default function StrategyInformation({
  changeStep,
  userID,
  changeOptionalID,
  strategyInfo,
  newProject,
  idProject,
}: StrategyInformationProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = yup.object().shape({
    vision: yup.string(),
    objectives: yup.string(),
    portfolio: yup.string(),
    segmentationStrategy: yup.string(),
    marketPrice: yup.string(),
    marketProduct: yup.string(),
    marketPlace: yup.string(),
    marketPromotion: yup.string(),
  });

  const mutation = useMutation(
    (data: BackendStrategyInformationType) =>
      api.post(`/api/investments/investments/optionals/${idProject}/update_strategy_development/`, data),
    {
      onError(error) {
        setIsLoading(false);
        console.error("Error", error);
      },
      onSuccess: (data) => {
        changeOptionalID(data.data.id);
        changeStep(7);
      },
    }
  );

  const updateMutation = useMutation(
    (data: BackendStrategyInformationType) =>
      api.put(
        `/api/investments/investments/optionals/${idProject}/update_strategy_development/`,
        data
      ),
    {
      onError(error) {
        console.error("Error", error);
      },
      onSuccess: (data) => {
        changeOptionalID(data.data.id);
        changeStep(7);
      },
    }
  );

  const cleanNumber = (number: string) => {
    const newNumber = number.includes(",") ? number.replace(/,/g, "") : number;
    return newNumber;
  };

  function Submit(
    vision: string,
    objectives: string,
    portfolio: string,
    segmentationStrategy: string,
    marketPrice: string,
    marketProduct: string,
    marketPlace: string,
    marketPromotion: string,
    investment: string
  ) {
    const body: StrategyInformationType = {
      vision,
      objectives,
      portfolio,
      segmentationStrategy,
      marketPrice,
      marketProduct,
      marketPlace,
      marketPromotion,
      investment: JSON.stringify(userID),
    };

    if (newProject) {
      setIsLoading(true);
      mutation.mutate(serializeStrategyInformation(body));
    } else {
      setIsLoading(true);
      updateMutation.mutate(serializeStrategyInformation(body));
    }
  }

  return (
    <>
      <Box className={classes.titleBox}>
        <Typography className={classes.title}>
          <Trans i18nKey="Strategy Development">
            <strong>Strategy Development</strong>
          </Trans>
        </Typography>
        <Tooltip
          title={
            <span className={classes.tooltip}>
              {t("This step is not mandatory")}
            </span>
          }
          placement="right"
        >
          <HelpIcon className={classes.helpIcon} />
        </Tooltip>
      </Box>

      <Formik
        initialValues={strategyInfo ? strategyInfo : INITIAL_VALUES}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(false);
          Submit(
            values.vision,
            values.objectives,
            values.portfolio,
            values.segmentationStrategy,
            cleanNumber(values.marketPrice.toString()),
            values.marketProduct,
            values.marketPlace,
            values.marketPromotion,
            JSON.stringify(userID)
          );
          resetForm({ values: INITIAL_VALUES });
        }}
      >
        {({ submitForm, isSubmitting, errors, touched, values }) => (
          <form className={classes.mainForm} autoComplete="off">
            <Box className={classes.secondSubtitle}>
              <Typography variant="h5">{t("Vision")}</Typography>
              <Tooltip
                title={
                  <span className={classes.tooltip}>
                    <Trans i18nKey="Vision Description">
                      {t(
                        `The fundamental purpose of strategic planning is to
                      align a company’s mission with its vision. Without
                      mission and vision, the plan exists in a vacuum, as the
                      mission is the starting point for planning, the vision
                      is the destination, and the strategic plan is the
                      roadmap that helps you navigate from one to the other.
                      Values are also important to the strategic planning
                      process as they provide the organization with a
                      touchstone for developing appropriate strategies and
                      tactics; if any strategy or tactic is in opposition to
                      the company’s values, then it should be reconsidered.`
                      )}
                    </Trans>
                  </span>
                }
                placement="right"
              >
                <HelpIcon className={classes.helpIcon} />
              </Tooltip>
            </Box>
            <FormControl fullWidth className={classes.formControlTextBox}>
              <Box className={classes.boxInput}>
                <Field
                  className={classes.inputTextBox}
                  placeholder={t("Limit to 1500 characters")}
                  name="vision"
                  component={TextField}
                  InputProps={{ disableUnderline: true }}
                  multiline={true}
                  rows="5"
                  inputProps={{ maxLength: 1500 }}
                />
              </Box>
            </FormControl>
            <Box className={classes.secondSubtitle}>
              <Typography variant="h5">
                {t("Objectives (Measurable and Challenging)")}
              </Typography>
              <Tooltip
                title={
                  <span className={classes.tooltip}>
                    <Trans i18nKey="Objectives Description">
                      {t(
                        `Strategic objectives are statements that indicate
                      what is critical or important in your organizational
                      strategy. In other words, they’re strategic goals you’re
                      trying to achieve in a certain period of time typically
                      3-5 years. Your objectives link out to your measures and
                      initiatives.`
                      )}
                    </Trans>
                  </span>
                }
                placement="right"
              >
                <HelpIcon className={classes.helpIcon} />
              </Tooltip>
            </Box>
            <FormControl fullWidth className={classes.formControlTextBox}>
              <Box className={classes.boxInput}>
                <Field
                  className={classes.inputTextBox}
                  placeholder={t("Limit to 1500 characters")}
                  name="objectives"
                  component={TextField}
                  InputProps={{ disableUnderline: true }}
                  multiline={true}
                  rows="5"
                  inputProps={{ maxLength: 1500 }}
                />
              </Box>
            </FormControl>
            <Box className={classes.secondSubtitle}>
              <Typography variant="h5">
                {t("Portfolio (Existing and new products)")}
              </Typography>
              <Tooltip
                title={
                  <span className={classes.tooltip}>
                    <Trans i18nKey="Portfolio Description">
                      {t(
                        `A product portfolio is the collection of all the
                      products or services offered by a company. Product
                      portfolio analysis can provide nuanced views on a stock
                      type, company growth prospects, profit margin drivers,
                      income contributions, market leadership, and operational
                      risk.`
                      )}
                    </Trans>
                  </span>
                }
                placement="right"
              >
                <HelpIcon className={classes.helpIcon} />
              </Tooltip>
            </Box>
            <FormControl fullWidth className={classes.formControlTextBox}>
              <Box className={classes.boxInput}>
                <Field
                  className={classes.inputTextBox}
                  placeholder={t("Limit to 1500 characters")}
                  name="portfolio"
                  component={TextField}
                  InputProps={{ disableUnderline: true }}
                  multiline={true}
                  rows="5"
                  inputProps={{ maxLength: 1500 }}
                />
              </Box>
            </FormControl>
            <Box className={classes.secondSubtitle}>
              <Typography variant="h5">{t("Segmentation Strategy")}</Typography>
              <Tooltip
                title={
                  <span className={classes.tooltip}>
                    <Trans i18nKey="Segmentation Strategy Description">
                      {t(
                        `A segmentation strategy is a marketing concept that
                      refers to a company's plan for identifying each section
                      of its target market. Businesses develop a segmentation
                      strategy to appropriately categorize their customers and
                      choose the best niches for their products and
                      advertising.`
                      )}
                    </Trans>
                  </span>
                }
                placement="right"
              >
                <HelpIcon className={classes.helpIcon} />
              </Tooltip>
            </Box>
            <FormControl fullWidth className={classes.formControlTextBox}>
              <Box className={classes.boxInput}>
                <Field
                  className={classes.inputTextBox}
                  placeholder={t("Limit to 1500 characters")}
                  name="segmentationStrategy"
                  component={TextField}
                  InputProps={{ disableUnderline: true }}
                  multiline={true}
                  rows="5"
                  inputProps={{ maxLength: 1500 }}
                />
              </Box>
            </FormControl>
            <Box className={classes.subtitleCtn}>
              <Typography className={classes.subtitle}>
                <Trans i18nKey="Positioning Strategy / Market Tactics">
                  <strong>
                    Positioning Strategy / Market Tactics
                  </strong>
                </Trans>
              </Typography>
              <Tooltip
                title={
                  <span className={classes.tooltip}>
                    <Trans i18nKey="Positioning Strategy Description">
                      {t(
                        `Positioning strategy defines the tactics, tools and
                      strategies used by a business to differentiate itself
                      from competitors and gain market share. In an 
                      ultra-competitive market, positioning strategy is often
                      the difference between failure and success.`
                      )}
                    </Trans>
                  </span>
                }
                placement="right"
              >
                <HelpIcon className={classes.helpIcon} />
              </Tooltip>
            </Box>
            <Divider className={classes.divider} />
            <Box className={classes.secondSubtitle}>
              <Typography variant="h5">{t("Price")}</Typography>
              <Tooltip
                title={
                  <span className={classes.tooltip}>
                    <Trans i18nKey="Price Description">
                      {t(
                        `Price is the cost consumers pay for a product.
                      Marketers must link the price to the product's real and
                      perceived value, but they also must consider supply
                      costs, seasonal discounts, and competitors' prices. In
                      some cases, business executives may raise the price to
                      give the product the appearance of being a luxury.
                      Alternatively, they may lower the price so more
                      consumers can try the product.`
                      )}
                    </Trans>
                  </span>
                }
                placement="right"
              >
                <HelpIcon className={classes.helpIcon} />
              </Tooltip>
            </Box>
            <FormControl fullWidth className={classes.formControlTextBox}>
              <Box className={classes.boxInput}>
                <Field
                  className={classes.inputTextBox}
                  placeholder={t("Limit to 1500 characters")}
                  name="marketPrice"
                  InputProps={{ disableUnderline: true }}
                  component={TextField}
                  multiline={true}
                  rows="5"
                  inputProps={{ maxLength: 1500}}
                />
              </Box>
            </FormControl>
            <Box className={classes.secondSubtitle}>
              <Typography variant="h5">{t("Product")}</Typography>
              <Tooltip
                title={
                  <span className={classes.tooltip}>
                    <Trans i18nKey="Product Description">
                      {t(
                        `Product refers to a good or service that a company
                      offers to customers. Ideally, a product should fulfill
                      an existing consumer demand. Or a product may be so
                      compelling that consumers believe they need to have it
                      and it creates a new demand.`
                      )}
                    </Trans>
                  </span>
                }
                placement="right"
              >
                <HelpIcon className={classes.helpIcon} />
              </Tooltip>
            </Box>
            <FormControl fullWidth className={classes.formControlTextBox}>
              <Box className={classes.boxInput}>
                <Field
                  className={classes.inputTextBox}
                  placeholder={t("Limit to 1500 characters")}
                  name="marketProduct"
                  component={TextField}
                  InputProps={{ disableUnderline: true }}
                  multiline={true}
                  rows="5"
                  inputProps={{ maxLength: 1500 }}
                />
              </Box>
            </FormControl>
            <Box className={classes.secondSubtitle}>
              <Typography variant="h5">{t("Place")}</Typography>
              <Tooltip
                title={
                  <span className={classes.tooltip}>
                    <Trans i18nKey="Place Description">
                      {t(
                        `When a company makes decisions regarding place, they
                      are trying to determine where they should sell a product
                      and how to deliver the product to the market. The goal
                      of business executives is always to get their products
                      in front of the consumers that are the most likely to
                      buy them.`
                      )}
                    </Trans>
                  </span>
                }
                placement="right"
              >
                <HelpIcon className={classes.helpIcon} />
              </Tooltip>
            </Box>
            <FormControl fullWidth className={classes.formControlTextBox}>
              <Box className={classes.boxInput}>
                <Field
                  className={classes.inputTextBox}
                  placeholder={t("Limit to 1500 characters")}
                  name="marketPlace"
                  component={TextField}
                  InputProps={{ disableUnderline: true }}
                  multiline={true}
                  rows="5"
                  inputProps={{ maxLength: 1500 }}
                />
              </Box>
            </FormControl>
            <Box className={classes.secondSubtitle}>
              <Typography variant="h5">{t("Promotion")}</Typography>
              <Tooltip
                title={
                  <span className={classes.tooltip}>
                    <Trans i18nKey="Promotion Description">
                      {t(
                        `Promotion includes advertising, public relations, and
                      promotional strategy. The goal of promoting a product is
                      to reveal to consumers why they need it and why they
                      should pay a certain price for it.`
                      )}
                    </Trans>
                  </span>
                }
                placement="right"
              >
                <HelpIcon className={classes.helpIcon} />
              </Tooltip>
            </Box>
            <FormControl fullWidth className={classes.formControlTextBox}>
              <Box className={classes.boxInput}>
                <Field
                  className={classes.inputTextBox}
                  placeholder={t("Limit to 1500 characters")}
                  name="marketPromotion"
                  component={TextField}
                  InputProps={{ disableUnderline: true }}
                  multiline={true}
                  rows="5"
                  inputProps={{ maxLength: 1500 }}
                />
              </Box>
            </FormControl>
            <Box>
              <Button onClick={() => changeStep(5)} className={classes.button}>
                {t("BACK")}
              </Button>
              <LoadingButton
                className={!isLoading ? classes.button : classes.none}
                onClick={submitForm}
                loading={isLoading}
                variant="outlined"
              >
                {t("NEXT")}
              </LoadingButton>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
}
