import { Container, Hidden } from "@material-ui/core";
import banner from "Assets/images/bannerPasswordReset.png";
import { ActivationType } from "Common/Types/ActivationType";
import ActivationFailed from "Components/ActivationAccount/ActivationFailed";
import ActivationSuccess from "Components/ActivationAccount/ActivationSuccess";
import React, { useState } from "react";
import { Img } from "react-image";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import api from "Utils/AxiosConfig";
import useStyles from "./styles";



export default function ActivationAccountPage() {
  const classes = useStyles();
  const { uuid, token, language } = useParams<ActivationType>();
  const [errorActivation, setErrorActivation] = useState(false);
  const [callMade, setCallMade] = useState(false);

  const mutation = useMutation(
    (data: ActivationType) => api.post("api/users/activation/", data),
    {
      onError: (error) => {
        console.error("Error: ", error);
        setErrorActivation(true);
        setCallMade(true);
      },
      onSuccess: () => {
        setErrorActivation(false);
        setCallMade(true);
      },
    }
  );

  // useEffect(() => {
    const activation: ActivationType = {
      uuid: uuid,
      token: token,
      language: language,
    };
    if (callMade === false){  
      mutation.mutate(activation);
    }
  //}, [uuid, token, language, mutation]);

  return (
    <>
      <Hidden only={["sm", "xs"]}>
        <Img src={banner} className={classes.banner} />
      </Hidden>
      <Container maxWidth="md" className={classes.mainContainer}>
        {!errorActivation ? <ActivationSuccess /> : <ActivationFailed />}
      </Container>
    </>
  );
}
