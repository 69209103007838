import { useState } from "react";
import { Img } from "react-image";
import { useTranslation } from "react-i18next";
import Carousel, { arrowsPlugin } from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";

import { Box, Grid, Typography } from "@material-ui/core";

import dollar from "Assets/images/dollar.svg";
import star from "Assets/images/star.svg";
import flor from "Assets/images/flor.svg";
import dolar2 from "Assets/images/dolar2.svg";
import circle from "Assets/images/circle.svg";
import bardollar from "Assets/images/bardollar.svg";

import useStyles from "./styles";

export default function Slogans() {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const { t } = useTranslation();

  const onChange = (value: any) => {
    setValue(value);
  };

  const slogans = [
    {
      icon: star,
      text: t("The # 1 company to invest in Tech companies in Latin America"),
    },
    {
      icon: flor,
      text: t("The # 1 company to invest in Latin America projects"),
    },
    {
      icon: dollar,
      text: t(
        "Entrepreneurs Post a Funding request and Ask Investors for Funding…..Investors , get connected with Latin American Entrepreneurs"
      ),
    },
    {
      icon: dolar2,
      text: t(
        "The #1 company for Entrepreneurs to Ask Investors for Funding and for Investors to get connected with Latin American Entrepreneurs"
      ),
    },
    {
      icon: circle,
      text: t(
        "The company for Entrepreneurs to ask Investors for Funding and for Investors to get connected with Latin American Entrepreneurs"
      ),
    },
    {
      icon: bardollar,
      text: t(
        "Latin American entrepreneurs connect with million dollars in Fundings"
      ),
    },
  ];

  setTimeout(() => {
    if (value < slogans.length) {
      setValue((prev) => prev + 1);
    } else {
      setValue(0);
    }
  }, 5000);

  const renderSlogan = slogans.map((item) => (
    <div>
      <Img src={item.icon} className={classes.icon} />
      <Typography variant="h4" className={classes.mainText}>
        {item.text}
      </Typography>
    </div>
  ));

  return (
    <Grid className={classes.slogansContainer}>
      <Grid className={classes.SliderShow}>
        <Carousel
          value={value}
          onChange={onChange}
          slides={renderSlogan}
          plugins={[
            "clickToChange",
            {
              resolve: arrowsPlugin,
              options: {
                arrowLeft: <Box className={classes.arrowLeft} />,
                arrowRight: <Box className={classes.arrowRight} />,
                addArrowClickHandler: true,
              },
            },
          ]}
        />
      </Grid>
    </Grid>
  );
}
