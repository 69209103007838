import {
  Container, FormControl, Grid, Hidden, Link, MenuItem,
  Select, Typography
} from "@material-ui/core";
import bannerSearch from "Assets/images/bannerSearch.jpg";
import { InvestmentType } from "Common/Types/InvestmentType";
import Filter from "Components/Filter";
import GridProducts from "Components/GridProducts";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Img } from "react-image";
import { useQuery } from "react-query";
import { useLocation, useParams } from "react-router-dom";
import api from "Utils/AxiosConfig";
import { serializeInvestmentToContext } from "Utils/Serializers/serializeInvestment";
import useStyles from "./styles";

interface AmountMarket {
  amountlte: number | undefined;
  amountgte: number | undefined;
  marketvaluegte: number | undefined;
  marketvaluelte: number | undefined;
  sharespercentagelte: number | undefined;
  sharespercentagegte: number | undefined;
}

interface Category {
  categoryID: string;
}

interface CustomState {
  seach: string
}

export default function SearchPage() {
  const classes = useStyles();
  const [orderBy, setOrderBy] = useState("");
  const { t } = useTranslation();
  const location = useLocation();
  const [allProducts, setAllProducts] = useState<InvestmentType[]>([]);
  const [countData, setCountData] = useState(0);
  const [category, setCategory] = useState<number>();
  const [searchTerm, setSearchTerm] = useState("");
  const [useInitial, setUseInitial] = useState(true);
  const [amountMarket, setAmountMarket] = useState<AmountMarket>({
    marketvaluegte: undefined,
    marketvaluelte: undefined,
    amountlte: undefined,
    amountgte: undefined,
    sharespercentagelte: undefined,
    sharespercentagegte: undefined,
  });

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setOrderBy(event.target.value as string);
  };

  const { categoryID } = useParams<Category>();

  const [params, setParams] = useState(categoryID);


  const getFilters = async () => {
    const url = `api/investments/investments/search/`;
    const result = await api.get(url, {
      params: {
        search: useInitial ? location.state : searchTerm,
        ordering: orderBy,
        industries: params !== "" ? params : category,
        amount__lte: amountMarket.amountlte,
        amount__gte: amountMarket.amountgte,
        market_value__lte: amountMarket.marketvaluelte,
        market_value__gte: amountMarket.marketvaluegte,
        shares_percentage__lte: amountMarket.sharespercentagelte,
        shares_percentage__gte: amountMarket.sharespercentagegte,
      },
    });
    setParams("");
    return result;
  };

 
   

  const { isLoading } = useQuery(
    [useInitial, location.state, orderBy, category, amountMarket],
    () => getFilters(),
    {
      onSuccess: (res) => {
        const data = res.data.results;
        setCountData(res.data.count);
        setAllProducts(serializeInvestmentToContext(data));
      },
    }
  );
  
  
  const removeSearch = () =>{
    setUseInitial(false);
    setSearchTerm("");

  }
  const searchLoc = useInitial ? location.state as CustomState : searchTerm;

  return (
    <>
      <Hidden only={["sm", "xs"]}>
        <Img src={bannerSearch} className={classes.banner} />
      </Hidden>
      <Container maxWidth="lg">
        <Grid container className={classes.resultCtn}>
          <Grid item md={10}>
            <>
            {useInitial && <Typography align="left" className={classes.resultInput}>{t("Search Terms")}: {" "}<b>{searchLoc}</b> <Link style={{"cursor": "pointer"}} onClick={removeSearch}>[x]</Link></Typography>}
            <Typography align="left" className={classes.resultInput}>
              {t("Showing")}{" "}
              <b>
                {countData} {t("of")} {countData} {t("results")}
              </b>
            </Typography>
            </>
          </Grid>
          <Grid item md={2}>
            <FormControl fullWidth>
              <Select
                value={orderBy}
                onChange={handleChange}
                className={classes.orderList}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                disableUnderline
              >
                <MenuItem value="">{t("Order by")}</MenuItem>
                <MenuItem value="name">{t("Alphabetical asc")}</MenuItem>
                <MenuItem value="-name">{t("Alphabetical desc")}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container className={classes.mainSearch}>
          <Grid item md={3} className={classes.filtersCtn}>
            <Filter
              setCategory={setCategory}
              setAmountMarket={setAmountMarket}
              amountMarket={amountMarket}
            />
          </Grid>
          <Grid item md={9}>
            <Container maxWidth="lg" className={classes.mainContainer}>
              <GridProducts data={allProducts} isLoading={isLoading} />
            </Container>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
