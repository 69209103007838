import React, { useState, useEffect } from "react";
import { Img } from "react-image";
import { useParams } from "react-router-dom";

import { Container, Hidden } from "@material-ui/core";

import bannerPasswordReset from "Assets/images/bannerPasswordReset.png";
import EmailForm from "Components/PasswordReset/EmailForm";
import NewPasswordForm from "Components/PasswordReset/NewPasswordForm";
import { PasswordResetType } from "Common/Types/PasswordRecoveryType";
import useStyles from "./styles";

export default function PasswordResetPage() {
  const classes = useStyles();
  const { token, language } = useParams<PasswordResetType>();
  const [newPassword, setNewPassword] = useState(false);

  useEffect(() => {
    if (token) {
      setNewPassword(true);
    }
  }, [language, token]);

  return (
    <>
      <Hidden only={["sm", "xs"]}>
        <Img src={bannerPasswordReset} className={classes.banner} />
      </Hidden>
      <Container maxWidth="md" className={classes.mainContainer}>
        {newPassword ? (
          <NewPasswordForm token={token} language={language.toUpperCase()} />
        ) : (
          <EmailForm />
        )}
      </Container>
    </>
  );
}
