import {
  Box, Button,
  FormHelperText, Grid, IconButton, InputAdornment,
  InputLabel, Link, Typography
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import buttonExit from "Assets/images/x.png";
import { ErrorResponseType } from "Common/Types/ErrorResponseType";
import FacebookAuth from "Components/FacebookAuth";
import GoogleAuth from "Components/GoogleAuth";
import { userContext } from "Context/UserSesion/UserContext";
import { Field, Formik } from "formik";
import { TextField } from "formik-material-ui";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Img } from "react-image";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import api from "Utils/AxiosConfig";
import * as yup from "yup";
import useStyles from "./styles";


export interface LoginProps {
  onClose: () => void;
}
interface Body {
  email: string;
  password: string;
}
const INITIAL_VALUES = {
  password: "",
  email: "",
};

export default function LogIn({ onClose }: LoginProps) {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [emailUser, setEmailUser] = useState("");
  const { setUser } = useContext(userContext);
  const { t } = useTranslation();
  const history = useHistory();

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email(t("Enter a valid email"))
      .required(t("Email is required")),
    password: yup
      .string()
      .min(8, t("Password should be of minimum 8 characters length"))
      .required(t("Password is required")),
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const mutation = useMutation(
    (data: Body) => api.post("api/users/api-token-auth/", data),
    {
      onError: (error: ErrorResponseType) => {
        const errorActivation = error.response.data.non_field_errors[0];
        if (errorActivation === "User account is disabled.") {
          history.push(`/resendActivationAccount`, { email: emailUser });
          onClose();
        }
        setErrorMessage(true);
      },
      onSuccess: (data) => {
        const token = data.data["token"];
        if (data.data["state"] === "NEW") {
          statusUser(token);
        }
        if (window.location.pathname === "/fundingRequest") {
          window.location.href = "/fundingRequest";
        }
        setUser({
          token: token,
        });
        onClose();
      },
    }
  );

  const statusUser = async (token: string) => {
    const action = await api.get("/api/users/profile/");
    if (action) {
      if (action.data.investor === null) {
        history.push("/signUp/Entrepreneur", action.data);
      } else {
        history.push("/signUp/Investor", action.data);
      }
    }
  };

  function Submit(username: string, password: string) {
    const body: Body = {
      email: username,
      password,
    };
    setEmailUser(username);
    mutation.mutate(body);
  }

  return (
    <Grid className={classes.container}>
      <Grid className={classes.subContainer}>
        <Grid className={classes.exitContainer}>
          <Img
            src={buttonExit}
            alt=""
            className={classes.ico}
            onClick={onClose}
          />
        </Grid>
        <Typography align="center" variant="h5">
          {t("LOG IN")}
        </Typography>
        <Box className={classes.line} />
        <Formik
          initialValues={INITIAL_VALUES}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            setSubmitting(false);
            Submit(values.email, values.password);
            resetForm({ values: INITIAL_VALUES });
          }}
        >
          {({ submitForm, isSubmitting, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Grid className={classes.form}>
                <Box width={1}>
                  <InputLabel>{t("E-mail")}</InputLabel>
                  <Field
                    disabled={isSubmitting}
                    className={classes.textFiel}
                    size="small"
                    variant="outlined"
                    fullWidth
                    name="email"
                    color="secondary"
                    component={TextField}
                  />
                </Box>
                <Box width={1}>
                  <InputLabel>{t("Password")}</InputLabel>
                  <Field
                    disabled={isSubmitting}
                    variant="outlined"
                    size="small"
                    fullWidth
                    type={showPassword ? "text" : "password"}
                    name="password"
                    color="secondary"
                    className={classes.textFiel}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    component={TextField}
                  />
                </Box>

                {errorMessage && (
                  <>
                    <Box className={classes.suscribeCtn}>
                      <FormHelperText className={classes.suscribeMsg}>
                        {t("Wrong e-mail or password!")}
                      </FormHelperText>
                    </Box>
                  </>
                )}
              </Grid>
              <Grid className={classes.butonContainer}>
                <Button
                  className={classes.buttonLogin}
                  type="submit"
                  disabled={isSubmitting}
                >
                  {t("LOG IN")}
                </Button>
              </Grid>
              <FacebookAuth callback={onClose} isInvestor={false} isSignUp={false}/>
              <GoogleAuth callback={onClose} isInvestor={false} isSignUp={false}/>
            </form>
          )}
        </Formik>
        <Typography className={classes.forgotpassword}>
          {t("Forgot password?")}
          <Link href="/resetPassword" className={classes.forgotpasswordClick}>
            {t("Click Here")}
          </Link>
        </Typography>
      </Grid>
    </Grid>
  );
}
