import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      width: "500px",
      justifyContent: "space-between",
      alignItems: "center",
    },
    formControl: {
      marginLeft: theme.spacing(1.7),
      [theme.breakpoints.down("xs")]: {
        "& div": {
          fontSize: "12px",
        },
      },
    },
    select: {
      "&:before": {
        borderColor: "transparent",
      },
      "&:after": {
        borderColor: "transparent",
      },

      "& .MuiInputBase-input": {
        color: "#FFFFFF",
        fontWeight: "bold",
        fontFamily: "Helvetica",
        fontSize: "12px",
        opacity: "0.9",
      },
    },
    languageMenu: {
      fontFamily: "Helvetica",
      fontSize: "12px",
      opacity: "0.9",
      fontWeight: "bold",
    },
    icon: {
      fill: "#47A6BE",
    },
    logout: {
      padding: 0,
      color: "#FFFFFF",
      marginLeft: "5%",
      transform: "scale(1.2)",
    },
    searchBar: {
      borderRadius: "20px",
      display: "flex",
      height: "27px",
      boxShadow: "none",
      backgroundColor: "transparent",
      border: `2px solid ${theme.palette.primary.main}`,
      width: "200px",
      "& svg": {
        fontSize: "1.10rem",
        color: theme.palette.primary.main,
      },
      "& input": {
        fontSize: "15px",
        color: "#FFFFFF",
      },
    },
    searchCtn: {
      display: "flex",
      width: "360px",
      justifyContent: "flex-end",
      alignItems: "center",
    }
  })
);
