import { createMuiTheme } from "@material-ui/core/styles";

const PRIMARY_COLOR = "#47A6BE";
const PRIMARY_COLOR_DARK = "#C1D3D6";
const SECONDARY_COLOR = "#19283F";
const TEXT_COLOR = "#000";
const BLUEISH_COLOR = "#D2DEEB";
const BLUEISH_COLOR_DARK = "#C1C9D6";
const BLUISH_CONTRAST = "#4795BE";

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: PRIMARY_COLOR,
      dark: PRIMARY_COLOR_DARK,
    },
    secondary: {
      main: SECONDARY_COLOR,
    },
    text: {
      primary: TEXT_COLOR,
    },
    info: {
      main: BLUEISH_COLOR,
      dark: BLUEISH_COLOR_DARK,
      contrastText: BLUISH_CONTRAST,
    },
  },
  typography: {
    fontFamily: ["Aero Matics Regular"].join(","),
    fontSize: 12,
  },
});

export const themeEditor = createMuiTheme({
  palette: {
    primary: {
      main: PRIMARY_COLOR,
      dark: PRIMARY_COLOR_DARK,
    },
    secondary: {
      main: SECONDARY_COLOR,
    },
    text: {
      primary: TEXT_COLOR,
    },
    info: {
      main: BLUEISH_COLOR,
      dark: BLUEISH_COLOR_DARK,
      contrastText: BLUISH_CONTRAST,
    },
  },
  typography: {
    fontFamily: ["Aero Matics Regular"].join(","),
    fontSize: 12,
  },
});

Object.assign(theme, {
  overrides: {
    MUIRichTextEditor: {
      root: {
        width: "100%",
      },
      editorContainer: {
        width: "100% !important",
        textAlign: "left",
        padding: "10px",
        fontSize: "17px",
        paddingLeft: "20px !important",
        backgroundColor: "#19283F !important",
        height: "340px",
        overflow: "auto",
        "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
          backgroundColor: "#dedede",
          width: "3px",
        },
        "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
          backgroundColor: "#1f2f46",
        },
        "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
          {
            backgroundColor: "#192434",
          },
      },
    },
  },
});

Object.assign(themeEditor, {
  overrides: {
    MUIRichTextEditor: {
      root: {
        width: "100%",
        marginLeft: "auto",
        marginRight: "auto",
        maxWidth: "634px",
      },
      editorContainer: {
        borderTop: "2px solid #19283F !important",
        width: "100% !important",
        textAlign: "left",
        padding: "10px",
        fontSize: "17px",
        paddingLeft: "20px !important",
        backgroundColor: "#FAFAFA !important",
        height: "310px !important",
        boxShadow: "none !important",
        overflow: "auto",
        "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
          backgroundColor: "#dedede",
          width: "3px",
        },
        "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
          backgroundColor: "#1f2f46",
        },
        "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
          {
            backgroundColor: "#192434",
          },
      },
    },
  },
});
